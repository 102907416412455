/**
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Button, Table, Modal,
  Icon, Popconfirm, message, Row, Col,
  Select, Divider, Spin,TreeSelect
} from 'antd';
import { fetchClassListNav,fetchClassList, addClass, updateClass, deleteClass,Copybychapter, getParentClass, getDetailClass,fetchAllLabel,Copybyids ,fetchClassCourseList} from 'modules/cManageRedux';
import defaultPicUrl from '../../../assets/images/default-pic.png';


import ClassForm from './form';
import CourseFrom from './CourseFrom';
import CopyChapterForm from './CopyChapterForm';
import CopyCourseForm from './CopyCourseForm';
const { Search } = Input;
const { Option } = Select;

import './index.less';

type Props = {
  listnav: Array<Object>,
  list: Array<Object>,
  loading: boolean,
  parentClass: Array<Object>,
  classLoading: Boolean,
  fetchClassListNav: typeof fetchClassListNav,
  fetchClassList: typeof fetchClassList,
  addClass: typeof addClass,
  updateClass: typeof updateClass,
  Copybychapter: typeof Copybychapter,
  deleteClass: typeof deleteClass,
  getParentClass: typeof getParentClass,
  detailLoading: boolean,
  classDetail: Object,
  fetchAllLabel: typeof fetchAllLabel,
  listCourse: Array<Object>,
};
type State = {
  modalVisible: boolean,
  modalVisibleParent: boolean,
  modalCopyChapter: boolean,
  modalCopyCourse: boolean,
  updateStatus: boolean,
  query: string,
  pageIndex: number,
  pageSize: number,
  deleteIds: Array<number>,
  curretnItem: Object,
  choseParentClassId: Number,
  selectedArr: Array<Object>,
};
class CourseManage extends Component<Props, State> {
  form: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVisible: false,
      updateStatus: false,
      modalVisibleParent:false,
      modalCopyChapter:false,
      modalCopyCourse:false,
      query: '',
      pageIndex: 1,
      pageSize: 10,
      deleteIds: [],
      curretnItem: {},
      choseParentClassId: '',
      routerName:2,
      typeid:25,
      rowId:-1,
      ptypename:'',
      istype:1,
      type_select_id:0,
      selectedArr:[],
      selectedStatus:false,
      expandedRowKeysArr:[],
      expandedRowKeyStatus:false,
      rowParentId:0,
      chapterArr:[],
      courseArr:[],
    };
  }
  componentDidMount = () => {
    this.getList();
    this.getListnav();
    this.getAllParentClass();
    this.props.fetchAllLabel();
  }
  getList = () => {
    const { pageIndex, pageSize, query, choseParentClassId,typeid,rowId} = this.state;
    const params = {
      pageIndex, pageSize, name: query,typeid:rowId
    };
    this.props.fetchClassList({
      ...params
    });
  }
  getListnav = () => {
    this.props.fetchClassListNav();

  }
  getIdByType = (target, init = []) => {
    target.forEach(item => {
        item.id && init.push(item.id);
        item.children && this.getIdByType(item.children, init);
    });
    return init;
};
  showModal = (obj, cb) => {
    this.setState({ ...obj }, cb);
  }
  parentShowModal = (obj, cb) => {
    if(this.state.type_select_id!=0){
      this.setState({ ...obj 
    }, cb);
    }
    else{
      this.info();
    }
    //  if(this.info()){
    //   this.setState({ ...obj }, cb);
    //  }
  }
  iframeCourseFn = (obj, cb) => {
    this.setState({ ...obj }, cb);
    // var childHtml = document.getElementById("course_ifamre").contentWindow;
  }
  closeModal = () => {
    this.setState({
      modalVisible: false,
      modalVisibleParent:false,
      modalCopyChapter:false,
      modalCopyCourse:false
    });
    this.form.resetFields();
  }


  handleCancel = () => {
    this.form.resetFields();
    this.setState({
      modalVisible: false,
      modalVisibleParent:false,
      modalCopyChapter:false,
      modalCopyCourse:false
    });
  }
  submitCopyChapter = () =>{
    this.form.validateFields((err, values) => {
      this.props.Copybychapter({'typeid':values['typeid'],'ids':this.state.chapterArr})
    }).then(()=>{
      message.success('编辑成功');
      this.closeModal();
    })
  }
  submitCopyCourse = () =>{
    this.form.validateFields((err, values) => {
      this.props.Copybyids({'typeid':values['typeid'],'pid':values['pid'],'ids':this.state.chapterArr})
    }).then(()=>{
      message.success('编辑成功');
      this.closeModal();
    })
  }
  submit = () => {
    this.form.validateFields((err, values) => {
      if (err) return;
      // console.log(values)
      const { updateStatus } = this.state;
      const { curretnItem } = this.state;
      const imgUrl = values.detailimg && values.detailimg[0] ?
        (values.detailimg[0].response ? values.detailimg[0].response.data : values.detailimg) : '';
        const imgUrl_m = values.detailimg_m && values.detailimg_m[0] ?
        (values.detailimg_m[0].response ? values.detailimg_m[0].response.data : values.detailimg_m) : '';
      const seconds = parseInt(values.minitues)*60+parseInt(values.second);
      values['seconds']=seconds;
      // console.log(values)
      // const newscontent = values.newscontent;
      if (updateStatus) {
        this.props.updateClass({ ...curretnItem, ...values, detailimg: imgUrl,detailimg_m:imgUrl_m })
          .then(() => {
            message.success('编辑成功');
            this.closeModal();
            this.getList();
            this.getAllParentClass();
          });
      } else {
        const params = {
          ...values,
          detailimg: imgUrl,
          detailimg_m:imgUrl_m,
        };
        params.parentid = +values.parentid;
        this.props.addClass(params)
          .then(() => {
            message.success('新增成功');
            this.closeModal();
            this.getList();
            this.getAllParentClass();
          });
      }
    });
  }
  // http://www.matools.com/blog/190318329
  handleChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const obj = {
      pageIndex: current,
      pageSize,
    };
    this.setState({ ...obj }, () => this.getList());
  };
  click = (record,rowkey)=>{
    //  console.log(record,rowkey)
    if(record.parentid===0){
      // this.props.list=[];
      // console.log(this.props)
      this.setState({ 
        type_select_id:0,
        rowId:-1,
        expandedRowKeyStatus:false,
        rowParentId:0
      },this.getList)
    }
    else{
      this.setState({
        rowId:record.id,
        ptypename:record.title,
        type_select_id:record.parentid,
        expandedRowKeyStatus:false,
        rowParentId:0
      },this.getList)
    }
  }
  clickCur = (record,rowkey)=>{
    this.setState({
      rowParentId:record.id,
      expandedRowKeyStatus:false
    })
  }
  setRowClassName = (record) => {
    // console.log(record.id,this.state.rowId);
    return record.id === this.state.rowId ? 'clickRowStyl' : '';
  }
  setRowClassName1 = (record) => {
    if(record.children.length>0){
      return 'blodfont'
    }
    if(record.id === this.state.rowParentId){
      return 'clickRowStyl'
    }
  }
  deleteClass = (item) => {
    this.props.deleteClass({
      key: item.id
    }
    ).then(res => {
      if (res) {
        message.success("删除成功");
        this.getList();
      }
    });
  }

  batchDelete = () => {
    const { deleteIds } = this.state;
    if (deleteIds.length === 0) {
      message.warning("请选择需要删除的分类");
      return;
    }

    this.props.deleteClass({
      key: deleteIds.join(',')
    }).then(res => {
      if (res) {
        message.success('删除成功');
        this.getList();
      }
    });
  }
 info=()=> {
  Modal.info({
    title: 'This is a notification message',
    content: (
      <div>
        <p>请选择课程分类后再继续!</p>
      </div>
    ),
  });
}
  getDetail = () => {
    const { id } = this.state.curretnItem;
    this.props.getDetailClass({
      id,
    });
  }

  // getDetailAdd = () => {
  //   const { id} = this.state.curretnItem;
  //   const { rowId } = this.state
  //   this.props.getDetailClass({
  //     id:rowId
  //   });
  // }

  getAllParentClass = () => {
    this.props.getParentClass();
  }

  onSelectChange = (value) => {
    // console.log(value)
    this.setState({
      pageIndex: 1,
      choseParentClassId: value,
    }, this.getList);
  }
  renderTable = () => {
    const columns = [{
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      },  {
        title: '简标题',
        dataIndex: 'shorttitle',
        key: 'shorttitle',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        width: "6%",
        render: (text, record) => (
          <div className="operate-col">
            <Icon
              type="edit"
              title="编辑"
              style={{ fontSize: 16, color: '#83B6FF', marginRight: 10 }}
              onClick={() => {
                this.showModal({ modalVisible: true, updateStatus: true, curretnItem: record }, this.getDetail);
              }}
            />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={() => this.deleteClass(record)}
              okText="确定"
              cancelText="取消"
            >
              <Icon type="delete" title="删除" style={{ fontSize: 16, color: '#FF4A4A' }} />
            </Popconfirm>
          </div>
        ),
      }
];
    const { list, loading, total } = this.props;
    const { pageIndex, pageSize, deleteIds,expandedRowKeysArr,expandedRowKeyStatus,chapterArr,courseArr } = this.state;
    const chapterData = [];
    const courseData = [];
    // console.log(list)

    const rowSelection = {
      columnWidth: '5%',
      selectedRowKeys: deleteIds,
      onChange: (selectedRowKeys: string[], selectedRows: Object[]) => {
        // istype:0,parentid>0 视频
        for(var j = 0,len=selectedRows.length; j < len; j++) {
          // console.log(selectedRows[j])
          chapterData.push(selectedRows[j].id)
        }
        // for( let first of selectedRows){
        //   console.log(first)
        //   for(let item of first)
        //     console.log(item)
        //   chapterData.push(item['id'])
        // }
        this.setState({ 
          deleteIds: selectedRowKeys,
          chapterArr: chapterData,
          courseArr:chapterData
        });
      }
    };

    return (
      <Table
      rowKey={record => record.id}
      key={list.id}
      dataSource={Array.from(list)}
      columns={columns} loading={loading}
      rowSelection={rowSelection}
      onChange={this.handleChange} 
      rowClassName={this.setRowClassName1}    
      expandIcon={(props) => this.customExpandIcon(props)} 
      // expandedRowKeys={this.insetIdFunc()}
      expandedRowKeys={!!expandedRowKeyStatus?expandedRowKeysArr:this.getIdByType(Array.from(list))}
      onExpandedRowsChange={this.onExpandedRowsChange}
      onRow={(record,rowkey)=>{
        return{
            onClick : this.clickCur.bind(this,record,rowkey)  
        }}}
      // onRow={(record,rowkey)=>{
      //   return{
      //       onClick : this.click.bind(this,record,rowkey)  
      //   }}}
    />
    )
  }
  
  // rowsTraversalTree = (tree) => {
  //   if (!tree) return;
  //   // console.log(tree)
  //   tree.map(item => {
  //     if (item.children) {
  //       this.rowsTraversalTree(item.children);
  //     }
  //     if (item.level === 0) {
  //       item.disabled = true;
  //     }
  //     item.id = item.id;
  //   });
  //   console.log(tree)
  //   return tree;
  // }
  onExpandedRowsChange=(expandedRow)=>{
    this.setState({
      expandedRowKeysArr:expandedRow,
      expandedRowKeyStatus:true
    })
  }
  onExpandedRowsChange1=(expandedRow)=>{
  }
  customExpandIcon=(props)=>{
    if(props.record.children.length > 0){
      if (props.expanded) {
          return <div role="button" tabIndex="0" className="ant-table-row-expand-icon ant-table-row-expanded" aria-label="关闭行" onClick={e => {
                 props.onExpand(props.record, e)}}></div>
      } else {
          return <div role="button" tabIndex="0" className="ant-table-row-expand-icon ant-table-row-collapsed" aria-label="展开行" aria-label="关闭行" onClick={e => {
            props.onExpand(props.record, e)}}></div>
      }
  }else{
      return <span style={{marginRight:8 }}></span>
  }
  }
  expandedRowKeysHandle =(data)=>{
    // console.log(data)
    return data.map(item => item.id)
  }
  insetIdFunc = (obj)=>{
  const { listnav} = this.props;
    return listnav.map(item=>{
      return item.id
    })
  }
  traversalTree = (tree) => {
    if (!tree) return;
    // console.log(tree)
    tree.map(item => {
      if (item.children) {
        this.traversalTree(item.children);
      }
      if (item.level === 0) {
        item.disabled = true;
      }
      item.key = item.id;
      item.title = item.title;
      item.value = item.id;
    });
    return tree;
  }
  onSelectHandle = (value,key,title) =>{
    const { listnav} = this.props;
    // console.log(this.state.selectedArr)
    // console.log(value)
    this.setState({
      selectedArr: listnav.filter(item=>{ return item.id==value })[0].children,
      selectedStatus:true,
      rowId:-1,
      type_select_id:0,
    },this.getList)
    
    // this.setState({
    //   rowId:value
    // },this.getList)
  }
  renderTableNav = () => {
    const columnsnav = [{
        title: '教程分类',
        dataIndex: 'title',
        key: 'title',
        width: '100%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }
];
    const { pageIndex, pageSize, deleteIds,selectedStatus,selectedArr } = this.state;
    const { listnav, loading, total } = this.props;
    return <Table
      key={listnav.id}
      rowKey={record => record.id}
      dataSource={selectedStatus?selectedArr:Array.from(listnav)}
      columns={columnsnav} loading={loading}
      onChange={this.handleChange}
      rowClassName={this.setRowClassName}
      pagination={ false } 
      scroll={{ y: 768 }}
      pagination={ false }
      expandIcon={(props) => this.customExpandIcon(props)}
      onExpandedRowsChange={this.onExpandedRowsChange1}
      // onExpand={(expanded, record) => { return this.expandFunction(expanded, record) || null; }}
      // expandedRowKeys={this.insetIdFunc()}
      onRow={(record,rowkey)=>{
        return{
            onClick : this.click.bind(this,record,rowkey)  
//点击行 record 指的本行的数据内容，rowkey指的是本行的索引
        }}}
    />;
  }

  render() {
    const { modalVisible, updateStatus, curretnItem,modalVisibleParent,modalCopyChapter,modalCopyCourse,istype } = this.state;
    const { parentClass, classLoading, loading, detailLoading, classDetail ,labelList,list,listnav} = this.props;
    return (
      <div style={{'display':'flex','background':'#fff'}}>
        <div style={{'width':'400px'}} className={'table-nav table-more-btn1'}>
          <div style={{'height': '46px','textAlign': 'center','lineHeight': '59px'}}>
            <span>教程分类:</span>
              <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="请选择教程分类"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={this.onSelectHandle}
                >
                  {listnav.map(item => <Option key={item.id} value={item.id}>{item.title}</Option>)}
            </Select>
          </div>,
        {this.renderTableNav()}
        </div>
        <div className="container-wraper" style={{'borderLeft':'1px solid #e8e8e8','boxSizing':'border-box'}}> 
          <Row className="page-header">
            <Col span={10}>
              <Button type="primary" onClick={() => this.parentShowModal({modalVisibleParent: true, updateStatus: false,istype:1})}
              >新增章节</Button>
                  <Divider type="vertical" />
              <Button type="primary" onClick={() => this.parentShowModal({modalVisibleParent: true, updateStatus: false,istype:0})}
              >新增课程</Button>
              <Divider type="vertical" />
              <Button type="primary" onClick={() => this.parentShowModal({modalCopyChapter: true, updateStatus: false,istype:1})}
              >章节复制</Button>
              <Divider type="vertical" />
              {/* <Button type="primary" onClick={() => this.parentShowModal({modalCopyCourse: true, updateStatus: false,istype:1})}
              >课程复制</Button>
              <Divider type="vertical" /> */}
              <Button type="primary" onClick={() =>this.parentShowModal({modalCopyCourse:true,updateStatus: false,istype:1})}
              >课程复制</Button>
              <Divider type="vertical" />
              {/* <Popconfirm
                placement="top"
                title="确定要删除吗"
                onConfirm={this.batchDelete}
                okText="确定"
                cancelText="取消"
              >
                <Button className="danger-btn" >批量删除</Button>
              </Popconfirm> */}
            </Col>
            {/* <Col span={14} className="search-wraper">
              一级分类：&nbsp;
                          <Select
                showSearch
                style={{ width: 200, marginRight: '16px' }}
                optionFilterProp="children"
                onChange={this.onSelectChange}
                placeholder="请选择"
                loading={classLoading}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {parentClass.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
              </Select>
              <Search
                placeholder="输入分类名查询"
                onSearch={value => this.setState({ query: value, pageIndex: 1, }, this.getList)}
                enterButton
              />
            </Col> */}
          </Row>
          <div className="table-wraper table-more-btn2">
                {this.renderTable()}
          </div> 
          <Modal
            title={updateStatus ? '编辑' : '新增'}
            visible={modalVisible}
            onOk={this.submit}
            onCancel={this.handleCancel}
            confirmLoading={loading}
            destroyOnClose={true}
            className="course-wraper"
            maskClosable={false}
            style={{top: 0}}
          >
            <Spin spinning={detailLoading}>
              <ClassForm
                curretnItem={updateStatus ? classDetail : {}}
                imgUrl={curretnItem.detailimg}
                imgUrl_m={curretnItem.detailimg_m}
                updateStatus={updateStatus}
                parentClass={parentClass}
                labelList={labelList}
                rowParentId = {this.state.rowParentId}
                list={list}
                ref={(form) => { return form ? this.form = form : null; }}
              />
            </Spin>
          </Modal>
          <Modal
            title={updateStatus ? '编辑' : '新增'}
            visible={modalVisibleParent}
            onOk={this.submit}
            onCancel={this.handleCancel}
            confirmLoading={loading}
            destroyOnClose={true}
            maskClosable={false}
            className="course-wraper rich-text-size"
            style={{top: 0}}
          >
            <Spin spinning={detailLoading}>
              <CourseFrom
                curretnItem={updateStatus ? classDetail : {}}
                imgUrl={curretnItem.detailimg}
                imgUrl_m={curretnItem.detailimg_m}
                updateStatus={updateStatus}
                parentClass={parentClass}
                labelList={labelList}
                list={list}
                ptypename = {this.state.ptypename}
                rowParentId = {this.state.rowParentId}
                rowId = {this.state.rowId}
                istype={this.state.istype}
                ref={(form) => { return form ? this.form = form : null; }}
              />
            </Spin>
          </Modal>
          <Modal
            title='章节复制'
            visible={modalCopyChapter}
            onOk={this.submitCopyChapter}
            onCancel={this.handleCancel}
            confirmLoading={loading}
            destroyOnClose={true}
            maskClosable={false}
            className="rich-text-size"
          >
            <Spin spinning={detailLoading}>
              <CopyChapterForm
                curretnItem={updateStatus ? classDetail : {}}
                imgUrl={curretnItem.detailimg}
                imgUrl_m={curretnItem.detailimg_m}
                updateStatus={updateStatus}
                parentClass={parentClass}
                labelList={labelList}
                list={list}
                ptypename = {this.state.ptypename}
                rowParentId = {this.state.rowParentId}
                chapterArrType = {listnav}
                rowId = {this.state.rowId}
                istype={this.state.istype}
                ref={(form) => { return form ? this.form = form : null; }}
              />
            </Spin>
          </Modal>
          <Modal
            title='课程复制'
            visible={modalCopyCourse}
            onOk={this.submitCopyCourse}
            onCancel={this.handleCancel}
            confirmLoading={loading}
            destroyOnClose={true}
            maskClosable={false}
            className="rich-text-size"
          >
            <Spin spinning={detailLoading}>
              <CopyCourseForm
                curretnItem={updateStatus ? classDetail : {}}
                imgUrl={curretnItem.detailimg}
                imgUrl_m={curretnItem.detailimg_m}
                updateStatus={updateStatus}
                parentClass={parentClass}
                labelList={labelList}
                list={list}
                ptypename = {this.state.ptypename}
                rowParentId = {this.state.rowParentId}
                courseArrType = {listnav}
                rowId = {this.state.rowId}
                istype={this.state.istype}
                fetchClassCourseList={this.props.fetchClassCourseList}
                listCourse={this.props.listCourse}
                courseArr = {this.state.courseArr}
                ref={(form) => { return form ? this.form = form : null; }}
              />
            </Spin>
          </Modal>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { cManageRedux } = state;
    return ({ ...cManageRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      fetchClassCourseList,fetchClassListNav,fetchClassList, addClass, updateClass, deleteClass, getParentClass, getDetailClass,fetchAllLabel,Copybychapter,Copybyids
    }, dispatch,
  ),
)(CourseManage);