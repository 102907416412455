/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  Form, Input, Select, Row, Col, Radio,
  Upload, Button, Icon, message, TreeSelect,
} from 'antd';

import * as Validator from 'utils/validator';
import { fileUploadUrl } from 'modules/url';
import './form.less';
import './richTextExtends.less';

const { Option } = Select;
const { TextArea } = Input;
var Editor = require('wangeditor');
window.Editor = Editor;

const radioGroup = (
  <Radio.Group  >
    <Radio value={1}>是</Radio>
    <Radio value={0}>否</Radio>
  </Radio.Group>
);
const FormItem = Form.Item;
type Props = {
  form: Form,
  backToPageList: Function,
  updateStatus: boolean,
  labelList: Array<Object>,
  classTree: Array<Object>,
  themeTree: Array<Object>,
  curretnItem: Object,
  updateStatus1: boolean,
}
type State = {
  fileList: Array<Object>,
  fileList1: Array<Object>,
}
class ArticleForm extends Component<Props, State> {
  editor: Object = null;
  constructor(props) {
    super(props);
    const { updateStatus, imgUrl,detailImgUrl } = this.props;
    const fileList = [];
    const fileList1=[];
    if (updateStatus && imgUrl) {
      fileList.push({
        uid: '-1',
        name: '缩略图',
        status: 'done',
        url: imgUrl,
      });
    }
    if (updateStatus && detailImgUrl) {
      fileList.push({
        uid: '-1',
        name: '详情图',
        status: 'done',
        url: detailImgUrl,
      });
    }
    this.state = {
      fileList,
      fileList1
    };
  }

  componentDidMount = () => {
    this.renderRichText();
    this.initRichTextValue();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.curretnItem !== prevProps.curretnItem && this.props.updateStatus) {
      this.initRichTextValue();
    }
  }

  initRichTextValue = () => {
    const { newscontent = '' } = this.props.curretnItem;
    if (this.editor) {
      document.getElementsByClassName('w-e-text')[0].innerHTML = newscontent;
    }
    this.props.form.setFieldsValue({
      newscontent,
    });
  }

  normFile = e => {
    if (e && e.fileList) {
      if (!e.fileList[0]) {
        return [];
      }
      const { status } = e.fileList[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList;
    }
    return [];
  };
  normFile1 = e => {
    if (e && e.fileList1) {
      if (!e.fileList1[0]) {
        return [];
      }
      const { status } = e.fileList1[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList1;
    }
    return [];
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('图片大小不能超过20MB!');
    }
    return isLt2M;
  };
  upload = () => {

    const { fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
      </div>
    );
    const headers = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=course'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        headers={headers}
        fileList={fileList}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState({ fileList });
        }}
      >
        {this.state.fileList.length >= 1 ? null : uploadButton}
      </Upload >
    );
  };
  upload1 = () => {

    const { fileList1 } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
      </div>
    );
    const headers = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=course'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        headers={headers}
        fileList={fileList1}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList1 = [];
          } else {
            const { status } = fileList1[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList1 = [];
            }
          }
          this.setState({ fileList1 });
        }}
      >
        {this.state.fileList1.length >= 1 ? null : uploadButton}
      </Upload >
    );
  };
  renderRichText = () => {
    // 配置全屏 和 预览功能
    // Editor.fullscreen = {
    //   init: function (clasName) {
    //     // 添加全屏
    //     document.getElementsByClassName('w-e-toolbar')[0].insertAdjacentHTML('beforeend',
    //       `<div class="w-e-menu"><a class="_wangEditor_btn_fullscreen" href="#" onclick="window.Editor.fullscreen.toggleFullscreen('${clasName}')">全屏</a></div>`);
    //     // // 添加预览功能  
    //     // document.getElementsByClassName('w-e-text-container')[0].insertAdjacentHTML('beforeend',
    //     //   `<div  id="_wangEditor_prev_view_conteiner"></div>`);
    //   },
    //   toggleFullscreen: function (clasName) {
    //     var el = document.getElementsByClassName(clasName)[0];
    //     el.classList.toggle('fullscreen-editor');

    //     var fullTool = document.getElementsByClassName('_wangEditor_btn_fullscreen')[0];
    //     if (fullTool.textContent === '全屏') {

    //       fullTool.textContent = '退出全屏';
    //     } else {
    //       fullTool.textContent = '全屏';
    //     }
    //   }
    // };

    this.editor = new Editor(ReactDOM.findDOMNode(this._div));
    // 富文本编辑器 图片配置
    const customConfig = {
      uploadImgServer: fileUploadUrl + '?type=news',
      uploadFileName: 'file',
      onchange: (html) => {
        this.props.form.setFieldsValue({
          'newscontent': html
        });
      },
      uploadImgHeaders: {
        "Authorization": sessionStorage.getItem('Authorization')
      },
      customAlert: (info) => {
        message.warning(info);
      },
      uploadImgHooks: {
        customInsert: function (insertImg, result, editor) {
          var { data } = result;
          insertImg(data);
        }
      }
    };
    this.editor.customConfig = customConfig;
    this.editor.create();

    // Editor.fullscreen.init('rich-text-container');
  }

  traversalTree = (tree) => {
    if (!tree) return;
    tree.map(item => {
      if (item.children) {
        this.traversalTree(item.children);
      }
      if (item.level === 0) {
        item.disabled = true;
      }
      item.key = item.id;
      item.title = item.name;
      item.value = item.id;
    });
    return tree;
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 15 },
      },
    };
    const contentLayout = {
      labelCol: {
        xs: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 20 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;
    const {
      parentid,
      showtitle,
     shorttitle,
     contents,
     seriesimg,
     detailimg,
     isvip,
     price,
     isnew,
     ishot,
     briefdesc,
     title, 
     keywords,
     description, 
     plids, isshow, sort,
     isseri,
     teacher,
     videolevel,
     crowd,
     purpose,
      newstitle,
      atids,
      img,istop, isred,
      isrecommend, newscontent,
      isdelete // 0 草稿 3 回收站  2 已发布
    } = this.props.curretnItem;
    const { updateStatus, labelList,
      classTree, themeTree } = this.props;
    return (
      <Form>
        <Row>
          <Col span={10} offset={1}>
          <FormItem
          label="一级分类"
          {...formItemLayout}
        >
          {getFieldDecorator('parentid', {
            initialValue: parentid ? parseInt(parentid, 0) : 0,
          })(
            // <Select placeholder="请选择一级分类" >
            //   {this.getParentClassData().map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            // </Select>
            <Input placeholder="请输入标题" />,
          )}
        </FormItem>
          <FormItem
              label="标题"
              {...formItemLayout}
            >
              {getFieldDecorator('title', {
                initialValue: title || '',
                rules: [
                  {
                    required: true, message: '标题不能为空',
                  }],
              })(
                <Input placeholder="请输入标题" />,
              )}
            </FormItem>
            <FormItem
              label="标签"
              {...formItemLayout}
            >
              {getFieldDecorator('plids', {
                initialValue: plids || [],
                rules: [
                  {
                    required: true, message: '标签不能为空',
                  }],
              })(
                <Select
                  mode="multiple"
                  placeholder="请选择文章标签"
                  optionFilterProp="children"
                  showSearch
                >
                  {labelList ? labelList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>) : ''}
                </Select>,
              )}
            </FormItem>
            <FormItem
              label="简标题"
              {...formItemLayout}
            >
              {getFieldDecorator('shorttitle', {
                initialValue: shorttitle || '',
              })(
                <Input placeholder="请输入简标题" />,
              )}
            </FormItem>
            <FormItem
              label="显示标题"
              {...formItemLayout}
            >
              {getFieldDecorator('showtitle', {
                initialValue: showtitle || '',
              })(
                <Input placeholder="请输入显示标题" />,
              )}
            </FormItem>
            <FormItem
              label="网页关键字"
              {...formItemLayout}
            >
              {getFieldDecorator('keywords', {
                initialValue: keywords || '',
              })(
                <Input placeholder="请输入网页关键字" />,
              )}
            </FormItem>
            <FormItem
              label="网页描述"
              {...formItemLayout}
            >
              {getFieldDecorator('description', {
                initialValue: description || '',
              })(
                <TextArea placeholder="请输入网页描述" />,
              )}
            </FormItem>
            <FormItem
              label="简介"
              {...formItemLayout}
            >
              {getFieldDecorator('contents', {
                initialValue: contents || '',
              })(
                <TextArea placeholder="请输入内容" />,
              )}
            </FormItem>
            <FormItem
              label="列表展示图"
              {...formItemLayout}
            >
              {getFieldDecorator('seriesimg', {
                initialValue: seriesimg,
                getValueFromEvent: this.normFile,
                rules: [
                  {
                    required: true, message: '缩略图不能为空',
                  }],
              })(
                this.upload()
              )}
            </FormItem>
            {/* <FormItem
              label="详情图"
              {...formItemLayout}
            >
              {getFieldDecorator('detailimg', {
                initialValue: detailimg,
                getValueFromEvent: this.normFile1,
                rules: [
                  {
                    required: true, message: '详情图不能为空',
                  }],
              })(
                this.upload()
              )}
            </FormItem> */}
            <FormItem
              label="老师"
              {...formItemLayout}
            >
              {getFieldDecorator('teacher', {
                initialValue: teacher || '',
              })(
                <Input placeholder="请输入老师姓名" />,
              )}
            </FormItem>
            <FormItem
              label="课程级别"
              {...formItemLayout}
            >
              {getFieldDecorator('videolevel', {
                initialValue: videolevel || '',
              })(
                <Input placeholder="请输入课程级别" />,
              )}
            </FormItem>
            <FormItem
              label="适合人群"
              {...formItemLayout}
            >
              {getFieldDecorator('crowd', {
                initialValue: crowd || '',
              })(
                <Input placeholder="请输入适合人群" />,
              )}
            </FormItem>
            <FormItem
              label="价格"
              {...formItemLayout}
            >
              {getFieldDecorator('price', {
                initialValue: price || 0,
              })(
                <Input placeholder="请输入适合人群" />,
              )}
            </FormItem>
            <FormItem
              label="目的"
              {...formItemLayout}
            >
              {getFieldDecorator('purpose', {
                initialValue: purpose || '',
              })(
                <Input placeholder="请输入目的" />,
              )}
            </FormItem>
          </Col>
          <Col span={12}>
          
            <Form.Item label="是否vip"  {...formItemLayout}>
              {getFieldDecorator('isvip', { initialValue: isvip })(radioGroup)}
            </Form.Item>
            <Form.Item label="是否更新"  {...formItemLayout}>
              {getFieldDecorator('isnew', { initialValue: isnew })(radioGroup)}
            </Form.Item>
            <Form.Item label="是否热门"  {...formItemLayout}>
              {getFieldDecorator('ishot', { initialValue: ishot })(radioGroup)}
            </Form.Item>
            <Form.Item label="是否显示"  {...formItemLayout}>
              {getFieldDecorator('isshow', { initialValue: isshow })(radioGroup)}
            </Form.Item>
            <Form.Item label="是否连载"  {...formItemLayout}>
              {getFieldDecorator('isseri', { initialValue: isseri })(radioGroup)}
            </Form.Item>
            <FormItem
              label="排序"
              {...formItemLayout}
            >
              {getFieldDecorator('sort', {
                initialValue: sort || 0,
                rules: [{
                  required: false, message: '',
                }, {
                  message: '请输入正整数',
                  pattern: /^[0-9]\d*$/,
                }],
              })(
                <Input placeholder="越小越靠前" />,
              )}
            </FormItem>
            <FormItem
              label="简介描述"
              {...formItemLayout}
            >
              {getFieldDecorator('briefdesc', {
                initialValue: briefdesc || '',
              })(
                <TextArea placeholder="请输入简介描述" rows="5" />,
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24} offset={1}>
            <FormItem
              label="文章内容"
              {...contentLayout}
            >
              {getFieldDecorator('newscontent', {
                initialValue: newscontent,
              })(
                <div className="rich-text-container" ref={(ref) => this._div = ref} >
                </div>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={22} offset={3}>
            <Button type="primary" onClick={this.props.submit}>保存</Button>
            &nbsp;&nbsp;
            <Button onClick={this.props.backToPageList}>取消</Button>
          </Col>
        </Row>
      </Form>
    );
  }
};

export default Form.create()(ArticleForm);
