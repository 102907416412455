/**
 * @flow
 */

import React, { Component } from 'react';
import { Button, message, Spin } from 'antd';
import CryptoJS from 'crypto-js';

import './index.less';


class Iframe extends Component<Props, State> {
  form: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
        userID:'',
        userName:'',
    };
  }

  componentDidMount = () => {
    var parentHeight = document.getElementsByClassName('container-wraper')[0].offsetHeight;
    var myiframe = document.getElementById('iframe');
    myiframe.onload = function(){
      myiframe.height = parentHeight
    }
    this.setState({
        userID:sessionStorage.getItem('userID'),
        userName:sessionStorage.getItem('userName'),
      })
      console.log(this.props);
  }
 
  render() {
    
      // 定义你的参数
      const uid = this.state.userID;
      const timestamp = Math.floor(Date.now() / 1000); // 时间戳，单位秒
      // 构建待加密的字符串
      const rawString = `${uid}_${timestamp}_msc`;
      // 使用SHA-1算法加密
      const encryptedString = CryptoJS.SHA1(rawString).toString(CryptoJS.enc.Hex);
    return (
      <div className="iframe-wraper">
        {/* <div className="page-header">
          <Button className="btn-default">返回上级</Button>
        </div> */}
            <iframe src={'http://erp.musicheng.com/admin/baike/detail?id='+this.props.itemId+'&uname='+this.state.userName+'&uid='+this.state.userID+'&token='+encryptedString+'&timestamp='+timestamp} frameborder="0" id='iframe' className='iframe' height='768'>
            </iframe>
      </div>
    );
  }
}

export default Iframe