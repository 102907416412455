/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  Form, Input, Select, Row, Col, Radio,
  Upload, Button, Icon, message, TreeSelect,
} from 'antd';

import * as Validator from 'utils/validator';
import { fileUploadUrl } from 'modules/url';
import './form.less';
import './richTextExtends.less';

const { Option } = Select;
const { TextArea } = Input;

var Editor = require('wangeditor');
window.Editor = Editor;

const radioGroup = (
  <Radio.Group >
    <Radio value={1}>是</Radio>
    <Radio value={0}>否</Radio>
  </Radio.Group>
);
const FormItem = Form.Item;
type Props = {
  form: Form,
  backToPageList: Function,
  updateStatus: boolean,
  labelList: Array<Object>,
  classTree: Array<Object>,
  themeTree: Array<Object>,
  curretnItem: Object,
}
type State = {
  fileList: Array<Object>,
}
class ArticleForm extends Component<Props, State> {
  editor: Object = null;
  constructor(props) {
    super(props);
    const { updateStatus, imgUrl,imgUrl_m } = this.props;
    const fileList = [];
    const fileList1 = [];
    if (updateStatus && imgUrl) {
      fileList.push({
        uid: '-1',
        name: '缩略图',
        status: 'done',
        url: imgUrl,
      });
    }
    if (updateStatus&& imgUrl_m) {
      fileList1.push({
        uid: '-2',
        name: '移动图',
        status: 'done',
        url: imgUrl_m,
      });
    }
    this.state = {
      fileList:fileList,
      fileList1:fileList1,
    };
  }

  componentDidMount = () => {
    this.renderRichText();
    this.initRichTextValue();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.curretnItem !== prevProps.curretnItem && this.props.updateStatus) {
      this.initRichTextValue();
    }
  }

  initRichTextValue = () => {
    const { contents = '',editremark='' } = this.props.curretnItem;
    let seocontent
    seocontent = contents
    seocontent = seocontent.replace(/<xml>[\s\S]*?<\/xml>/ig, '')
    seocontent = seocontent.replace(/<style>[\s\S]*?<\/style>/ig, '')
    seocontent = seocontent.replace(/<\/?[^>]*>/g, '')
    seocontent = seocontent.replace(/[ | ]*\n/g, '\n')
    seocontent = seocontent.replace(/&nbsp;/ig, '')
    if (this.editor) {
      this.editor.txt.html(contents);
      // document.getElementsByClassName('w-e-text')[0].innerHTML = contents;
    }
    this.props.form.setFieldsValue({
      contents,
      editremark:contents
    });
  }

  normFile = e => {
    if (e && e.fileList) {
      if (!e.fileList[0]) {
        return [];
      }
      const { status } = e.fileList[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList;
    }
    return [];
  };

  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('图片大小不能超过20MB!');
    }
    return isLt2M;
  };

  upload = () => {

    const { fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
      </div>
    );
    const headers = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=ArticleType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        headers={headers}
        fileList={fileList}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState({ fileList });
        }}
      >
        {this.state.fileList.length >= 1 ? null : uploadButton}
      </Upload >
    );
  };
  upload1 = () => {
    const { fileList1 } = this.state;
    const uploadButton1 = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
  </div>
    );
    const headers1 = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=CourseType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={fileList1}
        headers={headers1}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState((state1)=>({fileList1:fileList}));
        }}
      >
        {this.state.fileList1.length >= 1 ? null : uploadButton1}
      </Upload>
    );

  };
  renderRichText = () => {
    // 配置全屏 和 预览功能
    // Editor.fullscreen = {
    //   init: function (clasName) {
    //     // 添加全屏
    //     document.getElementsByClassName('w-e-toolbar')[0].insertAdjacentHTML('beforeend',
    //       `<div class="w-e-menu"><a class="_wangEditor_btn_fullscreen" href="#" onclick="window.Editor.fullscreen.toggleFullscreen('${clasName}')">全屏</a></div>`);
    //     // // 添加预览功能  
    //     // document.getElementsByClassName('w-e-text-container')[0].insertAdjacentHTML('beforeend',
    //     //   `<div  id="_wangEditor_prev_view_conteiner"></div>`);
    //   },
    //   toggleFullscreen: function (clasName) {
    //     var el = document.getElementsByClassName(clasName)[0];
    //     el.classList.toggle('fullscreen-editor');

    //     var fullTool = document.getElementsByClassName('_wangEditor_btn_fullscreen')[0];
    //     if (fullTool.textContent === '全屏') {

    //       fullTool.textContent = '退出全屏';
    //     } else {
    //       fullTool.textContent = '全屏';
    //     }
    //   }
    // };

    this.editor = new Editor(ReactDOM.findDOMNode(this._div));
    // 富文本编辑器 图片配置
    const customConfig = {
      uploadImgServer: fileUploadUrl + '?type=news',
      uploadFileName: 'file',
      onchange: (html) => {
        let seocontent;
        seocontent = html
        seocontent = seocontent.replace(/<xml>[\s\S]*?<\/xml>/ig, '')
        seocontent = seocontent.replace(/<style>[\s\S]*?<\/style>/ig, '')
        seocontent = seocontent.replace(/<\/?[^>]*>/g, '')
        seocontent = seocontent.replace(/[ | ]*\n/g, '\n')
        seocontent = seocontent.replace(/&nbsp;/ig, '')
        this.props.form.setFieldsValue({
          'contents': html,
          // 'editremark':this.editor.txt.text()
          'editremark':seocontent
        });
      },
      uploadImgHeaders: {
        "Authorization": sessionStorage.getItem('Authorization')
      },
      customAlert: (info) => {
        message.warning(info);
      },
      uploadImgHooks: {
        customInsert: function (insertImg, result, editor) {
          var { data } = result;
          insertImg(data);
        }
      }
    };
    this.editor.customConfig = customConfig;
    this.editor.create();

    // Editor.fullscreen.init('rich-text-container');
  }

  traversalTree = (tree) => {
    if (!tree) return;
    tree.map(item => {
      if (item.children) {
        this.traversalTree(item.children);
      }
      if (item.level === 0) {
        item.disabled = true;
      }
      item.key = item.id;
      item.title = item.name;
      item.value = item.id;
    });
    return tree;
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 15 },
      },
    };
    const contentLayout = {
      labelCol: {
        xs: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 20 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;
    const {
      title, showtitle, keywords,
      description, atids, 
      // ttids,
      plids, isshow, sort,
      img, ishot, istop, isred,
      isrecommend, contents,
      briefdesc,
      editremark,
      img_m,
      isdelete // 0 草稿 3 回收站  2 已发布
    } = this.props.curretnItem;
    const { updateStatus, labelList,
      classTree, themeTree } = this.props;
      // console.log(themeTree)
    return (
      <Form>
        <Row>
          <Col span={10} offset={1}>
            <FormItem
              label="问答标题"
              {...formItemLayout}
            >
              {getFieldDecorator('title', {
                initialValue: title || '',
                rules: [
                  {
                    required: true, message: '标题不能为空',
                  }],
              })(
                <Input placeholder="请输入标题" />,
              )}
            </FormItem>
            <FormItem
              label="网页标签"
              {...formItemLayout}
            >
              {getFieldDecorator('showtitle', {
                initialValue: showtitle || '',
              })(
                <Input placeholder="如果为空 系统按照规则自动生成（规则：取问答标题）" />,
              )}
            </FormItem>
            <FormItem
              label="网页关键字"
              {...formItemLayout}
            >
              {getFieldDecorator('keywords', {
                initialValue: keywords || '',
              })(
                <Input placeholder="请输入网页关键字（编辑自行设置）" />,
              )}
            </FormItem>
            <FormItem
              label="网页描述"
              {...formItemLayout}
            >
              {getFieldDecorator('description', {
                initialValue: description || '',
                rules: [
                  {
                    required: false, message: '',
                  }],
              })(
                <TextArea placeholder="如果为空 系统按照规则自动生成（规则：取问答内容前75个字）" rows="5" />,
              )}
            </FormItem>
            <FormItem
              label="所属分类"
              {...formItemLayout}
            >
              {getFieldDecorator('atids', {
                initialValue: atids || undefined,
                rules: [
                  {
                    required: true, message: '所属分类不能为空',
                  }],
              })(
                <TreeSelect
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={this.traversalTree(classTree)}
                  placeholder="请选择所属分类"
                  treeDefaultExpandAll
                />
              )}
            </FormItem>
            {/* <FormItem
              label="所属专题"
              {...formItemLayout}
            >
              {getFieldDecorator('ttids', {
                initialValue: ttids || undefined,
                rules: [
                  {
                    required: true, message: '所属专题不能为空',
                  }],
              })(
                <TreeSelect
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={this.traversalTree(themeTree)}
                  placeholder="请选择所属专题"
                  treeDefaultExpandAll
                />,
              )}
            </FormItem> */}
            <FormItem
              label="标签"
              {...formItemLayout}
            >
              {getFieldDecorator('plids', {
                initialValue: plids || [],
              })(
                <Select
                  mode="multiple"
                  placeholder="请选择标签"
                  optionFilterProp="children"
                  showSearch
                >
                  {labelList ? labelList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>) : ''}
                </Select>,
              )}
            </FormItem>
            <Form.Item label="是否显示"  {...formItemLayout}>
              {getFieldDecorator('isshow', { initialValue: isshow?isshow:1 })(radioGroup)}
            </Form.Item>
            <FormItem
              label="排序"
              {...formItemLayout}
            >
              {getFieldDecorator('sort', {
                initialValue: sort || 0,
                rules: [{
                  required: false, message: '',
                }, {
                  message: '请输入正整数',
                  pattern: /^[0-9]\d*$/,
                }],
              })(
                <Input placeholder="越小越靠前" />,
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="缩略图(PC)"
              {...formItemLayout}
            >
              {getFieldDecorator('img', {
                initialValue: img||'',
                getValueFromEvent: this.normFile,
              })(
                this.upload()
              )}
            </FormItem>
            {/* <FormItem
          label="缩略图(移动)"
          {...formItemLayout}
        >
          {getFieldDecorator('img_m', {
            initialValue: img_m || '',
            getValueFromEvent: this.normFile
          })(
            this.upload1()
          )}
        </FormItem> */}
            <FormItem
              label="简介描述"
              {...formItemLayout}
            >
              {getFieldDecorator('briefdesc', {
                initialValue: briefdesc || '',
              })(
                <TextArea placeholder="如果为空 系统按照规则自动生成（规则：取问答内容前75个字）" rows="5" />,
              )}
            </FormItem>
            <FormItem
              label="纯文本"
              {...formItemLayout}
              style={{'display':'none'}}
            >
              {getFieldDecorator('editremark', {
                initialValue: editremark || '',
              })(
                <Input placeholder="请输入纯文本" />,
              )}
            </FormItem>
            {
              updateStatus && isdelete === 2 ? <Col span={24}>
                <Form.Item label="是否热门"  {...formItemLayout}>
                  {getFieldDecorator('ishot', { initialValue: ishot, })(radioGroup)}
                </Form.Item>
                <Form.Item label="是否置顶"  {...formItemLayout}>
                  {getFieldDecorator('istop', { initialValue: istop })(radioGroup)}
                </Form.Item>
                <Form.Item label="是否标红"  {...formItemLayout}>
                  {getFieldDecorator('isred', { initialValue: isred })(radioGroup)}
                </Form.Item>
                <Form.Item label="是否推荐"  {...formItemLayout}>
                  {getFieldDecorator('isrecommend', { initialValue: isrecommend })(radioGroup)}
                </Form.Item>
              </Col> : ''
            }
          </Col>
        </Row>
        <Row>
          <Col span={24} offset={1}>
            <FormItem
              label="内容"
              {...contentLayout}
            >
              {getFieldDecorator('contents', {
                initialValue: contents,
              })(
                <div className="rich-text-container" ref={(ref) => this._div = ref} >
                </div>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={22} offset={3}>
            <Button type="primary" onClick={this.props.submit}>保存</Button>
            &nbsp;&nbsp;
            <Button onClick={this.props.backToPageList}>取消</Button>
          </Col>
        </Row>
      </Form>
    );
  }
};

export default Form.create()(ArticleForm);
