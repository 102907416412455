/**
 */
let tempUrl = '/v1.1';
let loginUrlTemp = '';
if (process.env.NODE_ENV === 'production') {
  tempUrl = 'http://kbmapi.musicheng.com.cn:8086/v1.1';  //测试http://140.246.149.72:8085 生产 http://kbmapi.musicheng.com:8085
  loginUrlTemp = 'http://kbmapi.musicheng.com.cn:8086';
}
export const baseUrl = tempUrl;
export const fileUploadUrl = baseUrl + '/Utils/UploadFile';
export const eClassUrl = baseUrl + '/ArticleType';
export const eArticleUrl = baseUrl + '/News';
export const newsUrl = baseUrl + '/NewsInfo';
export const eLabelUrl = baseUrl + '/ProductLabel';
export const eThemeUrl = baseUrl + '/ThemeType';
export const classTreeUrl = baseUrl + '/ArticleType/GetTypeTree';
export const themeTreeUrl = baseUrl + '/ThemeType/GetTypeTree';
export const ansTreeUrl = baseUrl + '/ArticleType/FaqTypeTree';
export const eAdvUrl = baseUrl + '/PicAdvert';
export const loginUrl = loginUrlTemp;


// 百科分类
export const bkClassUrl = baseUrl + '/ArticleType';
export const bkClassTreeUrl = baseUrl + '/ArticleType/WikiTypeTree';

//百科专题
export const bkThemeUrl = baseUrl + '/ThemeType';
export const bkThemeTreeUrl = baseUrl + '/ThemeType/WikiThemeTree';
export const bkWikiUrl = baseUrl + '/Wiki';
export const bkArticleUrl = baseUrl + '/News';

//问答分类
export const ansClassUrl = baseUrl + '/ArticleType';
//问答专题
export const ansThemeUrl = baseUrl + '/ThemeType';
export const ansThemeTreeUrl = baseUrl + '/ThemeType/FaqThemeTree';
//问答专题
export const ansQuesUrl = baseUrl + '/FAQ';


// 教程
export const courseQuesUrl = baseUrl + '/Course';
// ******http://140.246.149.72:8085/swagger/index.html
