/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, message, Spin } from 'antd';
import {
  addArticle, updateArticle,
  getArticleDetail,getParentClass
} from 'modules/cArticleRedux';

import ArticleForm from './form';
import { isArray } from 'util';

type Props = {
  updateStatus: Boolean,
  curretnItem: Object,
  backToPageList: Function,
  addArticle: typeof addArticle,
  updateArticle: typeof updateArticle,
  loading: Boolean,
  labelList: Array<Object>,
  labelLoading: boolean,
  classTree: Array<Object>,
  classTreeLoading: boolean,
  themeTree: Array<Object>,
  themeTreeLoading: boolean,
  getParentClass: typeof getParentClass,
  getArticleDetail: typeof getArticleDetail,
  articleDetail: Object,
};
class Article extends Component<Props, State> {
  form: Object;
  state = {};

  componentWillMount = () => {
    const { updateStatus } = this.props;
    updateStatus && this.getArticleDetail();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.updateStatus !== prevProps.updateStatus && this.props.updateStatus) {
      this.getArticleDetail();
    }
  }

  getArticleDetail = () => {
    const { id } = this.props.curretnItem;
    this.props.getArticleDetail({
      id
    });
  }
  componentDidMount = () => {
    this.getAllParentClass();
  }
  getAllParentClass = () => {
    this.props.getParentClass();
  }
  submit = () => {
    this.form.validateFields((err, values) => {
      if (err) return;
      const { updateStatus, curretnItem, backToPageList, addArticle, updateArticle } = this.props;
      const imgUrl = isArray(values.seriesimg) ? values.seriesimg[0].response.data : values.seriesimg;
      const detailImgUrl = isArray(values.detailimg) ? values.detailimg[0].response.data : values.detailimg;
      const { atids, ttids ,seriesimg,detailimg} = values;
      const params = {
        ...values,
        atids: isArray(atids) ? atids : [atids],
        ttids: isArray(ttids) ? ttids : [ttids],
        seriesimg: imgUrl,
        detailimg:detailImgUrl
      };
      if (updateStatus) {
        updateArticle({
          ...curretnItem,
          ...params,
        }).then(res => {
          message.success('编辑成功');
          backToPageList();
          this.getAllParentClass();
        });
      } else {
        addArticle({
          ...params
        }).then(res => {
          message.success('新增成功');
          backToPageList();
          this.getAllParentClass();
        });
      }
    });
  }

  render() {
    const { updateStatus,
      backToPageList, loading,
      labelList, labelLoading,
      classTree, classTreeLoading,
      themeTree, themeTreeLoading,
      articleDetail,
      curretnItem
    } = this.props;
    // console.log(this.props)
    return (
      <div className="add-wraper">
        <div className="page-header">
          <Button className="btn-default" onClick={backToPageList}>返回上级</Button>
        </div>
        <Spin spinning={loading || labelLoading || classTreeLoading || themeTreeLoading}>
          <div className="add-content">
            <ArticleForm
              curretnItem={updateStatus ? articleDetail : {}}
              imgUrl={curretnItem.seriesimg}
              detailImgUrl = {curretnItem.detailImgUrl}
              labelList={labelList}
              classTree={classTree}
              themeTree={themeTree}
              updateStatus={updateStatus}
              backToPageList={backToPageList}
              submit={this.submit}
              ref={(form) => { return form ? this.form = form : null; }} />
          </div>
        </Spin>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { cArticleRedux } = state;

    return ({ ...cArticleRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      addArticle, updateArticle, getArticleDetail,getParentClass
    }, dispatch,
  ),
)(Article);