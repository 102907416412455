/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import { Form, Input, Select, Checkbox, Upload, Icon, message, Radio } from 'antd';
import * as Validator from 'utils/validator';
const { Option } = Select;
import { fileUploadUrl } from 'modules/url';

const { TextArea } = Input;
const FormItem = Form.Item;
type Props = {
  form: Form,
  getDetail: typeof getDetail,
  updateStatus: boolean,
  currentItem: Object,
  imgUrl: String,
}
type State = {
  fileList: Array<Object>,
}
class AdvForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { updateStatus, imgUrl } = this.props;
    const fileList = [];
    if (updateStatus && imgUrl) {
      fileList.push({
        uid: '-1',
        name: '缩略图',
        status: 'done',
        url: imgUrl,
      });
    }
    this.state = {
      fileList
    };
  }

  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('图片大小不能超过20MB!');
    }
    return isLt2M;
  };

  upload = () => {
    const headers = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    const { fileList } = this.state;
    const uploadButton = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
    <div style={{color: 'red',position: 'absolute',right: '-233px',top: '-30px',pointerEvents:'none'}}>电商行情首页顶部图:350px*250px</div>
    <div style={{color: 'red',position: 'absolute',right: '-261px',top: '-5px',pointerEvents:'none'}}>电商行情首页左侧广告图:258px*173px</div>
    <div style={{color: 'red',position: 'absolute',right: '-234px',top: '20px',pointerEvents:'none'}}>电商百科首页广告图:830px*106px</div>
  </div>
    );
    return (
      <Upload
        action={fileUploadUrl + '?type=Adv'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={fileList}
        headers={headers}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState({ fileList });
        }}
      >
        {this.state.fileList.length >= 1 ? null : uploadButton}
      </Upload>
    );

  };

  normFile = e => {
    if (e && e.fileList) {
      if (!e.fileList[0]) {
        return [];
      }
      const { status } = e.fileList[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList;
    }
    return [];
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 18 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;
    const {
      url, pagelocation, advdescription, linkurl, keywords, img
    } = this.props.advDetail;
    console.log(this.props);
    return (
      <Form>
        <FormItem
          label="页面URL"
          {...formItemLayout}
        >
          {getFieldDecorator('url', {
            initialValue: url || '',
            rules: [{ required: true, message: '请输入页面URL' }],
          })(
            <Input placeholder="请输入页面URL" />,
          )}
        </FormItem>
        {/* <FormItem
          label="页面标识"
          // {...formItemLayout}
        >
          {getFieldDecorator('pagelocation', {
            initialValue: pagelocation || '',
            rules: [{ required: true, message: '请输入页面标识' }],
          })(
            <Input placeholder="请输入页面标识" />,
          )} 
        </FormItem> */}
        <FormItem label="请输入页面标识"
        {...formItemLayout}>
          {getFieldDecorator('pagelocation', {
            initialValue:pagelocation||'',
            rules: [{ required: true, message: '请输入页面标识' }],
          })(
            <Select>
              <Option value="101">电商行情首页顶部广告图(349px*225px)</Option>
              <Option value="102">电商行情左侧推荐关注(258px*173px)</Option>
              <Option value="103">电商百科广告图(842px*107px)</Option>
              <Option value="104">电商问答广告图</Option>
              <Option value="105">电商教程广告图(258px*173px)</Option>
              <Option value="201">电商智库首页轮播移动(1008px*432px)</Option>
              <Option value="202">电商智库首页突出点左移动(480px*504px)</Option>
              <Option value="203">电商智库首页突出点右移动(492px*234px)</Option>
              <Option value="204">电商行情首页轮播移动(1008px*432px)</Option>
            </Select>,
          )}
        </FormItem>
        <FormItem
          label="链接URL"
          {...formItemLayout}
        >
          {getFieldDecorator('linkurl', {
            initialValue: linkurl || '',
          })(
            <TextArea placeholder="请输入链接URL" />
          )}
        </FormItem>
        <FormItem
          label="关键字"
          {...formItemLayout}
        >
          {getFieldDecorator('keywords', {
            initialValue: keywords || '',
          })(
            <TextArea placeholder="请输入关键字" />
          )}
        </FormItem>
        <FormItem
          label="图片"
          {...formItemLayout}
        >
          {getFieldDecorator('img', {
            initialValue: img || '',
            getValueFromEvent: this.normFile
          })(
            this.upload()
          )}
        </FormItem>
        <FormItem
          label="广告描述"
          {...formItemLayout}
        >
          {getFieldDecorator('advdescription', {
            initialValue: advdescription || ''
          })(
            <TextArea placeholder="请输入广告描述" />,
          )}
        </FormItem>
      </Form >
    );
  };
}

export default Form.create()(AdvForm);
