/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import {
  Form, Radio
} from 'antd';

const FormItem = Form.Item;

type Props = {
  form: Form,
  labelName: String,
};
type State = {};
class BatchForm extends Component<Props, State>{
  state = {}

  render() {

    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 15 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;

    const { labelName } = this.props;
    return (
      <Form>
        <FormItem
          label={labelName}
          {...formItemLayout}
        >
          {getFieldDecorator('check', {
            rules: [
              {
                required: true, message: '请选择',
              }],
          })(
            <Radio.Group >
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(BatchForm);