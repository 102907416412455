/**
 * @flow
 */
import { ansyHandleFactory, handleActions } from 'utils/actionFactory';
import { createAsyncAction } from 'utils/request';
import { courseQuesUrl ,eLabelUrl} from './url';

const fetchClassListNavData = ansyHandleFactory('fetchClassListnav');
const fetchClassListData = ansyHandleFactory('fetchClassList');
const fetchClassCourseListData = ansyHandleFactory('fetchClassCourseList');
const addClassData = ansyHandleFactory('addClass');
const updateClassData = ansyHandleFactory('updateClass');
const CopybychapterData = ansyHandleFactory('Copybychapter');
const CopybyidsData = ansyHandleFactory('Copybyids');
const deleteClassData = ansyHandleFactory('deleteClass');
const getDetailData = ansyHandleFactory('getDetail');
const getParentClassData = ansyHandleFactory('getParentClass');
const fetchAllLabelListData = ansyHandleFactory('fetchAllLabelListData'); // 获取所有标签
const traversalTree = (tree) => {
    if (!tree) return;
  
    tree.map(item => {
      if (item.children) {
        traversalTree(item.children);
      }
      // if (item.level === 0) {
      //   item.disabled = true;
      // }
      item.key = item.id;
      item.title = item.name;
      item.value = item.id;
    });
    return tree;
  };

const ClassRedux = handleActions(
    fetchClassListData('GET', {
        pending: state => ({ ...state, list: [], loading: true,total: 0 }),
        accept: (state, action)  => ({ ...state, list: action.payload,loading: false }),
        reject: state  => ({ ...state, list: [], loading: false,total: 0 }),
    }),
    fetchClassCourseListData('GET', {
        pending: state => ({ ...state, listCourse: [], loading: true,total: 0 }),
        accept: (state, action)  => ({ ...state, listCourse: action.payload,loading: false }),
        reject: state  => ({ ...state, listCourse: [], loading: false,total: 0 }),
    }),
    fetchClassListNavData('GET', {
        pending: state => ({ ...state, listnav: [], loading: true,total: 0 }),
        accept: (state, action)  => ({ ...state, listnav: action.payload,loading: false }),
        reject: state  => ({ ...state, listnav: [], loading: false,total: 0 }),
    }),
    addClassData('POST', {
        accept: (state) => ({ ...state, loading: false }),
    }),
    updateClassData('POST', {
        accept: (state) => ({ ...state, loading: false }),
    }),
    CopybychapterData('POST', {
        accept: (state) => ({ ...state, loading: false }),
    }),
    CopybyidsData('POST', {
        accept: (state) => ({ ...state, loading: false }),
    }),
    deleteClassData('GET', {
        accept: (state) => ({ ...state, loading: false }),
    }),
    getDetailData('GET', {
        pending: (state) => ({ ...state, classDetail:{}, detailLoading: true, }),
        accept: (state,action) => ({ ...state, detailLoading: false, classDetail: action.payload}),
        reject: state => ({ ...state, classDetail:{}, detailLoading: false,})
    }),
    getParentClassData('GET', {
        pending: (state) => ({ ...state, parentClass: [], classLoading: true }),
        accept: (state, action) => ({ ...state, parentClass: action.payload, classLoading: false }),
        reject: (state) => ({ ...state, parentClass: [], classLoading: false }),
    }),
    fetchAllLabelListData('GET', {
        pending: state => ({ ...state, labelList: [], labelLoading: true }),
        accept: (state, action) => {
          return {
            ...state, labelList: action.payload, labelLoading: false
          };
        },
        reject: state => ({ ...state, labelList: [], labelLoading: false }),
      }),
    {
        loading: false,
        list: [],
        parentClass: [],
        classLoading: false,
        detailLoading: false,
        classDetail: {},
        total: 0,
        listnav:[],
        listCourse:[]
    },
);

export default ClassRedux;

/* ************************************************** 同步请求数据 ************************************************ */

/* ************************************************** 异步请求数据 *********************************************** */
type fetchClassListNavParams = {
};
export const fetchClassListNav: (obj: fetchClassListNavParams) => disPromise<*> = createAsyncAction(
    `${courseQuesUrl}/GetCourseTypeTree`,
    fetchClassListNavData('GET'),
);

type fetchClassListParams = {
};
export const fetchClassList: (obj: fetchClassListParams) => disPromise<*> = createAsyncAction(
    `${courseQuesUrl}/GetCourseTree`,
    fetchClassListData('GET'),
);
type fetchClassCourseListParams = {

};
export const fetchClassCourseList: (obj: fetchClassCourseListParams) => disPromise<*> = createAsyncAction(
    `${courseQuesUrl}/GetCourseTree`,
    fetchClassCourseListData('GET'),
);
type addClassParams = {
};

export const addClass: (obj: addClassParams) => disPromise<*> = createAsyncAction(
    `${courseQuesUrl}/CourseInsert`,
    addClassData('POST'),
);

type updateClassParams = {
};

export const updateClass: (obj: updateClassParams) => disPromise<*> = createAsyncAction(
    `${courseQuesUrl}/CourseUpdate`,
    updateClassData('POST'),
);

type deleteClassParams = {
};
export const deleteClass: (obj: deleteClassParams) => disPromise<*> = createAsyncAction(
    `${courseQuesUrl}/CourseDelete`,
    deleteClassData('POST'),
);

type getDetailParam = {
};
export const getDetailClass: (obj: getDetailParam) => disPromise<*> = createAsyncAction(
    `${courseQuesUrl}/CourseDetail`,
    getDetailData('GET'),
);

type getParentParams = {
};
export const getParentClass: (obj: getParentParams) => disPromise<*> = createAsyncAction(
    `${courseQuesUrl}/GetCourseTypeList`,
    getParentClassData('GET'),
);
export const fetchAllLabel: (obj: fetchAllLabelListParams) => disPromise<*> = createAsyncAction(
    `${eLabelUrl}/GetAllList`,
    fetchAllLabelListData('GET'),
  );

  export const Copybychapter: (obj: updateClassParams) => disPromise<*> = createAsyncAction(
    `${courseQuesUrl}/Copybychapter`,
    CopybychapterData('POST'),
);


export const Copybyids: (obj: updateClassParams) => disPromise<*> = createAsyncAction(
  `${courseQuesUrl}/Copybyids`,
  CopybyidsData('POST'),
);

