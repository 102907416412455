import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Icon } from 'antd';
import Link from '../page-link';
import './style.less';
import { getRouterChainByPath } from '../../../routers';

const { Item } = Breadcrumb;

export default class BreadcrumbComponent extends Component {
  static propTypes = {
    location: PropTypes.object, // 数据源
  };


  renderItems(pathname) {
    const paths = getRouterChainByPath([...pathname.split('/')]);
    const dataSource = paths.map(item => {
      return {
        key: item.router.path,
        text: item.name,
        path: item.router.path,
        icon: item.icon
      };
    });
    const iconStyle = { marginRight: 4 };
    if (dataSource && dataSource.length) {
      return dataSource.map(({ key, icon, text, path }) => {
        if (path) {
          return (
            <Item key={key}>
              <Link to={path}>
                {icon ? <Icon type={icon} style={iconStyle} /> : null}
                {text}
              </Link>
            </Item>
          );
        }
        return (
          <Item key={key}>
            {icon ? <Icon type={icon} style={iconStyle} /> : null}
            {text}
          </Item>
        );
      });
    }
    return null;
  }

  render() {
    const { location } = this.props;
    return (
      <div styleName="breadcrumb" className={`system-breadcrumb-default`}>
        <Breadcrumb>
          {this.renderItems(location.pathname)}
        </Breadcrumb>
      </div>
    );
  }
}
