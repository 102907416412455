/**
 * @flow
 */
import { ansyHandleFactory, handleActions } from 'utils/actionFactory';
import { createAsyncAction } from 'utils/request';
import { eAdvUrl } from './url';

const fetchListData = ansyHandleFactory('fetchList');
const addData = ansyHandleFactory('add');
const updateData = ansyHandleFactory('update');
const deleteData = ansyHandleFactory('deleteAdv');
const getDetailData = ansyHandleFactory('getDetail');

const advRedux = handleActions(
  fetchListData('GET', {
    pending: state => ({ ...state, list: [], loading: true, total: 0 }),
    accept: (state, action) => ({
      ...state,
      list: action.payload.pageData,
      total: action.payload.totalItems,
      loading: false
    }),
    reject: state => ({ ...state, list: [], loading: false, total: 0 }),
  }),
  addData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  updateData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  deleteData('GET', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  getDetailData('GET', {
    pending: (state) => ({ ...state, advDetail: {}, detailLoading: true, }),
    accept: (state, action) => ({ ...state, detailLoading: false, advDetail: action.payload }),
    reject: state => ({ ...state, advDetail: {}, detailLoading: false, })
  }),
  {
    loading: false,
    list: [],
    parentClass: [],
    classLoading: false,
    detailLoading: false,
    advDetail: {},
    total: 0,
  },
);

export default advRedux;

/* ************************************************** 同步请求数据 ************************************************ */

/* ************************************************** 异步请求数据 *********************************************** */
type fetchListParams = {
};

export const fetchList: (obj: fetchListParams) => disPromise<*> = createAsyncAction(
  `${eAdvUrl}/GetList`,
  fetchListData('GET'),
);

type addParams = {
};

export const add: (obj: addParams) => disPromise<*> = createAsyncAction(
  `${eAdvUrl}/Insert`,
  addData('POST'),
);

type updateParams = {
};

export const update: (obj: updateParams) => disPromise<*> = createAsyncAction(
  `${eAdvUrl}/update`,
  updateData('POST'),
);

type deleteParams = {
};
export const deleteAdv: (obj: deleteParams) => disPromise<*> = createAsyncAction(
  `${eAdvUrl}/delete`,
  deleteData('POST'),
);

type getDetailParam = {
};
export const getDetail: (obj: getDetailParam) => disPromise<*> = createAsyncAction(
  `${eAdvUrl}/detail`,
  getDetailData('GET'),
);
