/**
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Menu, Icon, message, Dropdown, Button, Modal,
  Tooltip
} from 'antd';
import { setHot, setRed, setShow, setRecommend, setTop, publishNews,setPushaction } from 'modules/bkArticleRedux';

import BatchForm from './form';
import './index.less';

type Props = {
  disabled: Boolean,
  ids: Array<string>,
  cb: Function, // 回调函数
  loading: Boolean,
  setHot: typeof setHot,
  setRed: typeof setRed,
  setShow: typeof setShow,
  setRecommend: typeof setRecommend,
  addAmountRead: typeof addAmountRead,
  publishNews: typeof publishNews,
  setPushaction: typeof setPushaction,
};
type State = {
  visible: Boolean,
  currentOperate: Object
};
class BatchOperation extends Component<Props, State> {
  form: Object;
  state = {
    visible: false,
    currentOperate: {}
  };

  KEY_BATCH = [{
    key: 1,
    name: '是否热点',
    mTitle: '热点设置',
    tips: '热点',
    method: this.props.setHot
  }, {
    key: 2,
    name: '是否置顶',
    mTitle: '置顶设置',
    tips: '置顶',
    method: this.props.setTop
  }, {
    key: 3,
    name: '是否标红',
    mTitle: '标红设置',
    tips: '标红',
    method: this.props.setRed
  }, {
    key: 4,
    name: '是否显示',
    mTitle: '显示设置',
    tips: '显示',
    method: this.props.setShow
  }, {
    key: 5,
    name: '是否推荐',
    mTitle: '推荐设置',
    tips: '推荐',
    method: this.props.setRecommend
  }, {
    key: 6,
    name: '批量发布',
    tips: '发布',
    mTitle: '发布文章',
    method: publishNews
  }, {
    key: 7,
    name: '百度推广',
    tips: '是否推广',
    mTitle: '推广',
    method: this.props.setPushaction
  }];

  handleMenuClick = (e) => {
    const { ids, cb } = this.props;
    if (!ids || ids.length === 0) {
      message.warning('请选择需要批量操作的项');
      return;
    }
    const { key } = e;
    const operate = this.KEY_BATCH.find(item => item.key === +key);
    if (+key === 6) { // 文章发布直接调用
      this.props.publishNews({
        key: ids,
      }).then(() => {
        message.success('发布成功');
        cb();
      });
      return;
    }
    if (+key === 7) { // 百度推广
      this.props.setPushaction({
        ids: ids,
      }).then((retdata) => {
        message.success(retdata.msg,10);
        cb();
      });
      return;
    }
    this.setState({
      visible: true,
      currentOperate: operate
    });
  }


  handleOk = () => {
    const { currentOperate } = this.state;
    const { method, tips } = currentOperate;
    const { ids, cb } = this.props;
    this.form.validateFields((err, values) => {
      if (err) return;
      const { check } = values;
      method({
        ids,
        flag: check ? true : false
      }).then(res => {
        const tipStr = check === 0 ? '取消' + tips : '设置' + tips;
        message.success(tipStr + '成功');
        this.handleCancel();
        cb();
      });
    });
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  }

  render() {
    const menu = (
      <Menu onClick={this.handleMenuClick} >
        {
          this.KEY_BATCH.map(item =>
            <Menu.Item key={item.key}>
              {item.name}
            </Menu.Item>)
        }
      </Menu>
    );

    const { visible, currentOperate } = this.state;
    const { labelName = '', mTitle = '' } = currentOperate;
    const { disabled } = this.props;
    return (
      <div styleName="batch-operate-wraper">
        {/*{*/}
          {/*disabled ? (*/}
            {/*<Tooltip placement="top" title="只有已发布的文章才能批量操作！">*/}
              {/*<Dropdown overlay={menu} disabled>*/}
                {/*<Button>*/}
                  {/*批量操作 <Icon type="down" />*/}
                {/*</Button>*/}
              {/*</Dropdown>*/}
            {/*</Tooltip>*/}
          {/*) : (*/}
              {/*<Dropdown overlay={menu} >*/}
                {/*<Button>*/}
                  {/*批量操作 <Icon type="down" />*/}
                {/*</Button>*/}
              {/*</Dropdown>*/}
            {/*)*/}
        {/*}*/}
          <Dropdown overlay={menu} >
              <Button>
                  批量操作 <Icon type="down" />
              </Button>
          </Dropdown>
        <Modal
          title={mTitle}
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose
        >
          <BatchForm labelName={labelName} ref={(form) => { return form ? this.form = form : null; }} ></BatchForm>
        </Modal>
      </div >
    );
  }
}


export default connect(
  (state) => {
    const loading = state.articleRedux;
    return ({ loading });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      setHot, setRed, setShow, setRecommend, setTop, publishNews,setPushaction
    }, dispatch,
  ),
)(BatchOperation);