/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import { Form, Input, Select, Switch, Upload, Icon, message, Radio } from 'antd';
import * as Validator from 'utils/validator';
const { Option } = Select;
import { fileUploadUrl } from 'modules/url';

const { TextArea } = Input;
const FormItem = Form.Item;
type Props = {
  form: Form,
  getDetailTheme: typeof getDetailTheme,
  parentTheme: Array<Object>,
  updateStatus: boolean,
  curretnItem: Object,
  imgUrl: String,
  labelList: Array<Object>,
}
type State = {
  fileList: Array<Object>,
}
class ThemeForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { updateStatus, imgUrl } = this.props;
    const fileList = [];
    if (updateStatus && imgUrl) {
      fileList.push({
        uid: '-1',
        name: '缩略图',
        status: 'done',
        url: imgUrl,
      });
    }
    this.state = {
      fileList
    };
  }

  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('图片大小不能超过20MB!');
    }
    return isLt2M;
  };

  upload = () => {

    const { fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
      </div>
    );
    const headers = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=ThemeType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        headers={headers}
        fileList={fileList}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState({ fileList });
        }}
      >
        {this.state.fileList.length >= 1 ? null : uploadButton}
      </Upload>
    );
  };

  normFile = e => {
    if (e && e.fileList) {
      if (!e.fileList[0]) {
        return [];
      }
      const { status } = e.fileList[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList;
    }
    return [];
  };

  getParentData = () => {
    const { parentTheme } = this.props;
    const { id } = this.props.currentItem;

    var data = [];
    parentTheme.forEach((item) => {
      if (item.id != id) {
        data.push(item);
      }
    });
    return data;
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 19 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;
    const {
      plids,name, parentid, isshow, sort = 0, img, remark, showtitle, keywords, description
    } = this.props.currentItem;

    const { updateStatus, labelList,
      classTree, themeTree } = this.props;
    return (
      <Form>
        <FormItem
          label="名称"
          {...formItemLayout}
        >
          {getFieldDecorator('name', {
            initialValue: name || '',
            rules: [
              {
                required: true, message: '请输入名称',
              }],
          })(
            <Input placeholder="请输入名称" />,
          )}
        </FormItem>
        <FormItem
          label="一级专题"
          {...formItemLayout}
        >
          {getFieldDecorator('parentid', {
            initialValue: parentid,
          })(
            <Select placeholder="请选择一级专题" >
              {this.getParentData().map(item => <Option value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem
              label="标签"
              {...formItemLayout}
            >
              {getFieldDecorator('plids', {
                initialValue: plids || [],
                rules: [
                  {
                    required: true, message: '标签不能为空',
                  }],
              })(
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  showSearch
                  placeholder="请选择标签"
                >
                  {labelList ? labelList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>) : ''}
                  {/* {children} */}
                </Select>,
              )}
            </FormItem>
        <FormItem
          label="网页标签"
          {...formItemLayout}
        >
          {getFieldDecorator('showtitle', {
            initialValue: showtitle || '',
          })(
            <Input placeholder="请输入网页标签" />,
          )}
        </FormItem>
        <FormItem
          label="网页关键字"
          {...formItemLayout}
        >
          {getFieldDecorator('keywords', {
            initialValue: keywords || '',
          })(
            <Input placeholder="请输入网页关键字" />,
          )}
        </FormItem>
        <FormItem
          label="网页描述"
          {...formItemLayout}
        >
          {getFieldDecorator('description', {
            initialValue: description || '',
            rules: [
              {
                required: false, message: '网页描述不能为空',
              }],
          })(
            <TextArea placeholder="请输入网页描述" />,
          )}
        </FormItem>
       
        <FormItem
          label="是否显示"
          {...formItemLayout}
        >
          {getFieldDecorator('isshow', {
            initialValue: isshow,
          })(
            <Radio.Group  >
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem
          label="顺序"
          {...formItemLayout}
        >
          {getFieldDecorator('sort', {
            initialValue: sort || 0,
            rules: [{
              required: false, message: '',
            }, {
              message: '请输入正整数',
              pattern: /^[0-9]\d*$/,
            }],
          })(
            <Input placeholder="越小越靠前" />,
          )}
        </FormItem>
        <FormItem
          label="图片"
          {...formItemLayout}
        >
          {getFieldDecorator('img', {
            initialValue: img || '',
            getValueFromEvent: this.normFile
          })(
            this.upload()
          )}
        </FormItem>
        <FormItem
          label="备注"
          {...formItemLayout}
        >
          {getFieldDecorator('remark', {
            initialValue: remark || ''
          })(
            <TextArea placeholder="请输入备注" />,
          )}
        </FormItem>
      </Form >
    );
  };
}

export default Form.create()(ThemeForm);
