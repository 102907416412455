/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, message, Spin } from 'antd';
import {
  addArticle, updateArticle,
  getArticleDetail,
} from 'modules/aQuesRedux';

import ArticleForm from './form';
import { isArray } from 'util';

type Props = {
  updateStatus: Boolean,
  curretnItem: Object,
  backToPageList: Function,
  addArticle: typeof addArticle,
  updateArticle: typeof updateArticle,
  loading: Boolean,
  labelList: Array<Object>,
  labelLoading: boolean,
  classTree: Array<Object>,
  classTreeLoading: boolean,
  themeTree: Array<Object>,
  themeTreeLoading: boolean,
  getArticleDetail: typeof getArticleDetail,
  articleDetail: Object,
};
class Article extends Component<Props, State> {
  form: Object;
  state = {};

  componentWillMount = () => {
    const { updateStatus } = this.props;
    updateStatus && this.getArticleDetail();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.updateStatus !== prevProps.updateStatus && this.props.updateStatus) {
      this.getArticleDetail();
    }
  }

  getArticleDetail = () => {
    const { id } = this.props.curretnItem;
    this.props.getArticleDetail({
      id
    });
  }

  submit = () => {
    this.form.validateFields((err, values) => {
      if (err) return;
      const { updateStatus, curretnItem, backToPageList, addArticle, updateArticle } = this.props;
      const imgUrl = isArray(values.img) ? values.img[0].response.data : values.img;
      const imgUrl_m = values.img_m && values.img_m[0] ?
      (values.img_m[0].response ? values.img_m[0].response.data : values.img_m) : '';
      const { atids, 
        // ttids
       } = values;
       const ttids = [];
      const params = {
        ...values,
        atids: isArray(atids) ? atids : [atids],
        // ttids: isArray(ttids) ? ttids : [ttids],
        // ttids:[],
        img: imgUrl,
        img_m:imgUrl_m
      };
      if (updateStatus) {
        updateArticle({
          ...curretnItem,
          ...params,
        }).then(res => {
          message.success('编辑成功');
          backToPageList();
        });
      } else {
        addArticle({
          ...params
        }).then(res => {
          message.success('新增成功');
          backToPageList();
        });
      }
    });
  }

  render() {
    const { updateStatus,
      backToPageList, loading,
      labelList, labelLoading,
      classTree, classTreeLoading,
      themeTree, themeTreeLoading,
      articleDetail,
      curretnItem
    } = this.props;
    return (
      <div className="add-wraper">
        <div className="page-header">
          <Button className="btn-default" onClick={backToPageList}>返回上级</Button>
        </div>
        <Spin spinning={loading || labelLoading || classTreeLoading || themeTreeLoading}>
          <div className="add-content">
            <ArticleForm
              curretnItem={updateStatus ? articleDetail : {}}
              imgUrl={curretnItem.img}
              imgUrl_m={curretnItem.img_m}
              labelList={labelList}
              classTree={classTree}
              themeTree={themeTree}
              updateStatus={updateStatus}
              backToPageList={backToPageList}
              submit={this.submit}
              ref={(form) => { return form ? this.form = form : null; }} />
          </div>
        </Spin>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { articleRedux } = state;

    return ({ ...articleRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      addArticle, updateArticle, getArticleDetail
    }, dispatch,
  ),
)(Article);