/**
 * @flow
 */
import { ansyHandleFactory, handleActions } from 'utils/actionFactory';
import { createAsyncAction } from 'utils/request';
import { ansQuesUrl } from './url';

const fetchListData = ansyHandleFactory('fetchList');
const addData = ansyHandleFactory('add');
const updateData = ansyHandleFactory('update');
const deleteData = ansyHandleFactory('deleteComment');

const fetchRepListData = ansyHandleFactory('fetchRepList');
const addRepData = ansyHandleFactory('addRep');
const updateRepData = ansyHandleFactory('updateRep');
const deleteRepData = ansyHandleFactory('deleteRep');

const eCommentRedux = handleActions(
  fetchListData('GET', {
    pending: state => ({ ...state, list: [], loading: true, total: 0 }),
    accept: (state, action) => ({ ...state, list: action.payload.pageData, total: action.payload.totalItems, loading: false, commentid:action.payload.pageData.length?action.payload.pageData.id:0 }),
    reject: state => ({ ...state, list: [], loading: false, total: 0 }),
  }),
  addData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  updateData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  deleteData('GET', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  fetchRepListData('GET', {
    pending: state => ({ ...state, listRep: [], loadingRep: true, }),
    accept: (state, action) => ({ ...state, listRep: action.payload,  loadingRep: false }),
    reject: state => ({ ...state, listRep: [], loadingRep: false, }),
  }),
  addRepData('POST', {
    pending: (state) => ({ ...state, loadingRep: true }),
    accept: (state) => ({ ...state, loadingRep: false }),
    reject: (state) => ({ ...state, loadingRep: false }),
  }),
  updateRepData('POST', {
      pending: (state) => ({ ...state, loadingRep: true }),
    accept: (state) => ({ ...state, loadingRep: false }),
      reject: (state) => ({ ...state, loadingRep: false }),
  }),
  deleteRepData('GET', {
      pending: (state) => ({ ...state, loadingRep: true }),
    accept: (state) => ({ ...state, loadingRep: false }),
      reject: (state) => ({ ...state, loadingRep: false }),
  }),
  {
    loading: false,
    list: [],
    total: 0,
    commentid: 0,

    loadingRep: false,
    listRep: [],
    totalRep: 0,
  },
);

export default eCommentRedux;

/* ************************************************** 同步请求数据 ************************************************ */

/* ************************************************** 异步请求数据 *********************************************** */
type fetchListParams = {
};

export const fetchList: (obj: fetchListParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/GetAnswerList`,
  fetchListData('GET'),
);

type addParams = {
};

export const add: (obj: addParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/AdminInsertAnswer`,
  addData('POST'),
);

type updateParams = {
};

export const update: (obj: updateParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/AdminUpdateAnswer`,
  updateData('POST'),
);

type deleteParams = {
};
export const deleteComment: (obj: deleteParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/AdminDelAnswer`,
  deleteData('POST'),
);
type fetchListRepParams = {
};

export const fetchRepList: (obj: fetchListRepParams) => disPromise<*> = createAsyncAction(
    `${ansQuesUrl}/GetReflexList`,
    fetchRepListData('GET'),
);

type addRepParams = {
};

export const addRep: (obj: addRepParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/AdminInsertReflex`,
  addRepData('POST'),
);

type updateRepParams = {
};

export const updateRep: (obj: updateRepParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/AdminUpdateReflex`,
  updateRepData('POST'),
);

type deleteRepParams = {
};
export const deleteRep: (obj: deleteRepParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/AdminDelReflex`,
  deleteRepData('POST'),
);