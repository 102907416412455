/**
 * @flow
 */
import { ansyHandleFactory, handleActions } from 'utils/actionFactory';
import { createAsyncAction } from 'utils/request';
import { eClassUrl, eLabelUrl } from './url';

const fetchLabelListData = ansyHandleFactory('fetchLabelList');
const addLabelData = ansyHandleFactory('addLabel');
const updateLabelData = ansyHandleFactory('updateLabel');
const getDetailData = ansyHandleFactory('getDetail');
const deleteLabelData = ansyHandleFactory('deleteLabel');

const LabelRedux = handleActions(
  fetchLabelListData('GET', {
    pending: state => ({ ...state, list: [], loading: true, total: 0 }),
    accept: (state, action) => ({ ...state, list: action.payload.pageData, total: action.payload.totalItems, loading: false }),
    reject: state => ({ ...state, list: [], loading: false, total: 0 }),
  }),
  addLabelData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  updateLabelData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  deleteLabelData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  getDetailData('GET', {
    pending: (state) => ({ ...state, labelDetail: {}, detailLoading: true, }),
    accept: (state, action) => ({ ...state, detailLoading: false, labelDetail: action.payload }),
    reject: state => ({ ...state, labelDetail: {}, detailLoading: false, })
  }),
  {
    loading: false,
    labelDetail: {},
    detailLoading: false,
    list: [],
    total: 0,
  },
);

export default LabelRedux;

/* ************************************************** 同步请求数据 ************************************************ */

/* ************************************************** 异步请求数据 *********************************************** */
type fetchLabelListParams = {
};

export const fetchLabelList: (obj: fetchLabelListParams) => disPromise<*> = createAsyncAction(
  `${eLabelUrl}/GetList`,
  fetchLabelListData('GET'),
);

type addLabelParams = {
};

export const addLabel: (obj: addLabelParams) => disPromise<*> = createAsyncAction(
  `${eLabelUrl}/Insert`,
  addLabelData('POST'),
);

type updateLabelParams = {
};

export const updateLabel: (obj: updateLabelParams) => disPromise<*> = createAsyncAction(
  `${eLabelUrl}/Update`,
  updateLabelData('POST'),
);

type deleteLabelParams = {
};
export const deleteLabel: (obj: deleteLabelParams) => disPromise<*> = createAsyncAction(
  `${eLabelUrl}/Delete`,
  updateLabelData('POST'),
);

type getDetailParam = {
};
export const getDetailClass: (obj: getDetailParam) => disPromise<*> = createAsyncAction(
  `${eLabelUrl}/detail`,
  getDetailData('GET'),
);
