/**
 * @flow
 */
import { ansyHandleFactory, handleActions } from 'utils/actionFactory';
import { createAsyncAction } from 'utils/request';
import { ansThemeUrl ,ansThemeTreeUrl,eLabelUrl } from './url';

const fetchThemeListData = ansyHandleFactory('fetchThemeList');
const addThemeData = ansyHandleFactory('addTheme');
const updateThemeData = ansyHandleFactory('updateTheme');
const deleteThemeData = ansyHandleFactory('deleteTheme');
const getDetailData = ansyHandleFactory('getDetail');
const getParentThemeData = ansyHandleFactory('getParentTheme');
const fetchClassTreeData = ansyHandleFactory('fetchClassTreeData'); // 获取分类树
const fetchAllLabelListData = ansyHandleFactory('fetchAllLabelListData'); // 获取所有标签

const traversalTree = (tree) => {
    if (!tree) return;
  
    tree.map(item => {
      if (item.children) {
        traversalTree(item.children);
      }
      // if (item.level === 0) {
      //   item.disabled = true;
      // }
      item.key = item.id;
      item.title = item.name;
      item.value = item.id;
    });
    return tree;
  };
const ThemeRedux = handleActions(
  fetchThemeListData('GET', {
    pending: state => ({ ...state, list: [], loading: true,total: 0 }),
    accept: (state, action) => ({ ...state, list: action.payload.pageData,total: action.payload.totalItems, loading: false }),
    reject: state  => ({ ...state, list: [], loading: false,total: 0 }),
  }),
  addThemeData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  updateThemeData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  deleteThemeData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  fetchClassTreeData('GET', {
    pending: state => ({ ...state, classTree: [], classTreeLoading: true }),
    accept: (state, action) => {
      return {
        ...state, classTree: traversalTree(action.payload), classTreeLoading: false
      };
    },
    reject: state => ({ ...state, classTree: [], classTreeLoading: false }),
  }),
  getDetailData('GET', {
    pending: (state) => ({ ...state, ThemeDetail: {}, detailLoading: true, }),
    accept: (state, action) => ({ ...state, detailLoading: false, ThemeDetail: action.payload }),
    reject: state => ({ ...state, ThemeDetail: {}, detailLoading: false, })
  }),
  getParentThemeData('GET', {
    pending: (state) => ({ ...state, parentTheme: [], ThemeLoading: true }),
    accept: (state, action) => ({ ...state, parentTheme: action.payload, ThemeLoading: false }),
    reject: (state) => ({ ...state, parentTheme: [], ThemeLoading: false }),
  }),
  fetchAllLabelListData('GET', {
    pending: state => ({ ...state, labelList: [], labelLoading: true }),
    accept: (state, action) => {
      return {
        ...state, labelList: action.payload, labelLoading: false
      };
    },
    reject: state => ({ ...state, labelList: [], labelLoading: false }),
  }),
  {
    loading: false,
    list: [],
    parentTheme: [],
    ThemeLoading: false,
    detailLoading: false,
    ThemeDetail: {},
    total: 0,
  },
);

export default ThemeRedux;

/* ************************************************** 同步请求数据 ************************************************ */

/* ************************************************** 异步请求数据 *********************************************** */
type fetchThemeListParams = {
};

export const fetchThemeList: (obj: fetchThemeListParams) => disPromise<*> = createAsyncAction(
  `${ansThemeUrl}/GetFaqList`,
  fetchThemeListData('GET'),
);

type addThemeParams = {
};

export const addTheme: (obj: addThemeParams) => disPromise<*> = createAsyncAction(
  `${ansThemeUrl}/FaqInsert`,
  addThemeData('POST'),
);

type updateThemeParams = {
};

export const updateTheme: (obj: updateThemeParams) => disPromise<*> = createAsyncAction(
  `${ansThemeUrl}/FaqUpdate`,
  updateThemeData('POST'),
);

type deleteThemeParams = {
};
export const deleteTheme: (obj: deleteThemeParams) => disPromise<*> = createAsyncAction(
  `${ansThemeUrl}/FaqDelete`,
  deleteThemeData('POST'),
);

type getDetailParam = {
};
export const getDetailTheme: (obj: getDetailParam) => disPromise<*> = createAsyncAction(
  `${ansThemeUrl}/detail`,
  getDetailData('GET'),
);

type getParentParams = {
};
export const getParentTheme: (obj: getParentParams) => disPromise<*> = createAsyncAction(
  `${ansThemeUrl}/FaqChildThemeList`,
  getParentThemeData('GET'),
);
type fetchClassTreeParams = {
};
export const fetchClassTree: (obj: fetchClassTreeParams) => disPromise<*> = createAsyncAction(
    `${ansThemeTreeUrl}`,
    fetchClassTreeData('GET'),
  );

  export const fetchAllLabel: (obj: fetchAllLabelListParams) => disPromise<*> = createAsyncAction(
    `${eLabelUrl}/GetAllList`,
    fetchAllLabelListData('GET'),
  );
