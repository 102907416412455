/**
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import md5 from 'crypto-js/md5';
import { message } from 'antd';
import { loginPlarform,loginPlarformToken } from 'modules/loginRedux';
import { LoginForm } from './form';
import Logo_pic from 'images/logo_img.png';

import './style/index.less';

type Props = {
  loginPlarform: typeof loginPlarform,
  loginPlarformToken: typeof loginPlarform,
};
type State = {
  username: string,
  password: string,
  keyid: string,
  secret: string,
};
class LoginPalatform extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      keyid:'',
      secret:'',
      dataArr:[]
    };
    this.logOn = this.logOn.bind(this);
  }

  componentWillMount = () => {
    const loginStatus = sessionStorage.getItem('loginStatus');
    if(window.location.href=='https://kbm.musicheng.com/#/login'){
      window.location.href="http://kbm.musicheng.com/#/login"; 
    }
    if(window.location.href.indexOf('?')>0)
    {
      this.setState({
        dataArr:this.props.location.search.split('&')
      },
        ()=>{
          if(this.state.dataArr.length>0){
            this.props.loginPlarformToken({
              accesskeyid: 'msc_admin',
              accesskeysecret: md5('admin_secret'),
              grant_type: 'password',
              keyid: this.state.dataArr[0].replace(/[^0-9]/ig,""),
              secret: this.state.dataArr[1].substr(7),
            }).then((res) => {
              message.success('登录成功');
              const { access_Token,userID ,userName} = res;
              sessionStorage.setItem('loginStatus', 'login');
              sessionStorage.setItem('Authorization', 'Bearer ' + access_Token);
              sessionStorage.setItem('userID', userID);
              sessionStorage.setItem('userName', userName);
              this.props.history.replace(`${ROUTE_PREFIX}`);
            });
          }
        }
      )
    }
    if (loginStatus) this.props.history.replace(`${ROUTE_PREFIX}`);
  }

 

  onChangeUserName = (e) => {
    this.setState({ username: e.target.value });
  }

  onChangePassWord = (e) => {
    this.setState({ password: e.target.value });
  }

  logOn = () => {
    const { username, password } = this.state;
    this.props.loginPlarform({
      accesskeyid: 'msc_admin',
      accesskeysecret: md5('admin_secret'),
      grant_type: 'password',
      loginname: username,
      password: md5(password),
    }).then((res) => {
      message.success('登录成功');
      const { access_Token,userID ,userName} = res;
      sessionStorage.setItem('loginStatus', 'login');
      sessionStorage.setItem('Authorization', 'Bearer ' + access_Token);
      sessionStorage.setItem('userID', userID);
      sessionStorage.setItem('userName', userName);
      this.props.history.replace(`${ROUTE_PREFIX}`);
    });
  };

  render() {
    return (
      <div styleName="container">
        <div styleName="head">
          <div styleName="logo"><img src={Logo_pic} /></div>
        </div>
        <div styleName="body">
          <div styleName="login-wraper">
            <div styleName="login-header">
              <div styleName="logo_pic"><img src={Logo_pic} /></div>
            </div>
            <div styleName="login-content" >
              <LoginForm logOn={this.logOn} onChangeUserName={this.onChangeUserName} onChangePassWord={this.onChangePassWord} />
            </div>
          </div>
        </div>
        <div styleName="footer">
          Copyright © 2012-2018 幕思城 musicheng.com 版权所有  备案号：蜀ICP备12026534号
                </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { loginRedux } = state;
    return ({ ...loginRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    { loginPlarform,loginPlarformToken }, dispatch,
  ),
)(LoginPalatform);
