/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import { Tooltip,Form, Input, Select, Checkbox, Upload, Icon, message, Radio, Col } from 'antd';
import * as Validator from 'utils/validator';
const { Option } = Select;
import { fileUploadUrl } from 'modules/url';

const { TextArea } = Input;
const FormItem = Form.Item;
type Props = {
  form: Form,
  getDetailClass: typeof getDetailClass,
  parentClass: Array<Object>,
  updateStatus: boolean,
  currentItem: Object,
  imgUrl: String,
  labelList: Array<Object>,
  isHidden: boolean,
}
type State = {
  fileList: Array<Object>,
}
class ClassForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { updateStatus, imgUrl,imgUrl1_m } = this.props;
    const fileList = [];
    const mfileList1 = [];
    if (updateStatus && imgUrl) {
      fileList.push({
        uid: '-1',
        name: '缩略图',
        status: 'done',
        url: imgUrl,
      });
    }
    if (updateStatus&& imgUrl1_m) {
      mfileList1.push({
        uid: '-2',
        name: '列表展示图',
        status: 'done',
        url: imgUrl1_m,
      });
    }
    this.state = {
      fileList:fileList,
      mfileList1:mfileList1,
    };
  }

  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('图片大小不能超过20MB!');
    }
    return isLt2M;
  };

  upload = () => {

    const { fileList } = this.state;
    const uploadButton = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
          <Icon type="plus" />
        <div style={{color: 'red',position: 'absolute',right: '-155px',top: 0,pointerEvents:'none'}}>图片大小:80px*80px</div>
      </div>
    );
    const headers = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=ArticleType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={fileList}
        headers={headers}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState({ fileList });
        }}
      >
        {this.state.fileList.length >= 1 ? null : uploadButton}
        
      </Upload>
    );

  };
  mupload1 = () => {
    const { mfileList1 } = this.state;
    const uploadButton1 = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
    <div style={{color: 'red',position: 'absolute',right: '-100px',top: '-15px',pointerEvents:'none'}}>图片大小:()</div>
  </div>
    );
    const headers1 = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=CourseType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={mfileList1}
        headers={headers1}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState((state1)=>({mfileList1:fileList}));
        }}
      >
        {this.state.mfileList1.length >= 1 ? null : uploadButton1}
      </Upload>
    );

  };
  normFile = e => {
    if (e && e.fileList) {
      if (!e.fileList[0]) {
        return [];
      }
      const { status } = e.fileList[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList;
    }
    return [];
  };

  getParentClassData = () => {
    const { parentClass } = this.props;
    const { id } = this.props.currentItem;
    var data = [];
    parentClass.forEach((item) => {
      if (item.id != id) {
        data.push(item);
      }
    });
    return data;
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 19 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;
    const {
      plids, name, parentid, isshow, sort = 0, img, remark, showtitle, spell,keywords, description,isnav,img_m
    } = this.props.currentItem;
    const { labelList} = this.props;
    return (
      <Form>
        <FormItem
          label="分类名称"
          {...formItemLayout}
        >
          {getFieldDecorator('name', {
            initialValue: name || '',
            rules: [
              {
                required: true, message: '请输入名称',
              }],
          })(
            <Input placeholder="请输入名称" />,
          )}
        </FormItem>
        <FormItem
          label="分类级别"
          {...formItemLayout}
          style={{display:this.props.isHidden?'block':'none'}}
        >
          {getFieldDecorator('remark', {
            initialValue: remark || '请选择分类'
          })(
            <Select>
              <Option value="淘宝系">淘宝系</Option>
              <Option value="拼多多系列">拼多多系列</Option>
              <Option value="shopee系列">shopee系列</Option>
              <Option value="抖音系列">抖音系列</Option>
              <Option value="其他">其他</Option>
            </Select>
          )}
        </FormItem>
        <FormItem
          label="一级分类"
          {...formItemLayout}
        >
          {getFieldDecorator('parentid', {
            initialValue: parentid ? parseInt(parentid, 0) : 0,
          })(
            <Select placeholder="请选择一级分类" >
              {this.getParentClassData().map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem
              label="标签"
              {...formItemLayout}
            >
              {getFieldDecorator('plids', {
                initialValue: plids || [],
                rules: [
                  {
                    required: true, message: '标签不能为空',
                  }],
              })(
                <Select
                  mode="multiple"
                  placeholder="请选择文章标签"
                  optionFilterProp="children"
                  showSearch
                >
                  {labelList ? labelList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>) : ''}
                </Select>,
              )}
            </FormItem>
        <FormItem
          label="拼音"
          {...formItemLayout}
        >
          {getFieldDecorator('spell', {
            initialValue: spell || '',
            rules: [{
              required: true, message: '请输入拼音',
            }, {
              message: '请输入正确格式',
              pattern: /^(?!\d+$)[\da-zA-Z]*$/,
            }],
          })(
            <Input placeholder="请输入拼音" />,
          )}
        </FormItem>
        <FormItem
          label="网页标签"
          {...formItemLayout}
        >
          {getFieldDecorator('showtitle', {
            initialValue: showtitle || '',
          })(
            <Input placeholder="请输入网页标签 (编辑自行设置)" />,
          )}
        </FormItem>
        
        <FormItem
          label="网页关键字"
          {...formItemLayout}
        >
          {getFieldDecorator('keywords', {
            initialValue: keywords || '',
          })(
            <Input placeholder="请输入网页关键字 (编辑自行设置)" />,
          )}
        </FormItem>
        
        <FormItem
          label="网页描述"
          {...formItemLayout}
        >
          {getFieldDecorator('description', {
            initialValue: description || '',
            rules: [
              {
                required: false, message: '网页描述不能为空',
              }],
          })(
            <TextArea placeholder="请输入网页描述 (编辑自行设置)" />,
          )}
        </FormItem>
        <FormItem
          label="是否显示"
          {...formItemLayout}
        >
          {getFieldDecorator('isshow', {
            initialValue: isshow,
          })(
            <Radio.Group  >
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem
          label="是否导航"
          {...formItemLayout}
        >
          {getFieldDecorator('isnav', {
            initialValue: isnav,
          })(
            <Radio.Group  >
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem
          label="顺序"
          {...formItemLayout}
        >
          {getFieldDecorator('sort', {
            initialValue: sort || 0,
            rules: [{
              required: false, message: '请输入顺序',
            }, {
              message: '请输入正整数',
              pattern: /^[0-9]\d*$/,
            }],
          })(
            <Input placeholder="越小越靠前" />,
          )}
        </FormItem>
        
        <FormItem
          label="缩略图(PC)"
          {...formItemLayout}
        >
          {getFieldDecorator('img', {
            initialValue: img,
            getValueFromEvent: this.normFile
          })(
            this.upload()
          )}
        </FormItem>
        {/* <FormItem
          label="缩略图(移动)"
          {...formItemLayout}
        >
          {getFieldDecorator('img_m', {
            initialValue: img_m,
            getValueFromEvent: this.normFile
          })(
            this.mupload1()
          )}
        </FormItem> */}
      </Form >
    );
  };
}

export default Form.create()(ClassForm);
