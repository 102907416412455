/**
 * @flow
 */
import { ansyHandleFactory, handleActions } from 'utils/actionFactory';
import { createAsyncAction } from 'utils/request';
import { ansTreeUrl, ansThemeTreeUrl,eLabelUrl,ansQuesUrl } from './url';

const fetchArticleListData = ansyHandleFactory('fetchArticleList');
const getArticleDetailData = ansyHandleFactory('getArticleDetailData');
const addArticleData = ansyHandleFactory('addArticle');
const updateArticleData = ansyHandleFactory('updateArticle');
const deleteArticleData = ansyHandleFactory('deleteArticle'); // 逻辑删除
const phydeleteArticleData = ansyHandleFactory('phydeleteArticleData'); // 完全删除
const fetchClassTreeData = ansyHandleFactory('fetchClassTreeData'); // 获取分类树
const fetchThemeTreeData = ansyHandleFactory('fetchThemeTreeData'); // 获取专题树
const fetchAllLabelListData = ansyHandleFactory('fetchAllLabelListData'); // 获取所有标签
const setHotData = ansyHandleFactory('setHotData');
const setTopData = ansyHandleFactory('setTopData');
const setRedData = ansyHandleFactory('setRedData');
const setShowData = ansyHandleFactory('setShowData');
const setRecommendData = ansyHandleFactory('setRecommendData');
const addAmountReadData = ansyHandleFactory('addAmountReadData');
const publishNewsData = ansyHandleFactory('publishNewsData');
const addReadNumData = ansyHandleFactory('addReadNumData');
const revcoverArticleData = ansyHandleFactory('revcoverArticleData');
const setPushactionData = ansyHandleFactory('setPushactionData');

const traversalTree = (tree) => {
  if (!tree) return;

  tree.map(item => {
    if (item.children) {
      traversalTree(item.children);
    }
    // if (item.level === 0) {
    //   item.disabled = true;
    // }
    item.key = item.id;
    item.title = item.name;
    item.value = item.id;
  });
  return tree;
};

const ArticleRedux = handleActions(
  fetchArticleListData('GET', {
    pending: state => ({ ...state, list: [], loading: true, total: 0 }),
    accept: (state, action) => ({
      ...state, list: action.payload.pageData,
      total: action.payload.totalItems, loading: false
    }),
    reject: state => ({ ...state, list: [], loading: false, total: 0 }),
  }),
  getArticleDetailData('GET', {
    pending: state => ({ ...state, loading: true, articleDetail: {} }),
    accept: (state, action) => ({ ...state, articleDetail: action.payload, loading: false }),
    reject: state => ({ ...state, loading: false, articleDetail: {} }),
  }),
  addArticleData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  updateArticleData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  deleteArticleData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  phydeleteArticleData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  fetchAllLabelListData('GET', {
    pending: state => ({ ...state, labelList: [], labelLoading: true }),
    accept: (state, action) => {
      return {
        ...state, labelList: action.payload, labelLoading: false
      };
    },
    reject: state => ({ ...state, labelList: [], labelLoading: false }),
  }),
  fetchClassTreeData('GET', {
    pending: state => ({ ...state, classTree: [], classTreeLoading: true }),
    accept: (state, action) => {
      return {
        ...state, classTree: traversalTree(action.payload), classTreeLoading: false
      };
    },
    reject: state => ({ ...state, classTree: [], classTreeLoading: false }),
  }),
  fetchThemeTreeData('GET', {
    pending: state => ({ ...state, themeTree: [], themeTreeLoading: true }),
    accept: (state, action) => ({ ...state, themeTree: traversalTree(action.payload), themeTreeLoading: false }),
    reject: state => ({ ...state, themeTree: [], themeTreeLoading: false }),
  }),
  setHotData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  setTopData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  setRedData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  setShowData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  setRecommendData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  setPushactionData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  
  addAmountReadData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  publishNewsData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  addReadNumData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  revcoverArticleData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  {
    loading: false,
    list: [],
    total: 0,
    classTree: [],
    classTreeLoading: false,
    themeTree: [],
    themeTreeLoading: false,
    labelList: [],
    labelLoading: false,
    articleDetail: {},
  },
);

export default ArticleRedux;

/* ************************************************** 同步请求数据 ************************************************ */

/* ************************************************** 异步请求数据 *********************************************** */
type fetchArticleListParams = {
};

export const fetchArticleList: (obj: fetchArticleListParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/GetList`,
  fetchArticleListData('GET'),
);

type getArticleDetailDataParams = {
};

export const getArticleDetail: (obj: getArticleDetailDataParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/GetDetail`,
  getArticleDetailData('GET'),
);

type addArticleParams = {
};

export const addArticle: (obj: addArticleParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/Insert`,
  addArticleData('POST'),
);

type updateArticleParams = {
};

export const updateArticle: (obj: updateArticleParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/Update`,
  updateArticleData('POST'),
);

type deleteArticleParams = {
};
export const deleteArticle: (obj: deleteArticleParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/Delete`,
  deleteArticleData('POST'),
);
export const phyDeleteArticle: (obj: deleteArticleParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/CompleteDelete`,
  phydeleteArticleData('POST'),
);

type fetchClassTreeParams = {
};

export const fetchClassTree: (obj: fetchClassTreeParams) => disPromise<*> = createAsyncAction(
  `${ansTreeUrl}`,
  fetchClassTreeData('GET'),
);

type fetchThemeTreeParams = {
};

export const fetchThemeTree: (obj: fetchThemeTreeParams) => disPromise<*> = createAsyncAction(
  `${ansThemeTreeUrl}`,
  fetchThemeTreeData('GET'),
);

type fetchAllLabelListParams = {
};

export const fetchAllLabel: (obj: fetchAllLabelListParams) => disPromise<*> = createAsyncAction(
  `${eLabelUrl}/GetAllList`,
  fetchAllLabelListData('GET'),
);

type batchParams = {
};

export const setHot: (obj: batchParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/SetHot`,
  setHotData('POST'),
);

export const setTop: (obj: batchParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/SetTop`,
  setTopData('POST'),
);

export const setRed: (obj: batchParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/SetRed`,
  setRedData('POST'),
);

export const setShow: (obj: batchParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/SetShow`,
  setShowData('POST'),
);

export const setRecommend: (obj: batchParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/SetRecommend`,
  setRecommendData('POST'),
);
// 百度推广
export const setPushaction: (obj: batchParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/Pushaction1`,
  setPushactionData('POST'),
);
// export const addAmountRead: (obj: batchParams) => disPromise<*> = createAsyncAction(
//   `${ansQuesUrl}/AddAmountRead`,
//   addAmountReadData('POST'),
// );

type publishNewsParams = {
  key: Array<string>
}
export const publishNews: (obj: publishNewsParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/Publish`,
  publishNewsData('POST'),
);

type addReadNumParams = {
}
// export const addReadNum: (obj: addReadNumParams) => disPromise<*> = createAsyncAction(
//   `${ansQuesUrl}/AddAmountRead`,
//   addReadNumData('POST'),
// );
type revcoverArticleParams = {
}
export const revcoverArticle: (obj: revcoverArticleParams) => disPromise<*> = createAsyncAction(
  `${ansQuesUrl}/Recovery`,
  revcoverArticleData('POST'),
);
