/**
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setBreadcrumbs } from 'modules/systemRedux';
import { Layout, Icon, Row, Col } from 'antd';
import Link from '../page-link';
import Logo from '../logo';
import HeaderUser from '../header-user';
import LeftNav from '../leftNav';
import BreadcrumbComponent from '../breadcrumb';

import './index.less';

const { Header, Sider, Content } = Layout;
type Props = {
  children: any,
  breadcrumbs: Array<Object>,
  setBreadcrumbs: typeof setBreadcrumbs,
}
type State = {
  collapsed: Boolean,
}
class Frame extends Component<Props, State> {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { location } = this.props;
    return (
      <Layout styleName="base-frame">
        <Sider trigger={null} collapsible
          collapsed={this.state.collapsed} width="200" styleName="sider">
          <div styleName="logo-wraper" >
            <Link to="/">
              <Logo
                min={this.state.collapsed}
                title="幕思城后台管理"
              />
            </Link>
          </div>
          <LeftNav collapsed={this.state.collapsed}
            location={location} />
        </Sider>
        <Layout>
          <Header styleName="header" >
            <Row>
              <Col span={10} >
                <Icon
                  styleName="trigger"
                  type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={this.toggle}
                />
                <BreadcrumbComponent location={location} />
              </Col>
              <Col span={14} style={{ textAlign: 'right' }}>
                <HeaderUser />
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              padding: 20,
              minHeight: 280,
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default connect(
  (state) => {
    const { systemReducer } = state;
    return ({ breadcrumbs: systemReducer.breadcrumbs });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      setBreadcrumbs
    }, dispatch,
  ),
)(Frame);