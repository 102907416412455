/**
 * @flow
 */
import { ansyHandleFactory, handleActions } from 'utils/actionFactory';
import { createAsyncAction } from 'utils/request';
import { courseQuesUrl,eLabelUrl } from './url';

const fetchThemeListData = ansyHandleFactory('fetchThemeList');
const addThemeData = ansyHandleFactory('addTheme');
const updateThemeData = ansyHandleFactory('updateTheme');
const deleteThemeData = ansyHandleFactory('deleteTheme');
const getDetailData = ansyHandleFactory('getDetail');
const getParentThemeData = ansyHandleFactory('getParentTheme');
const fetchAllLabelListData = ansyHandleFactory('fetchAllLabelListData'); // 获取所有标签

const ThemeRedux = handleActions(
  fetchThemeListData('GET', {
    pending: state => ({ ...state, list: [], loading: true,total: 0 }),
    accept: (state, action) => ({ ...state, list: action.payload.pageData,total: action.payload.totalItems, loading: false }),
    reject: state  => ({ ...state, list: [], loading: false,total: 0 }),
  }),
  addThemeData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  updateThemeData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  deleteThemeData('POST', {
    accept: (state) => ({ ...state, loading: false }),
  }),
  getDetailData('GET', {
    pending: (state) => ({ ...state, ThemeDetail: {}, detailLoading: true, }),
    accept: (state, action) => ({ ...state, detailLoading: false, ThemeDetail: action.payload }),
    reject: state => ({ ...state, ThemeDetail: {}, detailLoading: false, })
  }),
  getParentThemeData('GET', {
    pending: (state) => ({ ...state, parentTheme: [], ThemeLoading: true }),
    accept: (state, action) => ({ ...state, parentTheme: action.payload, ThemeLoading: false }),
    reject: (state) => ({ ...state, parentTheme: [], ThemeLoading: false }),
  }),
  fetchAllLabelListData('GET', {
    pending: state => ({ ...state, labelList: [], labelLoading: true }),
    accept: (state, action) => {
      return {
        ...state, labelList: action.payload, labelLoading: false
      };
    },
    reject: state => ({ ...state, labelList: [], labelLoading: false }),
  }),
  {
    loading: false,
    list: [],
    parentTheme: [],
    ThemeLoading: false,
    detailLoading: false,
    ThemeDetail: {},
    total: 0,
  },
);

export default ThemeRedux;

/* ************************************************** 同步请求数据 ************************************************ */

/* ************************************************** 异步请求数据 *********************************************** */
type fetchThemeListParams = {
};

export const fetchThemeList: (obj: fetchThemeListParams) => disPromise<*> = createAsyncAction(
  `${courseQuesUrl}/GetCourseNavList`,
  fetchThemeListData('GET'),
);

type addThemeParams = {
};

export const addTheme: (obj: addThemeParams) => disPromise<*> = createAsyncAction(
  `${courseQuesUrl}/CourseNavInsert`,
  addThemeData('POST'),
);

type updateThemeParams = {
};

export const updateTheme: (obj: updateThemeParams) => disPromise<*> = createAsyncAction(
  `${courseQuesUrl}/CourseNavUpdate`,
  updateThemeData('POST'),
);

type deleteThemeParams = {
};
export const deleteTheme: (obj: deleteThemeParams) => disPromise<*> = createAsyncAction(
  `${courseQuesUrl}/CourseNavDelete`,
  deleteThemeData('POST'),
);

type getDetailParam = {
};
export const getDetailTheme: (obj: getDetailParam) => disPromise<*> = createAsyncAction(
  `${courseQuesUrl}/CourseNavDetail`,
  getDetailData('GET'),
);

type getParentParams = {
};
export const getParentTheme: (obj: getParentParams) => disPromise<*> = createAsyncAction(
  `${courseQuesUrl}/GetParentNav`,
  getParentThemeData('GET'),
);

export const fetchAllLabel: (obj: fetchAllLabelListParams) => disPromise<*> = createAsyncAction(
  `${eLabelUrl}/GetAllList`,
  fetchAllLabelListData('GET'),
);
