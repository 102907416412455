/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Form, Input, Select, Checkbox, Upload, Icon, message, Radio, Col,Row } from 'antd';
import * as Validator from 'utils/validator';
import './richTextExtends.less';
import './form.less';
const { Option } = Select;
import { fileUploadUrl } from 'modules/url';

const { TextArea } = Input;
const FormItem = Form.Item;
var Editor = require('wangeditor');
window.Editor = Editor;
type Props = {
  form: Form,
  getDetailClass: typeof getDetailClass,
  parentClass: Array<Object>,
  updateStatus: boolean,
  curretnItem: Object,
  imgUrl: String,
  labelList: Array<Object>,
}
type State = {
  fileList: Array<Object>,
}
class ClassForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { updateStatus, imgUrl ,imgUrl_m} = this.props;
    const fileList = [];
    const mfileList = [];
    if (updateStatus && imgUrl) {
      fileList.push({
        uid: '-1',
        name: '缩略图',
        status: 'done',
        url: imgUrl,
      });
    }
    if (updateStatus&& imgUrl_m) {
      mfileList.push({
        uid: '-2',
        name: '列表展示图',
        status: 'done',
        url: imgUrl_m,
      });
    }
    this.state = {
      fileList:fileList,
      mfileList:mfileList,
    };
  }

  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('图片大小不能超过20MB!');
    }
    return isLt2M;
  };
  initRichTextValue = () => {
    const { contents = '' } = this.props.curretnItem;
    if (this.editor) {
      document.getElementsByClassName('w-e-text')[0].innerHTML = contents;
    }
    setTimeout(() => {
    this.props.form.setFieldsValue({
      contents,
    });
    },0)
  }
  renderRichText = () => {
    // 配置全屏 和 预览功能
    // Editor.fullscreen = {
    //   init: function (clasName) {
    //     // 添加全屏
    //     document.getElementsByClassName('w-e-toolbar')[0].insertAdjacentHTML('beforeend',
    //       `<div class="w-e-menu"><a class="_wangEditor_btn_fullscreen" href="#" onclick="window.Editor.fullscreen.toggleFullscreen('${clasName}')">全屏</a></div>`);
    //     // // 添加预览功能  
    //     // document.getElementsByClassName('w-e-text-container')[0].insertAdjacentHTML('beforeend',
    //     //   `<div  id="_wangEditor_prev_view_conteiner"></div>`);
    //   },
    //   toggleFullscreen: function (clasName) {
    //     var el = document.getElementsByClassName(clasName)[0];
    //     el.classList.toggle('fullscreen-editor');

    //     var fullTool = document.getElementsByClassName('_wangEditor_btn_fullscreen')[0];
    //     if (fullTool.textContent === '全屏') {

    //       fullTool.textContent = '退出全屏';
    //     } else {
    //       fullTool.textContent = '全屏';
    //     }
    //   }
    // };

    this.editor = new Editor(ReactDOM.findDOMNode(this._div));
    // 富文本编辑器 图片配置
    const customConfig = {
      uploadImgServer: fileUploadUrl + '?type=Course',
      uploadFileName: 'file',
      onchange: (html) => {
        this.props.form.setFieldsValue({
          'contents': html
        });
      },
      uploadImgHeaders: {
        "Authorization": sessionStorage.getItem('Authorization')
      },
      customAlert: (info) => {
        message.warning(info);
      },
      uploadImgHooks: {
        customInsert: function (insertImg, result, editor) {
          var { data } = result;
          insertImg(data);
        }
      }
    };
    this.editor.customConfig = customConfig;
    this.editor.create();

    // Editor.fullscreen.init('rich-text-container');
  }
  upload = () => {

    const { fileList } = this.state;
    const uploadButton = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
    <div style={{color: 'red',position: 'absolute',right: '-200px',top: '-5px',pointerEvents:'none'}}>图片大小:900px*605px</div>
  </div>
    );
    const headers = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=Course'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={fileList}
        headers={headers}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState({ fileList });
        }}
      >
        {this.state.fileList.length >= 1 ? null : uploadButton}
      </Upload>
    );

  };
  mupload = () => {
    const { mfileList } = this.state;
    const uploadButton1 = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '-15px',pointerEvents:'none'}}>一级列表图片大小:227px*140px</div>
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '15px',pointerEvents:'none'}}>二级列表图片大小:227px*140px</div>
  </div>
    );
    const headers1 = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=CourseType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={mfileList}
        headers={headers1}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState((state1)=>({mfileList:fileList}));
        }}
      >
        {this.state.mfileList.length >= 1 ? null : uploadButton1}
      </Upload>
    );

  };
  normFile = e => {
    if (e && e.fileList) {
      if (!e.fileList[0]) {
        return [];
      }
      const { status } = e.fileList[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList;
    }
    return [];
  };

  getParentClassData = () => {
    const { parentClass } = this.props;
    const { id } = this.props.curretnItem;

    var data = [];
    parentClass.forEach((item) => {
      if (item.id != id) {
        data.push(item);
      }
    });
    return data;
  }
  componentDidMount = () => {
    this.renderRichText();
    this.initRichTextValue();
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.curretnItem !== prevProps.curretnItem && this.props.updateStatus) {
      this.initRichTextValue();
    }
  }
  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 19 },
      },
    };
    const contentLayout = {
      labelCol: {
        xs: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 20 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;
    // console.log(this.props)
    const radioGroup = (
      <Radio.Group  >
        <Radio value={1}>是</Radio>
        <Radio value={0}>否</Radio>
      </Radio.Group>
    );
    const {
       parentid,
       showtitle,
      shorttitle,
      contents,
      seriesimg,
      detailimg,
      detailimg_m,
      isvip,
      price,
      ischarge,
      isrecommend,
      briefdesc,
      title, 
      keywords,
      description, 
      plids, isshow, sort,
      isseri,
      teacher,
      videolevel,
      crowd,
      purpose,
      typeid,
      themeid,
      seconds,
      fullname,
      videourl,
      mp4url,
      tokenid,
      aliid,
      istype
    } = this.props.curretnItem;
    // console.log(this.props.curretnItem.contents)
    const { labelList} = this.props;
    return (
      <Form>
        <Row>
        <Col span={6}>
                <FormItem
          label="所属章节"
          {...formItemLayout}
        >
          {getFieldDecorator('parentid', {
            initialValue: parentid ? parseInt(parentid, 0) : 0,
          })(
            <Select placeholder="请选择教程分类" >
              {this.props.list.map(item => <Option key={item.id} value={item.id}>{item.title}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem
              label="视频标题"
              {...formItemLayout}
            >
              {getFieldDecorator('title', {
                initialValue: title || '',
                rules: [
                  {
                    required: true, message: '标题不能为空',
                  }],
              })(
                <Input placeholder="请输入标题" />,
              )}
            </FormItem>
            <FormItem
              label="标签"
              {...formItemLayout}
            >
              {getFieldDecorator('plids', {
                initialValue: plids || [],
                rules: [
                  {
                    required: true, message: '标签不能为空',
                  }],
              })(
                <Select
                  mode="multiple"
                  placeholder="请选择文章标签"
                  optionFilterProp="children"
                  showSearch
                >
                  {labelList ? labelList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>) : ''}
                </Select>,
              )}
            </FormItem>
            <FormItem
              label="简标题"
              {...formItemLayout}
            >
              {getFieldDecorator('shorttitle', {
                initialValue: shorttitle || '',
              })(
                <Input placeholder="请输入简标题" />,
              )}
            </FormItem>
            {/* <FormItem
              label="父级ID"
              {...formItemLayout}
            >
              {getFieldDecorator('typeid', {
                initialValue: typeid || 0,
              })(
                <Input placeholder="请输入分类ID" />,
              )}
            </FormItem> */}
            {/* <FormItem
              label="专题ID"
              {...formItemLayout}
            >
              {getFieldDecorator('themeid', {
                initialValue: themeid || 0,
              })(
                <Input placeholder="请输入专题ID" />,
              )}
            </FormItem> */}
            <FormItem
              label="网页标签"
              {...formItemLayout}
            >
              {getFieldDecorator('showtitle', {
                initialValue: showtitle || '',
              })(
                <Input placeholder="如果为空 系统生成（规则：取【标题】名称）" />,
              )}
            </FormItem>
            <FormItem
              label="网页关键字"
              {...formItemLayout}
            >
              {getFieldDecorator('keywords', {
                initialValue: keywords || '',
              })(
                <Input placeholder="请输入关键字 (编辑自行设置)" />,
              )}
            </FormItem>
            <FormItem
              label="网页描述"
              {...formItemLayout}
            >
              {getFieldDecorator('description', {
                initialValue: description || '',
              })(
                <TextArea placeholder="如果为空 系统按照规则自动生成（规则：幕思城为您推荐【课时标题】视频教程、图文教程、相关文章,关联工具、问答以及评价等内容。【课时标题】教程讲师:【讲师名字】,所属分类：【标签seo】，视频时长：xx分xx秒）" />,
              )}
            </FormItem>
            <FormItem
              label="分"
              {...formItemLayout}
            >
              {getFieldDecorator('minitues', {
                initialValue: Math.floor(seconds/60) || 0,
              })(
                <Input placeholder="请输入分" />,
              )}
            </FormItem>
            <FormItem
              label="秒"
              {...formItemLayout}
            >
              {getFieldDecorator('second', {
                initialValue: seconds%60 || 0,
              })(
                <Input placeholder="请输入秒" />,
              )}
            </FormItem>
            <FormItem
              label="排序"
              {...formItemLayout}
            >
              {getFieldDecorator('sort', {
                initialValue: sort || 0,
                rules: [{
                  required: false, message: '',
                }, {
                  message: '请输入正整数',
                  pattern: /^[0-9]\d*$/,
                }],
              })(
                <Input placeholder="越小越靠前" />,
              )}
            </FormItem>
            </Col>
            <Col span={7} className={istype?"hidecourse":"showcourse"}>
            <FormItem
              label="腾讯视频id"
              {...formItemLayout}
            >
              {getFieldDecorator('tokenid', {
                initialValue: tokenid || '',
              })(
                <Input placeholder="请输入腾讯视频id" />,
              )}
            </FormItem>
            <FormItem
              label="阿里视频id"
              {...formItemLayout}
            >
              {getFieldDecorator('aliid', {
                initialValue: aliid || '',
              })(
                <Input placeholder="请输入阿里视频id" />,
              )}
            </FormItem>
            <FormItem
          label="详情图(PC)"
          {...formItemLayout}
        >
          {getFieldDecorator('detailimg', {
            initialValue: detailimg || '',
            getValueFromEvent: this.normFile
          })(
            this.upload()
          )}
        </FormItem>
        {/* <FormItem
                label="详情图(移动)"
                {...formItemLayout}
              >
                {getFieldDecorator('detailimg_m', {
                  initialValue: detailimg_m || '',
                  getValueFromEvent: this.normFile
                })(
                  this.mupload()
                )}
            </FormItem> */}
        {/* <FormItem
          label="详情图:"
          {...formItemLayout}
        >
          {getFieldDecorator('detailimg', {
            initialValue: detailimg || '',
            getValueFromEvent: this.normFile
          })(
            this.upload()
          )}
        </FormItem> */}
            {/* <FormItem
              label="视频路径"
              {...formItemLayout}
            >
              {getFieldDecorator('fullname', {
                initialValue: fullname || '',
              })(
                <Input placeholder="请输入视频路径" />,
              )}
            </FormItem>
            <FormItem
              label="视频url"
              {...formItemLayout}
            >
              {getFieldDecorator('videourl', {
                initialValue: videourl || '',
              })(
                <Input placeholder="请输入视频url" />,
              )}
            </FormItem> */}
            {/* <FormItem
              label="mp4url"
              {...formItemLayout}
            >
              {getFieldDecorator('mp4url', {
                initialValue: mp4url || '',
              })(
                <Input placeholder="请输入mp4url" />,
              )}
            </FormItem> */}



            <Form.Item label="是否vip"  {...formItemLayout}>
              {getFieldDecorator('isvip', { initialValue: isvip })(radioGroup)}
              <p style={{'color':'#c7c3c3','lineHeight':'20px'}}>(是:表示必须付费客户(盈利服务或其他付费类型客户)并安权限控制是否能观看。否:不控制任何权限,不登录就能看)</p>
            </Form.Item>
            <Form.Item label="是否试听"  {...formItemLayout}>
              {getFieldDecorator('ischarge', { initialValue: ischarge })(radioGroup)}
              <p style={{'color':'#c7c3c3','lineHeight':'20px'}}>(只要登录了，都可以观看) 优先级:是否试听>是否VIP</p>
            </Form.Item>
            <Form.Item label="是否推荐"  {...formItemLayout}>
              {getFieldDecorator('isrecommend', { initialValue: isrecommend })(radioGroup)}
            </Form.Item>
            <Form.Item label="是否显示"  {...formItemLayout}>
              {getFieldDecorator('isshow', { initialValue: isshow })(radioGroup)}
            </Form.Item>

            <FormItem
              label="简介描述"
              {...formItemLayout}
            >
              {getFieldDecorator('briefdesc', {
                initialValue: briefdesc || '',
              })(
                <TextArea placeholder="如果为空 系统按照规则自动生成（规则：自动应用第一个课时的描述到所属课程下所有的课时描述）" rows="5" />,
              )}
            </FormItem>
            </Col>
            <Col span={11}>
            <FormItem
              label="视频内容"
              {...contentLayout}
            >
              {getFieldDecorator('contents', {
                initialValue: contents,
              })(
                <div className="rich-text-container rich-text-size" ref={(ref) => this._div = ref} style={{width:694,minHeight:650}} >
                </div>
              )}
            </FormItem>
            </Col>
            </Row>
      </Form >
    );
  };
}

export default Form.create()(ClassForm);
