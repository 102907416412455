/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import { Form, Input, Select, Switch, Upload, Icon, message, Radio ,Row,Col} from 'antd';
import * as Validator from 'utils/validator';
const { Option } = Select;
import { fileUploadUrl } from 'modules/url';

const { TextArea } = Input;
const FormItem = Form.Item;
type Props = {
  form: Form,
  getDetailTheme: typeof getDetailTheme,
  parentTheme: Array<Object>,
  updateStatus: boolean,
  currentItem: Object,
  imgUrl: String,
  imgUrl1: String,
  labelList: Array<Object>,
}
type State = {
  fileList: Array<Object>,
}
class ThemeForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { updateStatus, imgUrl,imgUrl1,imgUrl1_m,imgUrl_m } = this.props;
    const fileList = [];
    const fileList1 = [];
    const mfileList1 = [];
    const mfileList = [];
    if (updateStatus && imgUrl) {
      fileList.push({
        uid: '-1',
        name: '缩略图',
        status: 'done',
        url: imgUrl,
      });
    }
    if (updateStatus&& imgUrl1) {
      fileList1.push({
        uid: '-2',
        name: '列表展示图',
        status: 'done',
        url: imgUrl1,
      });
    }
    if (updateStatus&& imgUrl1_m) {
      mfileList1.push({
        uid: '-2',
        name: '列表展示图',
        status: 'done',
        url: imgUrl1,
        url: imgUrl1_m,
      });
    }
    if (updateStatus&& imgUrl_m) {
      mfileList.push({
        uid: '-2',
        name: '列表展示图',
        status: 'done',
        url: imgUrl_m,
      });
    }
    this.state = {
      fileList:fileList,
      fileList1:fileList1,
      mfileList1:mfileList1,
      mfileList:mfileList,
    };
  }

  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('图片大小不能超过20MB!');
    }
    return isLt2M;
  };

  upload = () => {

    const { fileList } = this.state;
    const uploadButton = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
        <Icon type="plus" />
        <div style={{color: 'red',position: 'absolute',right: '-226px',top: '-15px',pointerEvents:'none'}}>一级详情图片大小:317px*206px</div>
        <div style={{color: 'red',position: 'absolute',right: '-226px',top: '15px',pointerEvents:'none'}}>二级详情图片大小:317px*206px</div>
    </div>
    );
    const headers = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=ThemeType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        headers={headers}
        fileList={fileList}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState({ fileList });
        }}
      >
        {this.state.fileList.length >= 1 ? null : uploadButton}
      </Upload>
    );
  };
  //列表图
  upload1 = () => {
    const { fileList1 } = this.state;
    const uploadButton1 = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '-15px',pointerEvents:'none'}}>一级列表图片大小:227px*140px</div>
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '15px',pointerEvents:'none'}}>二级列表图片大小:227px*140px</div>
  </div>
    );
    const headers1 = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=CourseType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={fileList1}
        headers={headers1}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState((state1)=>({fileList1:fileList}));
        }}
      >
        {this.state.fileList1.length >= 1 ? null : uploadButton1}
      </Upload>
    );

  };
  mupload1 = () => {
    const { mfileList1 } = this.state;
    const uploadButton = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
      <div style={{color: 'red',position: 'absolute',right: '-226px',top: '-15px',pointerEvents:'none'}}>一级列表图片大小:100px*116px</div>
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '15px',pointerEvents:'none'}}>二级列表图片大小:100px*116px</div>
  </div>
    );
    const headers1 = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=CourseType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={mfileList1}
        headers={headers1}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState((state1)=>({mfileList1:fileList}));
        }}
      >
        {this.state.mfileList1.length >= 1 ? null : uploadButton}
      </Upload>
    );

  };
  mupload = () => {
    const { mfileList } = this.state;
    const uploadButton1 = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
      <div style={{color: 'red',position: 'absolute',right: '-226px',top: '-15px',pointerEvents:'none'}}>一级详情图片大小:336px*232px</div>
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '15px',pointerEvents:'none'}}>二级详情图片大小:336px*232px</div>
  </div>
    );
    const headers1 = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=CourseType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={mfileList}
        headers={headers1}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState((state1)=>({mfileList:fileList}));
        }}
      >
        {this.state.mfileList.length >= 1 ? null : uploadButton1}
      </Upload>
    );

  };
  normFile = e => {
    if (e && e.fileList) {
      if (!e.fileList[0]) {
        return [];
      }
      const { status } = e.fileList[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList;
    }
    return [];
  };

  getParentData = () => {
    const { parentTheme } = this.props;
    const { id } = this.props.currentItem;

    var data = [];
    parentTheme.forEach((item) => {
      if (item.id != id) {
        data.push(item);
      }
    });
    return data;
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 19 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;
    const {
      plids,name, parentid, isshow, sort = 0, img, remark, showtitle, keywords, description,spell,detailimg,img_m,detailimg_m
    } = this.props.currentItem;
    const { updateStatus, labelList,
      classTree, themeTree } = this.props;
      // console.log(this.props);
    return (
      <Form>
        <Row>
        <Col span={12}>
        <FormItem
          label="专题名称"
          {...formItemLayout}
        >
          {getFieldDecorator('name', {
            initialValue: name || '',
            rules: [
              {
                required: true, message: '请输入名称',
              }],
          })(
            <Input placeholder="请输入名称" />,
          )}
        </FormItem>
        <FormItem
          label="一级专题"
          {...formItemLayout}
        >
          {getFieldDecorator('parentid', {
            initialValue: parentid,
          })(
            <Select placeholder="请选择一级专题" >
              {this.getParentData().map(item => <Option value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem
              label="标签"
              {...formItemLayout}
            >
              {getFieldDecorator('plids', {
                initialValue: plids || [],
              })(
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  showSearch
                  placeholder="请选择标签"
                >
                  {labelList ? labelList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>) : ''}
                  {/* {children} */}
                </Select>,
              )}
            </FormItem>
            <FormItem
                label="拼音"
                {...formItemLayout}
              >
                {getFieldDecorator('spell', {
                  initialValue: spell || '',
                  rules: [{
                    required: true, message: '请输入拼音',
                  }, {
                    message: '请输入正确格式',
                    pattern: /^(?!\d+$)[\da-zA-Z]*$/,
                  }],
                })(
                  <Input placeholder="请输入拼音" />,
                )}
              </FormItem>
        <FormItem
          label="网页标签"
          {...formItemLayout}
        >
          {getFieldDecorator('showtitle', {
            initialValue: showtitle || '',
          })(
            <Input placeholder="请输入网页标签 (编辑自行设置)" />,
          )}
        </FormItem>
        <FormItem
          label="网页关键字"
          {...formItemLayout}
        >
          {getFieldDecorator('keywords', {
            initialValue: keywords || '',
          })(
            <Input placeholder="请输入网页关键字 (编辑自行设置)" />,
          )}
        </FormItem>
        <FormItem
          label="网页描述"
          {...formItemLayout}
        >
          {getFieldDecorator('description', {
            initialValue: description || '',
            rules: [
              {
                required: false, message: '网页描述不能为空',
              }],
          })(
            <TextArea placeholder="请输入网页描述 (编辑自行设置)" />,
          )}
        </FormItem>
        </Col>
        <Col span={12}>
        <FormItem
          label="是否显示"
          {...formItemLayout}
        >
          {getFieldDecorator('isshow', {
            initialValue: isshow,
          })(
            <Radio.Group  >
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem
          label="顺序"
          {...formItemLayout}
        >
          {getFieldDecorator('sort', {
            initialValue: sort || 0,
            rules: [{
              required: false, message: '',
            }, {
              message: '请输入正整数',
              pattern: /^[0-9]\d*$/,
            }],
          })(
            <Input placeholder="越小越靠前" />,
          )}
        </FormItem>
        <FormItem
          label="列表展示图(PC)"
          {...formItemLayout}
        >
          {getFieldDecorator('img', {
            initialValue: img || '',
            getValueFromEvent: this.normFile
          })(
            this.upload1()
          )}
        </FormItem>
        <FormItem
          label="详情图片(PC)"
          {...formItemLayout}
        >
          {getFieldDecorator('detailimg', {
            initialValue: detailimg || '',
            getValueFromEvent: this.normFile
          })(
            this.upload()
          )}
        </FormItem>
        {/* <FormItem
          label="列表展示图(移动)"
          {...formItemLayout}
        >
          {getFieldDecorator('img_m', {
            initialValue: img_m || '',
            getValueFromEvent: this.normFile
          })(
            this.mupload1()
          )}
        </FormItem>
        <FormItem
          label="详情图片(移动)"
          {...formItemLayout}
        >
          {getFieldDecorator('detailimg_m', {
            initialValue: detailimg_m || '',
            getValueFromEvent: this.normFile
          })(
            this.mupload()
          )}
        </FormItem> */}
        <FormItem
          label="备注"
          {...formItemLayout}
        >
          {getFieldDecorator('remark', {
            initialValue: remark || ''
          })(
            <TextArea placeholder="请输入备注" />,
          )}
        </FormItem>
        </Col>
        </Row>
      </Form >
    );
  };
}

export default Form.create()(ThemeForm);
