/**
 * @flow
 */
import { combineReducers } from 'redux';
import loginRedux from './loginRedux';
import systemReducer from './systemRedux';
import classRedux from './ecomClassRedux';
import articleRedux from './eArticleRedux';
import labelRedux from './eLabelRedux';
import themeRedux from './eThemeRedux';
import advRedux from './advRedux';
import ansAdvRedux from './ansAdvRedux';
import eCommentRedux from './eCommentRedux';

import bkClassRedux from './bkClassRedux';
import bkThemeRedux from './bkThemeRedux';
import bkWikiRedux from './bkArticleRedux';

import aClassRedux from './aClassRedux';
import aThemeRedux from './aThemeRedux';
import aQuesRedux from './aQuesRedux';
import aCommentRedux from './aCommentRedux';

import cArticleRedux from './cArticleRedux';
import cClassRedux from './cClassRedux';
import cManageRedux from './cManageRedux';
import cNavRedux from './cNavRedux';


const rootReducer = combineReducers({
  loginRedux,
  systemReducer,
  classRedux,
  articleRedux,
  labelRedux,
  themeRedux,
  advRedux,
  eCommentRedux,
  bkClassRedux,
  bkThemeRedux,
  bkWikiRedux,
  aClassRedux,
  aThemeRedux,
  aQuesRedux,
  aCommentRedux,
  cArticleRedux,
  cClassRedux,
  cManageRedux,
  cNavRedux,
  ansAdvRedux
});

export default rootReducer;
