/**
 * @flow
 */

import React, { Component } from 'react';
import { Button, message, Spin } from 'antd';

import './index.less';


class Iframe extends Component<Props, State> {
  form: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
        userID:'',
        userName:'',
    };
  }

  componentDidMount = () => {
    var parentHeight = document.getElementsByClassName('container-wraper')[0].offsetHeight;
    var myiframe = document.getElementById('iframe');
    myiframe.onload = function(){
      myiframe.height = parentHeight
    }
    this.setState({
        userID:sessionStorage.getItem('userID'),
        userName:sessionStorage.getItem('userName'),
      })
      console.log(this.props);
  }
 
  render() {
    return (
      <div className="iframe-wraper">
        {/* <div className="page-header">
          <Button className="btn-default">返回上级</Button>
        </div> */}
            <iframe src={'http://erp.musicheng.com.cn/admin/sysconfig/index'} frameborder="0" id='iframe' className='iframe' height='768'>
            </iframe>
      </div>
    );
  }
}

export default Iframe