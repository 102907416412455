/**
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Button, Table, Modal,
  Icon, Popconfirm, message, Row, Col,
  Select, Divider, Spin
} from 'antd';
import { fetchThemeList, addTheme, updateTheme, deleteTheme, getParentTheme, getDetailTheme,fetchAllLabel } from 'modules/eThemeRedux';

import ThemeForm from './form';
const { Search } = Input;
const { Option } = Select;

import './index.less';
import defaultPicUrl from "../../../assets/images/default-pic.png";

type Props = {
  list: Array<Object>,
  loading: boolean,
  parentTheme: Array<Object>,
  ThemeLoading: Boolean,
  fetchThemeList: typeof fetchThemeList,
  addTheme: typeof addTheme,
  updateTheme: typeof updateTheme,
  deleteTheme: typeof deleteTheme,
  getParentTheme: typeof getParentTheme,
  detailLoading: boolean,
  ThemeDetail: Object,
  fetchAllLabel: typeof fetchAllLabel,
};
type State = {
  modalVisible: boolean,
  updateStatus: boolean,
  query: string,
  pageIndex: number,
  pageSize: number,
  deleteIds: Array<number>,
  currentItem: Object,
  choseParentThemeId: Number,
};
class EcomTheme extends Component<Props, State> {
  form: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVisible: false,
      updateStatus: false,
      query: '',
      pageIndex: 1,
      pageSize: 10,
      deleteIds: [],
      currentItem: {},
      choseParentThemeId: '',
    };
  }

  componentDidMount = () => {
    this.getList();
    this.getAllParentTheme();
    this.props.fetchAllLabel();
  }

  getList = () => {
    const { pageIndex, pageSize, query, choseParentThemeId } = this.state;
    const params = {
      pageIndex, pageSize, name: query,

    };
    if (choseParentThemeId) params.parentId = choseParentThemeId;
    else if(choseParentThemeId === 0) params.parentId = choseParentThemeId;
    this.props.fetchThemeList({
      ...params
    });
  }

  showModal = (obj, cb) => {
    this.setState({ ...obj }, cb);
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
    this.form.resetFields();
  }


  handleCancel = () => {
    this.form.resetFields();
    this.setState({
      modalVisible: false,
    });
  }

  submit = () => {
    this.form.validateFields((err, values) => {
      if (err) return;
      const { updateStatus } = this.state;
      const { currentItem } = this.state;
      const imgUrl = values.img && values.img[0] ?
        (values.img[0].response ? values.img[0].response.data : values.img) : '';
      const imgUrl1 = values.detailimg && values.detailimg[0] ?
      (values.detailimg[0].response ? values.detailimg[0].response.data : values.detailimg) : '';
      const parentid = values.parentid ? +values.parentid : 0;
      const imgUrl1_m = values.img_m && values.img_m[0] ?
      (values.img_m[0].response ? values.img_m[0].response.data : values.img_m) : '';
      const imgUrl_m = values.detailimg_m && values.detailimg_m[0] ?
      (values.detailimg_m[0].response ? values.detailimg_m[0].response.data : values.detailimg_m) : '';
      if(!values.spell.indexOf('i')||!values.spell.indexOf('I')||!values.spell.indexOf('v')||!values.spell.indexOf('V')){
        alert('不能首字母为i,I,v,V开头')
      }else{
        if (updateStatus) {
          this.props.updateTheme({ ...currentItem, ...values, img: imgUrl, parentid,detailimg:imgUrl1,img_m:imgUrl1_m,detailimg_m:imgUrl_m })
            .then(() => {
              message.success('编辑成功');
              this.closeModal();
              this.getList();
              this.getAllParentTheme();
            });
        } else {
          const params = {
            ...values,
            img: imgUrl,
            parentid,
            detailimg:imgUrl1,
            img_m:imgUrl1_m,
            detailimg_m:imgUrl_m
          };
          this.props.addTheme(params)
            .then(() => {
              message.success('新增成功');
              this.closeModal();
              this.getList();
              this.getAllParentTheme();
            });
        }
      }
    });
  }

  handleChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const obj = {
      pageIndex: current,
      pageSize,
    };
    this.setState({ ...obj }, () => this.getList());
  };

  deleteTheme = (item) => {
    this.props.deleteTheme({
      key: [item.id]
    }
    ).then(res => {
      if (res) {
        message.success("删除成功");
        this.getList();
      }
    });
  }

  batchDelete = () => {
    const { deleteIds } = this.state;
    if (deleteIds.length === 0) {
      message.warning("请选择需要删除的分类");
      return;
    }

    this.props.deleteTheme({
      key: deleteIds
    }).then(res => {
      if (res) {
        message.success('删除成功');
        this.getList();
      }
    });
  }

  getDetail = () => {
    const { id } = this.state.currentItem;
    this.props.getDetailTheme({
      id,
    });
  }

  getAllParentTheme = () => {
    this.props.getParentTheme();
  }

  onSelectChange = (value) => {
    this.setState({
      pageIndex: 1,
      choseParentThemeId: value,
    }, this.getList);
  }

  renderTable = () => {
    const columns = [
      {
        title: '专题名称',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
      }, {
        title: '一级专题',
        dataIndex: 'parentname',
        key: 'parentname',
        width: '10%',
      }, {
        title: '网页标签',
        dataIndex: 'showtitle',
        key: 'showtitle',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '网页关键字',
        dataIndex: 'keywords',
        key: 'keywords',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '网页描述',
        dataIndex: 'description',
        key: 'description',
        width: '20%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '排序',
        dataIndex: 'sort',
        key: 'sort',
        width: "6%",
      }, {
        title: '是否显示',
        dataIndex: 'isshow',
        key: 'isshow',
        width: "6%",
        render: (text) => (text ? '是' : '否'),
      }, {
        title: '缩略图',
        dataIndex: 'img',
        key: 'img',
        width: "7%",
        render: text => {
          if (text) {
            return <img src={text} alt="图片" height="40" width="40" />;
          }
          return <img src={defaultPicUrl} alt="图片" height="40" width="40" />;
        }
      }, {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        width: "6%",
        render: (text, record) => (
          <div className="operate-col">
            <Icon
              type="edit"
              title="编辑"
              style={{ fontSize: 16, color: '#83B6FF', marginRight: 10 }}
              onClick={() => {
                this.showModal({ modalVisible: true, updateStatus: true, currentItem: record }, this.getDetail);
              }}
            />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={() => this.deleteTheme(record)}
              okText="确定"
              cancelText="取消"
            >
              <Icon type="delete" title="删除" style={{ fontSize: 16, color: '#FF4A4A' }} />
            </Popconfirm>
          </div>
        ),
      }];
    const { list, loading, total } = this.props;
    const { pageIndex, pageSize, deleteIds } = this.state;
    const pagination = {
      total,
      pageSize,
      current: pageIndex,
      showTotal: num => `共${num}项`,
      pageSizeOptions: ['10', '20', '30', '40','200','400','600','800','1000','2000'],
      showSizeChanger: true,
      showQuickJumper: true,
    };

    const rowSelection = {
      columnWidth: '5%',
      selectedRowKeys: deleteIds,
      onChange: (selectedRowKeys: string[], selectedRows: Object[]) => {
        this.setState({ deleteIds: selectedRowKeys });
      },
    };

    return <Table
      rowKey={record => record.id}
      dataSource={list}
      columns={columns} loading={loading}
      pagination={pagination}
      rowSelection={rowSelection}
      onChange={this.handleChange}
    />;
  }

  render() {
    const { modalVisible, updateStatus, currentItem } = this.state;
    const { parentTheme, ThemeLoading, loading, detailLoading, ThemeDetail ,labelList} = this.props;
    return (
      <div className="container-wraper">
        <Row className="page-header">
          <Col span={10}>
            <Button type="primary" onClick={() => this.showModal({ modalVisible: true, updateStatus: false })}
            >新增</Button>
            <Divider type="vertical" />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={this.batchDelete}
              okText="确定"
              cancelText="取消"
            >
              <Button className="danger-btn" >批量删除</Button>
            </Popconfirm>
          </Col>
          <Col span={14} className="search-wraper">
            一级专题：&nbsp;
                        <Select
              showSearch
              style={{ width: 200, marginRight: '16px' }}
              optionFilterProp="children"
              onChange={this.onSelectChange}
              placeholder="请选择"
              loading={ThemeLoading}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {parentTheme.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
            <Search
              placeholder="输入专题名称查询"
              onSearch={value => this.setState({ query: value }, this.getList)}
              enterButton
            />
          </Col>
        </Row>
        <div className="table-wraper">
          {this.renderTable()}
        </div>
        <Modal
          title={updateStatus ? '编辑' : '新增'}
          visible={modalVisible}
          onOk={this.submit}
          onCancel={this.handleCancel}
          confirmLoading={loading}
          destroyOnClose={true}
          className="course-wraper"
        >
          <Spin spinning={detailLoading}>
            <ThemeForm
              currentItem={updateStatus ? ThemeDetail : {}}
              imgUrl={currentItem.img}
              imgUrl1={currentItem.detailimg}
              imgUrl1_m={currentItem.img_m}
              imgUrl_m={currentItem.detailimg_m}
              updateStatus={updateStatus}
              parentTheme={parentTheme}
              labelList={labelList}
              ref={(form) => { return form ? this.form = form : null; }}
            />
          </Spin>

        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { themeRedux } = state;
    return ({ ...themeRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      fetchThemeList, addTheme, updateTheme, deleteTheme, getParentTheme, getDetailTheme,fetchAllLabel
    }, dispatch,
  ),
)(EcomTheme);