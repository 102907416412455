/**
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Button, Table, Modal,
  Icon, Popconfirm, message, Row, Col,
  Select, Divider, Spin
} from 'antd';
import { fetchClassTree,fetchClassList, addClass, updateClass, deleteClass, getParentClass, getDetailClass,fetchAllLabel } from 'modules/bkClassRedux';
import defaultPicUrl from '../../../assets/images/default-pic.png';


import ClassForm from './form';
const { Search } = Input;
const { Option } = Select;

import './index.less';

type Props = {
  list: Array<Object>,
  loading: boolean,
  parentClass: Array<Object>,
  classLoading: Boolean,
  fetchClassList: typeof fetchClassList,
  addClass: typeof addClass,
  updateClass: typeof updateClass,
  deleteClass: typeof deleteClass,
  getParentClass: typeof getParentClass,
  detailLoading: boolean,
  classDetail: Object,
  fetchClassTree: typeof fetchClassTree,
  fetchAllLabel: typeof fetchAllLabel,
  isHidden: boolean,
};
type State = {
  modalVisible: boolean,
  updateStatus: boolean,
  query: string,
  pageIndex: number,
  pageSize: number,
  deleteIds: Array<number>,
  currentItem: Object,
  choseParentClassId: Number,
  isHidden: boolean
};
class Bclass extends Component<Props, State> {
  form: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVisible: false,
      updateStatus: false,
      query: '',
      pageIndex: 1,
      pageSize: 10,
      deleteIds: [],
      currentItem: {},
      choseParentClassId: '',
      routerName:2
    };
  }

  componentDidMount = () => {
    this.getList();
    this.getAllParentClass();
    this.initData();
  }
  initData = () => {
    this.props.fetchClassTree();
    this.props.fetchAllLabel();
  }
  getList = () => {
    const { pageIndex, pageSize, query, choseParentClassId } = this.state;
    const params = {
      pageIndex, pageSize, name: query,
    };
    if (choseParentClassId) params.parentId = choseParentClassId;
    else if(choseParentClassId === 0) params.parentId = choseParentClassId;
    this.props.fetchClassList({
      ...params
    });
  }

  showModal = (obj, cb) => {
    this.setState({ ...obj }, cb);
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
    this.form.resetFields();
  }


  handleCancel = () => {
    this.form.resetFields();
    this.setState({
      modalVisible: false,
    });
  }

  submit = () => {
    this.form.validateFields((err, values) => {
      if (err) return;
      const { updateStatus } = this.state;
      const { currentItem } = this.state;
      const imgUrl = values.img && values.img[0] ?
        (values.img[0].response ? values.img[0].response.data : values.img) : '';
        const imgUrl1_m = values.img_m && values.img_m[0] ?
        (values.img_m[0].response ? values.img_m[0].response.data : values.img_m) : '';
        if(!values.spell.indexOf('i')||!values.spell.indexOf('I')||!values.spell.indexOf('v')||!values.spell.indexOf('V')){
          alert('不能首字母为i,I,v,V开头')
        }else{
          if (updateStatus) {
            this.props.updateClass({ ...currentItem, ...values, img: imgUrl ,img_m:imgUrl1_m })
              .then(() => {
                message.success('编辑成功');
                this.closeModal();
                this.getList();
                this.getAllParentClass();
              });
          } else {
            const params = {
              ...values,
              img: imgUrl,
              img_m:imgUrl1_m,
            };
            params.parentid = +values.parentid;
            this.props.addClass(params)
              .then(() => {
                message.success('新增成功');
                this.closeModal();
                this.getList();
                this.getAllParentClass();
              });
          }
        }
    });
  }
  customExpandIcon=(props)=>{
    if(props.record.children.length > 0){
      if (props.expanded) {
          return <div role="button" tabindex="0" class="ant-table-row-expand-icon ant-table-row-expanded" aria-label="关闭行" onClick={e => {
                 props.onExpand(props.record, e)}}></div>
      } else {
          return <div role="button" tabindex="0" class="ant-table-row-expand-icon ant-table-row-collapsed" aria-label="展开行" aria-label="关闭行" onClick={e => {
            props.onExpand(props.record, e)}}></div>
      }
  }else{
      return <span style={{marginRight:8 }}></span>
  }
  }
  handleChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const obj = {
      pageIndex: current,
      pageSize,
    };
    this.setState({ ...obj }, () => this.getList());
  };

  deleteClass = (item) => {
    // console.log(item.id);
    this.props.deleteClass({
      key: [item.id]
    }
    ).then(res => {
      if (res) {
        message.success("删除成功");
        this.getList();
      }
    });
  }

  batchDelete = () => {
    const { deleteIds } = this.state;
    // console.log(deleteIds.join(','))
    if (deleteIds.length === 0) {
      message.warning("请选择需要删除的分类");
      return;
    }

    this.props.deleteClass({
      key: deleteIds
    }).then(res => {
      if (res) {
        message.success('删除成功');
        this.getList();
      }
    });
  }

  getDetail = () => {
    const { id } = this.state.currentItem;
    // console.log(this.state.currentItem)
    this.props.getDetailClass({
      id,
    });
  }

  getAllParentClass = () => {
    this.props.getParentClass();
  }

  onSelectChange = (value) => {
    // console.log(value)
    this.setState({
      pageIndex: 1,
      choseParentClassId: value,
    }, this.getList);
  }

  renderTable = () => {
    const columns = [
      {
        title: '分类名称',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
      },
      {
        title: '分类级别',
        dataIndex: 'remark',
        key: 'remark',
        width: '6%',
      },  {
        title: '一级分类',
        dataIndex: 'parentname',
        key: 'parentname',
        width: '10%',
      }, {
        title: '网页标签',
        dataIndex: 'showtitle',
        key: 'showtitle',
        width: '10%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '拼音',
        dataIndex: 'spell',
        key: 'spell',
        width: '10%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      },{
        title: '网页关键字',
        dataIndex: 'keywords',
        key: 'keywords',
        width: '10%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '网页描述',
        dataIndex: 'description',
        key: 'description',
        width: '16%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '排序',
        dataIndex: 'sort',
        key: 'sort',
        width: "6%",
      }, {
        title: '是否显示',
        dataIndex: 'isshow',
        key: 'isshow',
        width: "6%",
        render: (text) => (text ? '是' : '否'),
      }, {
        title: '缩略图',
        dataIndex: 'img',
        key: 'img',
        width: "7%",
        render: text => {
          if (text) {
            return <img src={text} alt="图片" height="40" width="40" />;
          }
          return <img src={defaultPicUrl} alt="图片" height="40" width="40" />;
        }
      }, {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        width: "6%",
        render: (text, record) => (
          <div className="operate-col">
            <Icon
              type="edit"
              title="编辑"
              style={{ fontSize: 16, color: '#83B6FF', marginRight: 10 }}
              onClick={() => {
                this.setState({
                  isHidden: record.level?false:true
                });
                this.showModal({ modalVisible: true, updateStatus: true, currentItem: record }, this.getDetail);
              }}
            />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={() => this.deleteClass(record)}
              okText="确定"
              cancelText="取消"
            >
              <Icon type="delete" title="删除" style={{ fontSize: 16, color: '#FF4A4A' }} />
            </Popconfirm>
          </div>
        ),
      }];
    const { list, loading, total } = this.props;
    // console.log(this.props)
    const { pageIndex, pageSize, deleteIds } = this.state;
    const pagination = {
      total,
      pageSize,
      current: pageIndex,
      showTotal: num => `共${num}项`,
      pageSizeOptions: ['10', '20', '30', '40','200','400','600','800','1000','2000'],
      showSizeChanger: true,
      showQuickJumper: true,
    };

    const rowSelection = {
      columnWidth: '5%',
      selectedRowKeys: deleteIds,
      onChange: (selectedRowKeys: string[], selectedRows: Object[]) => {
        this.setState({ deleteIds: selectedRowKeys });
      },
    };

    return <Table
      rowKey={record => record.id}
      dataSource={list}
      columns={columns} loading={loading}
      pagination={pagination}
      childrenColumnName={'children'}
      rowSelection={rowSelection}
      onChange={this.handleChange}
      expandIcon={(props) => this.customExpandIcon(props)}
    />;
  }

  render() {
    const { modalVisible, updateStatus, currentItem,isHidden } = this.state;
    const { parentClass, classLoading, loading, detailLoading, classDetail,labelList } = this.props;
    return (
      <div className="container-wraper">
        <Row className="page-header">
          <Col span={10}>
            <Button type="primary" onClick={() => 
            {
              this.setState({
                isHidden: true
              });
              this.showModal({ modalVisible: true, updateStatus: false })
            }
          }
            >新增</Button>
            <Divider type="vertical" />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={this.batchDelete}
              okText="确定"
              cancelText="取消"
            >
              <Button className="danger-btn" >批量删除</Button>
            </Popconfirm>
          </Col>
          <Col span={14} className="search-wraper">
            一级分类：&nbsp;
                        <Select
              showSearch
              style={{ width: 200, marginRight: '16px' }}
              optionFilterProp="children"
              onChange={this.onSelectChange}
              placeholder="请选择"
              loading={classLoading}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {parentClass.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
            <Search
              placeholder="输入分类名查询"
              onSearch={value => this.setState({ query: value, pageIndex: 1, }, this.getList)}
              enterButton
            />
          </Col>
        </Row>
        <div className="table-wraper">
          {this.renderTable()}
        </div>
        <Modal
          title={updateStatus ? '编辑' : '新增'}
          visible={modalVisible}
          onOk={this.submit}
          onCancel={this.handleCancel}
          confirmLoading={loading}
          destroyOnClose={true}
        >
          <Spin spinning={detailLoading}>
            <ClassForm
              currentItem={updateStatus ? classDetail : {}}
              imgUrl={currentItem.img}
              updateStatus={updateStatus}
              imgUrl1_m={currentItem.img_m}
              parentClass={parentClass}
              labelList={labelList}
              isHidden={isHidden}
              ref={(form) => { return form ? this.form = form : null; }}
            />
          </Spin>

        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { bkClassRedux } = state;
    return ({ ...bkClassRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      fetchClassTree, fetchClassList, addClass, updateClass, deleteClass, getParentClass, getDetailClass,fetchAllLabel
    }, dispatch,
  ),
)(Bclass);