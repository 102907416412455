/**
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Button, Table, Icon,
  Popconfirm, message, Row, Col, Divider,
  Tabs, DatePicker, Select, TreeSelect
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  fetchArticleList, addArticle,
  updateArticle, deleteArticle,
  fetchThemeTree, fetchClassTree,
  fetchAllLabel, phyDeleteArticle,
  revcoverArticle
} from 'modules/aQuesRedux';
import defaultPicUrl from 'images/default-pic.png';
import PreviewImg from 'components/previewImg';

import Article from './article';
import BatchOperation from './batchOperation';
import Iframe from './../iframe';
import Comment from './../comment';

const { TabPane } = Tabs;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const selectData = [
  {
    title: '已修改',
    value: '8',
    key: '8',
  },
  {
    title: '未修改',
    value: '9',
    key: '9',
  },
  {
    title: '显示',
    value: '0',
    key: '0',
  },
  {
    title: '不显示',
    value: '1',
    key: '1',
  },
  {
    title: '热门',
    value: '2',
    key: '2',
  },
  {
    title: '非热门',
    value: '3',
    key: '3',
  },
  {
    title: '置顶',
    value: '4',
    key: '4',
  },
  {
    title: '非置顶',
    value: '5',
    key: '5',
  },
  {
    title: '推荐',
    value: '6',
    key: '6',
  },
  {
    title: '非推荐',
    value: '7',
    key: '7',
  },
  // {
  //   title: '是否修改',
  //   value: '7',
  //   key: '7',
  // },
];
const selectDataUpdate = [
  {
    title: '未修改',
    value: '0',
    key: '0',
  },
  {
    title: '已修改',
    value: '1',
    key: '1',
  }
];
const selectDataOrigin = [
  {
    title: '自建',
    value: '0',
    key: '0',
  },
  {
    title: '问商友',
    value: '1',
    key: '1',
  },
  {
    title: '淘宝大学',
    value: '2',
    key: '2',
  },
  {
    title: '千牛',
    value: '3',
    key: '3',
  }
];

import './index.less';

type Props = {
  list: Array<Object>,
  total: number,
  loading: boolean,
  fetchArticleList: typeof fetchArticleList,
  addArticle: typeof addArticle,
  updateArticle: typeof updateArticle,
  phyDeleteArticle: typeof phyDeleteArticle,
  deleteArticle: typeof deleteArticle,
  fetchAllLabel: typeof fetchAllLabel,
  labelList: Array<Object>,
  labelLoading: boolean,
  fetchClassTree: typeof fetchClassTree,
  classTree: Array<Object>,
  classTreeLoading: boolean,
  fetchThemeTree: typeof fetchThemeTree,
  themeTree: Array<Object>,
  themeTreeLoading: boolean,
  revcoverArticle: typeof revcoverArticle,
};
type State = {
  updateStatus: boolean,
  query: string,
  pageIndex: number,
  pageSize: number,
  deleteIds: Array<number>,
  curretnItem: Object,
  disPageList: Boolean,
  articleStatus: string, // 0 草稿 1 已删除  2 已发布
  selectFilter: string,
  selectFilterUpdate: string,
  rangeTime: Array<string>,
  sorter: Object, // 排序字段
  articletypeid: String, // 文章分类
  themetypeid: String, // 专题分类
  productlabelid: String, // 标签
  prevVisible: Boolean,
  prevImgUrl: String,
};
class AquesArticle extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      updateStatus: false,
      query: '',
      pageIndex: 1,
      pageSize: 10,
      deleteIds: [],
      curretnItem: {},
      conmentItem:{},
      disPageList: true,
      articleStatus: '2', // 默认查询已发布
      selectFilter: '',
      selectFilterUpdate:'',
      selectFilterOrigin:'',
      rangeTime: [],
      sorter: {},
      articletypeid: undefined, // 文章分类
      themetypeid: undefined, // 专题分类
      productlabelid: undefined, // 标签
      prevVisible: false,
      prevImgUrl: '',
      disPageIframe:true,
      disPageComent:true,
      editor:0
    };
  }

  componentDidMount = () => {
    this.getList();
    this.initData();
    this.setState({
      userID:sessionStorage.getItem('userID'),
      userName:sessionStorage.getItem('userName'),
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.disPageList !== this.state.disPageList && this.state.disPageList) {
      this.getList();
    }
  }

  initData = () => {
    this.props.fetchClassTree();
    this.props.fetchAllLabel();
    this.props.fetchThemeTree();
  }

  getList = () => {
    const { pageIndex, pageSize, query,
      articleStatus, selectFilter, rangeTime, sorter,selectFilterUpdate,selectFilterOrigin,
      articletypeid,
      themetypeid,
      productlabelid,
      userID,
      editor
    } = this.state;
    const { order, field } = sorter;
    const params = {
      pageIndex, pageSize,
      state: articleStatus,
      searchkey: query,
      isupdate:-1,
      sourcetp:-1
    };
    if(articleStatus==4){
      params.editor=userID;
      params.state=0;
    }
    if (rangeTime.length === 2 && rangeTime[0] && rangeTime[1]) {
      const startTime = moment(rangeTime[0]).format(dateFormat);
      const entTime = moment(rangeTime[1]).format(dateFormat);
      params.searchtimespan = startTime + "@" + entTime;
    }

    articletypeid ? params.articletypeid = articletypeid : '';
    themetypeid ? params.themetypeid = themetypeid : '';
    productlabelid ? params.productlabelid = productlabelid : '';
    params.orderby = 'id';
    params.sort = order === "ascend" ? 0 : 1;
    if (order && field) {
    }
    if (selectFilter) {
      params.searchenum = selectFilter + '';
    }
    
    if (selectFilterUpdate) {
      params.isupdate = selectFilterUpdate + '';
    }
    if (selectFilterOrigin) {
      params.sourcetp = selectFilterOrigin + '';
    }
    // 发布状态 筛选 
    this.props.fetchArticleList({
      ...params
    });
  }

  handleChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const obj = {
      pageIndex: current,
      pageSize,
      sorter,
    };
    this.setState({ ...obj }, () => this.getList());
  };

  deleteArticle = (item) => {
    const { deleteArticle, phyDeleteArticle } = this.props;
    const { articleStatus } = this.state;
    let methods = '';
    if (articleStatus === '3') { // 回收站删除为物理删除
      methods = phyDeleteArticle;
    } else {
      methods = deleteArticle;
    }

    methods({
      key: [item.id]
    }).then(res => {
      message.success("删除成功");
      this.getList();
    });
  }

  backToPageList = () => {
    this.setState({
      disPageList: true,
      curretnItem: {},
      conmentItem:{}
    });
  }

  onTabChange = (activeKey) => {
    const obj = {
      articleStatus: activeKey,
      pageIndex: 1,
      deleteIds: [],
    };
    activeKey !== '2' ? obj.rangeTime = [] : '';
    this.setState({
      ...obj,
    }, this.getList);
  }

  onTimeChange = (time: moment, timeString: string) => {
    this.setState({
      rangeTime: time,
      pageIndex: 1,
    }, this.getList);
  }

  onSelectFilterChange = value => {
    this.setState({ selectFilter: value, pageIndex: 1, }, this.getList);
  };
  onSelectFilterUpdate = value => {
    this.setState({ selectFilterUpdate: value, pageIndex: 1, }, this.getList);
  };
  onSelectFilterOrigin = value => {
    this.setState({ selectFilterOrigin: value, pageIndex: 1, }, this.getList);
  };
  renderSelectFilter = () => {
    const { selectFilter } = this.state;
    return (
      <Select
        value={selectFilter ? selectFilter : undefined}
        onChange={this.onSelectFilterChange}
        placeholder="请选择"
        allowClear
        style={{width:90}}
      >
        {
          selectData.map(item => <Option key={item.value} value={item.value}>{item.title}</Option>)
        }
      </Select>
    );
  }
  renderSelectUpdate = () => {
    const { selectFilterUpdate } = this.state;
    return (
      <Select
        value={selectFilterUpdate ? selectFilterUpdate : undefined}
        onChange={this.onSelectFilterUpdate}
        placeholder="请选择"
        allowClear
        style={{width:90}}
      >
        {
          selectDataUpdate.map(item => <Option key={item.value} value={item.value}>{item.title}</Option>)
        }
      </Select>
    );
  }
  renderSelectOrign = () => {
    const { selectFilterOrigin } = this.state;
    return (
      <Select
        value={selectFilterOrigin ? selectFilterOrigin : undefined}
        onChange={this.onSelectFilterOrigin}
        placeholder="请选择"
        allowClear
        style={{width:90}}
      >
        {
          selectDataOrigin.map(item => <Option key={item.value} value={item.value}>{item.title}</Option>)
        }
      </Select>
    );
  }

  onThemeChange = (value) => {
    this.setState({
      pageIndex: 1,
      themetypeid: value,
    });
  }

  onClassTreeSelect = (value) => {
    this.setState({
      pageIndex: 1,
      articletypeid: value,
    });
  }

  onLabelChange = (value) => {
    this.setState({
      pageIndex: 1,
      productlabelid: value,
    });
  }

  getColumnSearchProps = dataIndex => {
    const { themetypeid, articletypeid, productlabelid } = this.state;
    const { classTree, labelList, themeTree } = this.props;
    const commonProps = {
      dropdownStyle: {
        maxHeight: 400, overflow: 'auto'
      },
      style: {
        display: 'block', marginBottom: '10px'
      },
      treeDefaultExpandAll: true,
    };

    const classTreeSelect = <TreeSelect
      treeData={classTree}
      onChange={this.onClassTreeSelect}
      value={articletypeid}
      placeholder="请选择所属分类"
      {...commonProps}
    />;

    const themeTreeSelect = <TreeSelect
      treeData={themeTree}
      onChange={this.onThemeChange}
      value={themetypeid}
      placeholder="请选择所属专题"
      {...commonProps}
    />;
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          {
            dataIndex === 'lblnames' ? (
              <Select
                placeholder="请选择标签"
                style={{ display: 'block', marginBottom: '10px' }}
                onChange={this.onLabelChange}
                value={productlabelid}
              >
                {labelList ? labelList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>) : ''}
              </Select>
            ) : (
                dataIndex === 'atnames' ? classTreeSelect : themeTreeSelect
              )
          }
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" icon="delete" style={{ width: 90 }}>
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    };
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.getList();
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({
      articletypeid: undefined, // 文章分类
      themetypeid: undefined, // 专题分类
      productlabelid: undefined, // 标签
    }, this.getList);
  };

  recover = (record) => {
    this.props.revcoverArticle({
      key: [record.id]
    }).then(() => {
      message.success("文章已恢复至草稿箱！");
      this.getList();
    });
  }

  renderTable = () => {
    const pushTimeCol = {
      title: '发布时间',
      dataIndex: 'publishtime_t',
      key: 'publishtime_t',
      width:100
    };
    const modifiTimeCol = {
      title: '更新时间',
      dataIndex: 'modifytime_t',
      key: 'modifytime_t',
      width:100
    };
    const sourceCol = {
        title: '来源',
        dataIndex: 'sourcetpname',
        key: 'sourcetpname',
        width: 100,
        render:text =>(<div title={text}>{text}</div>)
    }
    const baiDuCol = 
    {
      title: '百度推广',
      dataIndex: 'ispushid',
      key: 'ispushid',
      width: 100,
      fixed: 'left',
      render:text =>(<div title={text}>{text?'是':'否'}</div>)
    }
    const columns = [
      {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        fixed: 'left',
        render: text => (<div title={text}>{text}</div>)
      },
      {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        width: 350,
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '简介描述',
        dataIndex: 'briefdesc',
        key: 'briefdesc',
        render: text => (<div className="text-overflow-class" style={{maxWidth:'inherit'}} title={text}>{text}</div>)
      }, {
        title: '作者',
        dataIndex: 'creatorname',
        key: 'creatorname',
        width: '5%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      },{
        title: '修改',
        dataIndex: 'editorname',
        key: 'editorname',
        width: '5%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '所属分类',
        dataIndex: 'atnames',
        key: 'atnames',
        width: '8%',
        ...this.getColumnSearchProps('atnames'),
      },
      //  {
      //   title: '所属专题',
      //   dataIndex: 'ttnames',
      //   key: 'ttnames',
      //   width: '8%',
      //   ...this.getColumnSearchProps('ttnames'),
      // }
      , {
        title: '文章标签',
        dataIndex: 'lblnames',
        key: 'lblnames',
        width: '8%',
        ...this.getColumnSearchProps('lblnames'),
      },
      // {
      //   title: '缩略图',
      //   dataIndex: 'img',
      //   key: 'img',
      //   width: '6%',
      //   render: text => {
      //     if (text) {
      //       return (
      //         <div className="img-hover-container">
      //           <img
      //             onClick={() => this.setState({ prevVisible: true, prevImgUrl: text })}
      //             src={text}
      //             className="view-img"
      //             alt="图片" width="50"
      //             height="50" />
      //         </div>
      //       );
      //     }
      //     return <img src={defaultPicUrl} alt="图片" height="50" width="50" />;
      //   }
      // },
       {
        title: '创建时间',
        sorter: true,
        dataIndex: 'createtime_t',
        key: 'createtime_t',
        width: 100,
      }, {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        width: 120,
        fixed:'right',
        render: (text, record) => {
          const { articleStatus } = this.state;
          return (
            <div className="operate-col">
          {
                record.isweb?<Icon
              type="edit"
              title="编辑"
              style={{ fontSize: 16, color: 'red', marginRight: 5 }}
              onClick={() => {
                this.setState({ disPageIframe: false,disPageList:false,disPageComent:false,itemId:record.id})
            }
              }
            />:<Icon
            type="edit"
            title="编辑"
            style={{ fontSize: 16, color: '#83B6FF', marginRight: 5 }}
            onClick={() => 
              this.setState({ disPageList: false, updateStatus: true, curretnItem: record,disPageIframe:true })}
            // window.open(`https://www.musicheng.com/admin/news?id=${record.id}&uname=${record.creatorname}&uid=${record.creator}`)
          />
              }
              <Popconfirm
                placement="top"
                title={articleStatus === '3' ? "删除后无法恢复,确定要删除吗？" : "确定要删除吗？"}
                onConfirm={() => this.deleteArticle(record)}
                okText="确定"
                cancelText="取消"
              >
                <Icon title="删除" type="delete" style={{ fontSize: 16, color: '#FF4A4A', marginRight: 5 }} />
              </Popconfirm>
              {
                articleStatus === '3' ? <Icon type="rollback" title="恢复"
                  onClick={() => this.recover(record)} style={{ fontSize: 16, color: '#83B6FF', marginRight: 5 }} /> : ''
              }
              {
                  // <Link
                  //   to={{
                  //     pathname: `${ROUTE_PREFIX}/ans/quescommont`,
                  //     state: {
                  //       ...record
                  //     },
                  //   }}
                  // >
                  //   <Icon
                  //     title="评论管理"
                  //     type="message"
                  //     style={{ fontSize: 16, color: '#83B6FF', marginRight: 5 }}
                  //   />
                  // </Link>
                  // <Icon
                  //   title="评论管理"
                  //   type="message"
                  //   style={{ fontSize: 16, color: '#83B6FF', marginRight: 5 }}
                  //   onClick={()=>this.setState({
                  //     disPageComent:true,disPageList:false,disPageIframe:false,
                  //     conmentItem:record
                  //   })}
                  // />
              }
              {
                  <Icon
                    type="eye"
                    title="浏览"
                    style={{ fontSize: 16, color: '#83B6FF', marginRight: 5 }}
                    onClick={() => window.open(`https://www.musicheng.com/wenda/i${record.id}.html`)}
                  />
              }
            </div>
          );
        },
      }];
    const { list, loading, total } = this.props;
    // console.log(this.props)
    const { pageIndex, pageSize, deleteIds, articleStatus } = this.state;
    if (articleStatus === '2') { // 0 草稿 3 回收站  2 已发布
      columns.splice(3, 0, pushTimeCol);
      columns.splice(7, 0, modifiTimeCol);
      columns.splice(1, 0, baiDuCol);
    } else if (articleStatus === '0') {
      columns.splice(3, 0, modifiTimeCol);
      columns.splice(1, 0, sourceCol);
    } else if (articleStatus === '4') {
      columns.splice(3, 0, modifiTimeCol);
      columns.splice(1, 0, sourceCol);
    }
    else if (articleStatus === '3') {
      columns.splice(3, 0, modifiTimeCol);
      columns.splice(1, 0, sourceCol);
    }
    const pagination = {
      total,
      pageSize,
      current: pageIndex,
      showTotal: num => `共${num}项`,
      pageSizeOptions: ['10', '20', '30', '40','200','400','600','800','1000','2000'],
      showSizeChanger: true,
      showQuickJumper: true,
    };

    const rowSelection = {
      selectedRowKeys: deleteIds,
      onChange: (selectedRowKeys: string[], selectedRows: Object[]) => {
        this.setState({ deleteIds: selectedRowKeys });
      },
    };

    return <Table
      rowKey={record => record.id}
      dataSource={list}
      columns={columns} loading={loading}
      pagination={pagination}
      rowSelection={rowSelection}
      onChange={this.handleChange}
      scroll={{ x: 2000}} 
      // scroll={{ x: 2300 }}
    />;
  }

  renderPageList = () => {
    const { deleteIds, articleStatus, rangeTime } = this.state;
    return (
      <div className="page-list-wraper">
        <Row className="page-header" style={{height:'auto',minWidth:'1137px'}}>
          <Col span={6}>
            {/* <Button type="primary" onClick={() => this.setState({ disPageList: false, updateStatus: false })}>新增</Button> */}
            <Button type="primary" onClick={() => this.setState({ disPageIframe: false,disPageList:false,disPageComent:false,itemId:0})} style={{marginLeft:'15px'}}>高级新增</Button>
            <Divider type="vertical" />
            <BatchOperation ids={deleteIds} cb={this.getList} disabled={articleStatus !== '2'} />
          </Col>
          <Col span={18} className="search-wraper">
            <div className="item">
              是否修改&nbsp;:&nbsp;
              {this.renderSelectUpdate()}
            </div>
            <div className="item">
              来源&nbsp;:&nbsp;
              {this.renderSelectOrign()}
            </div>
            <div className="item">
              筛选&nbsp;:&nbsp;
              {this.renderSelectFilter()}
            </div>
            <div className="item">
              发布时间&nbsp;:&nbsp;
              <RangePicker size='default' value={rangeTime} onChange={this.onTimeChange} disabled={articleStatus !== '2'} style={{width:174}}/>
            </div>
            <Search
              placeholder="输入文章作者或标题查询"
              onSearch={value => this.setState({ query: value, pageIndex: 1, }, this.getList)}
              enterButton
              title='文章查询: i开头表示id查询; z开头表示作者查询; e开头表示修改人查询，其他标题查询'
              style={{width:160}}
            />
          </Col>
        </Row>
        <div className="table-wraper">
          <Tabs type="card" defaultActiveKey={articleStatus} onChange={this.onTabChange}>
            <TabPane tab="已发布" key="2"> </TabPane>
            <TabPane tab="草稿箱" key="0"></TabPane>
            <TabPane tab="回收站" key="3"></TabPane>
            <TabPane tab="草稿[淘宝]-我的" key="4"></TabPane>
            <TabPane tab="草稿[拼多多]-我的" key="6"></TabPane>
            <TabPane tab="草稿[shopee]-我的" key="7"> </TabPane>
            <TabPane tab="草稿[抖音]-我的" key="8"> </TabPane>
            <TabPane tab="草稿[其他]-我的" key="9"> </TabPane>
            { this.state.userID==372? <TabPane tab="我的审核" key="5"></TabPane>:'' }
          </Tabs>
          {this.renderTable()}
        </div>
      </div>
    );
  }

  render() {
    const { disPageList, updateStatus,disPageIframe,disPageComent,
      curretnItem, prevVisible, prevImgUrl,conmentItem } = this.state;
    return (
      <div className="container-wraper">
        {
          disPageList ? this.renderPageList() : disPageIframe?<Article updateStatus={updateStatus} curretnItem={curretnItem}
            backToPageList={this.backToPageList}
          />:disPageComent?
          <div>
              <div className="page-header">
                <Button className="btn-default" onClick={()=>this.setState({disPageList:true,disPageIframe:true})}>返回上级</Button>
              </div>
           <Comment conmentItem={conmentItem}/>
           </div>
           :
          <div>
            <div className="page-header">
              <Button className="btn-default" onClick={()=>this.setState({disPageList:true,disPageIframe:true})}>返回上级</Button>
            </div>
            <Iframe itemId={this.state.itemId>0?this.state.itemId:0}/>
            {/* <iframe src={'https://www.musicheng.com/admin/news?id='+this.state.itemId+'&uname='+this.state.userName+'&uid='+this.state.userID} frameborder="0" id='iframe' className='iframe' height='768'>
            </iframe> */}
          </div>
        }
        <PreviewImg visible={prevVisible} imageUrl={prevImgUrl}
          cancel={() => this.setState({ prevVisible: false })}></PreviewImg>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { articleRedux } = state;
    return ({ ...articleRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      fetchArticleList, addArticle, updateArticle,
      deleteArticle, phyDeleteArticle,
      fetchClassTree, fetchThemeTree, fetchAllLabel,
      revcoverArticle,
    }, dispatch,
  ),
)(AquesArticle);