/**
 * @flow
 */

import { message } from 'antd'
import actionFactory from './actionFactory'

const messageByCode = (code: number) => {
  try {
    const codeLevel = code.toString()[0];
    let msgOut = message.info;
    switch (codeLevel) {
      case '2':
        // msgOut = message.success;
        break;
      // case '400':
      //   // use default message out
      //   break;
      // case '2':
      //   msgOut = message.warning;
      //   break;
      // case '3':
      //   msgOut = message.error;
      // break;
      default:
        msgOut = message.error;
        break;
    }
    return msgOut;
  } catch (error) {
    return msg => msg;
  }
}

const config = {
  commonParams() {
    const shopId = sessionStorage.getItem('shopId')
    const appId = sessionStorage.getItem('appId')
    // return { shopId, appId }
    return {}
  },
  handleStatus(resp: Object) {
    const {
      code, data, msg, Code
    } = resp
    if (code === 200) {
      return data
    }
    if (code === 401) {
      message.warning('登录已过期,请重新登录!');
      setTimeout(() => {
        sessionStorage.removeItem('loginStatus');
        sessionStorage.removeItem('Authorization');
        window.location.href = '/';
      }, 3000)
      return;
    }
    messageByCode(code)(msg)
    throw msg
  },
}
const getFileConfig = {
  handleStatus(resp: Object) {
    const {
      retCode, filename, fileName, errInfo,
    } = resp
    if (retCode === 0) {
      const filePath = fileName.replace('../../', '');
      return { filename, filePath }
    }
    message.error(errInfo)
    throw errInfo
  },
}

const createAsyncAction = actionFactory(config)
const createEmptyAction = actionFactory()
const createDownloadAction = actionFactory(getFileConfig)

export { createAsyncAction, createEmptyAction, createDownloadAction }
