/**
 * @flow
 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

type Props = {
  LayoutRouter: any,
};
class AuthRoute extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const loginStatus = sessionStorage.getItem('loginStatus');
    if (loginStatus) { // 已经登录
      this.props.history.replace(`${ROUTE_PREFIX}/ecommerce/article`);
    } else {
      this.props.history.replace(`${ROUTE_PREFIX}/login`);
    }
  }

  render() {
    return null;
  }
}

export default AuthRoute;
