/**
 * @flow
 */

import React, { Component } from 'react';

import {
  Modal
} from 'antd';

import './index.less';

type Props = {
  visible: Boolean,
  imageUrl: string,
  cancel: Function,
}
class PreviewImg extends Component<Props> {
  state = {};

  render() {
    const { visible, cancel, imageUrl } = this.props;
    return (
      <Modal
        wrapClassName="preview-img-modal"
        visible={visible}
        title="图片预览"
        footer={null}
        onCancel={cancel}
      >
        <img alt="example" style={{ width: '100%' }} src={imageUrl} />
      </Modal>
    );
  }
}

export default PreviewImg;