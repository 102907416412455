/**
 * @flow
 */

import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { Icon } from 'antd';
import App from './containers';
import Login from './containers/login';
import Authenticate from 'components/authroute';
import { EcomClass, EcomArticle, ELabel, ETheme, Comment } from './pages/ecommerce';
import { Btheme,BwikiArticle,Bclass,WikiComment } from './pages/bk';
import {AnsClass , AnsTheme,AquesArticle,aComment,anSadv} from './pages/ans';
import {CourseClass,CourseArticle,CourseManage,Cnav} from './pages/course';
import SAdv from './pages/system/eadv';
import Stu from './pages/system/student';
import Nav from './pages/system/nav';

export const routerConfig: RouterConfig = [
  {
    name: '电商行情',
    image: <Icon type="pie-chart" />,
    router: { path: 'ecommerce', },
    default: true,
    children: [
      {
        name: '新闻文章',
        ppath: 'ecommerce',
        router: { path: 'ecommerce/article', component: EcomArticle },
      },
      {
        name: '文章评论管理',
        ppath: 'ecommerce',
        hide: true,
        router: { path: 'ecommerce/commont', component: Comment },
      },
      {
        name: '分类管理',
        ppath: 'ecommerce',
        router: { path: 'ecommerce/class', component: EcomClass },
      },
      {
        name: '标签管理',
        ppath: 'ecommerce',
        router: { path: 'ecommerce/label', component: ELabel },
      },
      {
        name: '专题管理',
        ppath: 'themetype',
        router: { path: 'ecommerce/themetype', component: ETheme },
      },
    ]
  },
  {
    name: '电商百科',
    image: <Icon type="read" />,
    router: { path: 'bk', },
    children: [
      {
        name: '百科信息',
        ppath: 'bk',
        router: { path:'bk/article',component: BwikiArticle},
      },
      {
        name: '专题管理',
        ppath: 'bk',
        hide: true,
        router: { path:'bk/theme',component: Btheme},
      },
      {
        name: '分类管理',
        ppath: 'bk',
        router: { path:'bk/class',component: Bclass},
      }
    ]
  },
  {
    name: '电商问答',
    image: <Icon type="question" />,
    router: { path: 'ans', },
    children: [
      {
        name: '分类管理',
        ppath: 'ans',
        router: { path:'ans/class',component: AnsClass},
      },{
        name: '问答管理',
        ppath: 'ecommerce',
        hide: true,
        router: { path: 'ans/quescommont', component: aComment },
      },
      {
        name: '专题管理',
        ppath: 'ans',
        hide: true,
        router: { path:'ans/theme',component: AnsTheme},
      },
      {
        name: '问答管理',
        ppath: 'ans',
        router: { path:'ans/ques',component: AquesArticle},
      },
      // {
      //   name: '广告位',
      //   ppath: 'ans',
      //   router: { path:'ans/eadv',component: anSadv},
      // },
    ]
  },
  // {
  //   name: '电商教程',
  //   image: <Icon type="play-circle" />,
  //   router: { path: 'course', },
  //   children: [
  //     {
  //       name: '教程分类',
  //       ppath: 'course',
  //       router: { path:'course/class',component: CourseClass},
  //     },
  //     {
  //       name: '课程管理',
  //       ppath: 'course',
  //       hide: false,
  //       router: { path:'course/courseManage',component: CourseManage},
  //     },
  //     {
  //       name: '导航管理',
  //       ppath: 'nav',
  //       router: { path:'course/nav',component: Cnav},
  //     },
  //     {
  //       name: '教程详情',
  //       ppath: 'course',
  //       hide: true,
  //       router: { path:'course/article',component: CourseArticle},
  //     },
  //     // {
  //     //   name: '视频管理',
  //     //   ppath: 'vedio',
  //     //   hide: true,
  //     //   router: { path:'course/CcArticle',component: CcArticle},
  //     // }
  //   ]
  // },
  {
    name: '系统配置',
    image: <Icon type="setting" />,
    router: { path: 'system', },
    children: [
      {
        name: '广告位',
        ppath: 'system',
        router: { path: 'system/adv', component: SAdv },
      },
      {
        name: '学员案例',
        ppath: 'system',
        router: { path: 'system/stu', component: Stu },
      },
      {
        name: '首页配置',
        ppath: 'system',
        router: { path: 'system/nav', component: Nav },
      }
    ]
  }
];



export const getRouterChainByPath = (paths: Array<string>) => {
  const routerArray = [];
  // console.log(paths);
  const getAllRouter = (routers: Array) => {
  
    routers.map(item => {
      routerArray.push(item);
      if (item.children) {
        getAllRouter(item.children);
      }
    });
  };
  getAllRouter(routerConfig);
  // console.log(routerArray)

  const targetRouters = [];
  paths.map(path => {
    if (routerArray.find(item => item.router.path.indexOf(path) !== -1) && path) {
      targetRouters.push(routerArray.find(item => item.router.path.indexOf(path) !== -1));
    }
  });
  return targetRouters;
};

const getRouters = (config: RouterConfig) => config.map((items) => {
  const { children } = items;
  let routers = [];
  if (children) {
    routers = children.map(child => setRoute(child));
  } else {
    routers = setRoute(items);
  }
  return routers;
});

const setRoute = (item: Object) => {
  const { router, subRouter } = item;
  // console.log(router,subRouter);
  const routers = subRouter ?
    subRouter.map(route => <Route key={route.path} path={`${ROUTE_PREFIX}/${route.path}`} exact component={route.component} />) :
    [];
  router && routers.push(<Route key={router.path} path={`${ROUTE_PREFIX}/${router.path}`} exact component={router.component} />);
  return routers;
};

const jumpToLogin = withRouter(({ history }) => {
  const loginStatus = sessionStorage.getItem('loginStatus');
  if (!loginStatus) {
    history.replace(`${ROUTE_PREFIX}/login`);
  }
  return null;
});

const routes = () => {
  return (
    <Router>
      <Switch>
        <Route path={`${ROUTE_PREFIX}/login`} component={Login} />
        <Route path={`${ROUTE_PREFIX}`} render={(props) => (
          <App {...props}>
            <Switch>
              {getRouters(routerConfig)}
              <Route
                path={`${ROUTE_PREFIX}`}
                exact
                component={Authenticate}
              />
              <Redirect from={`${ROUTE_PREFIX}`} to={`${ROUTE_PREFIX}/ecommerce/article`} />
              <Route component={() => <div>加载中...</div>} />
            </Switch>
            <Route component={jumpToLogin} />
          </App>
        )} />
      </Switch>
    </Router>
  );
};

export default routes;
