/**
 * @flow
 */
import { ansyHandleFactory, handleActions } from 'utils/actionFactory';
import { createAsyncAction } from 'utils/request';
import { bkThemeUrl,bkThemeTreeUrl ,eLabelUrl} from './url';

const fetchClassListData = ansyHandleFactory('fetchClassList');
const addClassData = ansyHandleFactory('addClass');
const updateClassData = ansyHandleFactory('updateClass');
const deleteClassData = ansyHandleFactory('deleteClass');
const getDetailData = ansyHandleFactory('getDetail');
const getParentClassData = ansyHandleFactory('getParentClass');
const fetchClassTreeData = ansyHandleFactory('fetchClassTreeData'); // 获取分类树
const fetchAllLabelListData = ansyHandleFactory('fetchAllLabelListData'); // 获取所有标签

const traversalTree = (tree) => {
    if (!tree) return;
  
    tree.map(item => {
      if (item.children) {
        traversalTree(item.children);
      }
      // if (item.level === 0) {
      //   item.disabled = true;
      // }
      item.key = item.id;
      item.title = item.name;
      item.value = item.id;
    });
    return tree;
  };
const ClassRedux = handleActions(
    fetchClassListData('GET', {
        pending: state => ({ ...state, list: [], loading: true,total: 0 }),
        accept: (state, action) => ({ ...state, list: action.payload.pageData, total: action.payload.totalItems,loading: false }),
        reject: state  => ({ ...state, list: [], loading: false,total: 0 }),
    }),
    addClassData('POST', {
        accept: (state) => ({ ...state, loading: false }),
    }),
    updateClassData('POST', {
        accept: (state) => ({ ...state, loading: false }),
    }),
    deleteClassData('GET', {
        accept: (state) => ({ ...state, loading: false }),
    }),
    getDetailData('GET', {
        pending: (state) => ({ ...state, classDetail:{}, detailLoading: true, }),
        accept: (state,action) => ({ ...state, detailLoading: false, classDetail: action.payload}),
        reject: state => ({ ...state, classDetail:{}, detailLoading: false,})
    }),
    fetchClassTreeData('GET', {
        pending: state => ({ ...state, classTree: [], classTreeLoading: true }),
        accept: (state, action) => {
          return {
            ...state, classTree: traversalTree(action.payload), classTreeLoading: false
          };
        },
        reject: state => ({ ...state, classTree: [], classTreeLoading: false }),
      }),
    getParentClassData('GET', {
        pending: (state) => ({ ...state, parentClass: [], classLoading: true }),
        accept: (state, action) => ({ ...state, parentClass: action.payload, classLoading: false }),
        reject: (state) => ({ ...state, parentClass: [], classLoading: false }),
    }),
    fetchAllLabelListData('GET', {
        pending: state => ({ ...state, labelList: [], labelLoading: true }),
        accept: (state, action) => {
          return {
            ...state, labelList: action.payload, labelLoading: false
          };
        },
        reject: state => ({ ...state, labelList: [], labelLoading: false }),
      }),
    {
        loading: false,
        list: [],
        parentClass: [],
        classLoading: false,
        detailLoading: false,
        classDetail: {},
        total: 0,
    },
);

export default ClassRedux;

/* ************************************************** 同步请求数据 ************************************************ */

/* ************************************************** 异步请求数据 *********************************************** */
type fetchClassListParams = {
};

export const fetchClassList: (obj: fetchClassListParams) => disPromise<*> = createAsyncAction(
    `${bkThemeUrl}/GetWikiList`,
    fetchClassListData('GET'),
);

type addClassParams = {
};

export const addClass: (obj: addClassParams) => disPromise<*> = createAsyncAction(
    `${bkThemeUrl}/WikiInsert`,
    addClassData('POST'),
);

type updateClassParams = {
};

export const updateClass: (obj: updateClassParams) => disPromise<*> = createAsyncAction(
    `${bkThemeUrl}/WikiUpdate`,
    updateClassData('POST'),
);

type deleteClassParams = {
};
export const deleteClass: (obj: deleteClassParams) => disPromise<*> = createAsyncAction(
    `${bkThemeUrl}/WikiDelete`,
    deleteClassData('POST'),
);

type getDetailParam = {
};
export const getDetailClass: (obj: getDetailParam) => disPromise<*> = createAsyncAction(
    `${bkThemeUrl}/detail`,
    getDetailData('GET'),
);

type getParentParams = {
};
export const getParentClass: (obj: getParentParams) => disPromise<*> = createAsyncAction(
    `${bkThemeUrl}/WikiChildThemeList`,
    getParentClassData('GET'),
);

type fetchClassTreeParams = {
};

export const fetchClassTree: (obj: fetchClassTreeParams) => disPromise<*> = createAsyncAction(
  `${bkThemeTreeUrl}`,
  fetchClassTreeData('GET'),
);

export const fetchAllLabel: (obj: fetchAllLabelListParams) => disPromise<*> = createAsyncAction(
    `${eLabelUrl}/GetAllList`,
    fetchAllLabelListData('GET'),
  );
