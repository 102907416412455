/**
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Button, Table, Modal,
  Icon, Popconfirm, message, Row, Col,
  Select, Divider, Spin
} from 'antd';
import { fetchThemeList, addTheme, updateTheme, deleteTheme, getDetailTheme,getParentTheme } from 'modules/cNavRedux';

import NavForm from './form';
const { Search } = Input;
const { Option } = Select;

import './index.less';
import defaultPicUrl from "../../../assets/images/default-pic.png";

type Props = {
  list: Array<Object>,
  loading: boolean,
  parentTheme: Array<Object>,
  ThemeLoading: Boolean,
  fetchThemeList: typeof fetchThemeList,
  addTheme: typeof addTheme,
  updateTheme: typeof updateTheme,
  deleteTheme: typeof deleteTheme,
  getParentTheme: typeof getParentTheme,
  detailLoading: boolean,
  ThemeDetail: Object,
};
type State = {
  modalVisible: boolean,
  updateStatus: boolean,
  query: string,
  pageIndex: number,
  pageSize: number,
  deleteIds: Array<number>,
  currentItem: Object,
  choseParentThemeId: Number,
};
class Cnav extends Component<Props, State> {
  form: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVisible: false,
      updateStatus: false,
      query: '',
      pageIndex: 1,
      pageSize: 10,
      deleteIds: [],
      currentItem: {},
      choseParentThemeId: '',
    };
  }

  componentDidMount = () => {
    this.getList();
    this.getAllParentTheme();
  }

  getList = () => {
    const { pageIndex, pageSize, query, choseParentThemeId } = this.state;
    const params = {
      pageIndex, pageSize, name: query,

    };
    if (choseParentThemeId) params.parentId = choseParentThemeId;
    else if(choseParentThemeId === 0) params.parentId = choseParentThemeId;
    this.props.fetchThemeList({
      ...params
    });
  }

  showModal = (obj, cb) => {
    this.setState({ ...obj }, cb);
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
    this.form.resetFields();
  }


  handleCancel = () => {
    this.form.resetFields();
    this.setState({
      modalVisible: false,
    });
  }

  submit = () => {
    this.form.validateFields((err, values) => {
      if (err) return;
      const { updateStatus } = this.state;
      const { currentItem } = this.state;
      const imgUrl = values.img && values.img[0] ?
        (values.img[0].response ? values.img[0].response.data : values.img) : '';
      const parentid = values.parentid ? +values.parentid : 0;
      if (updateStatus) {
        this.props.updateTheme({ ...currentItem, ...values, img: imgUrl, parentid })
          .then(() => {
            message.success('编辑成功');
            this.closeModal();
            this.getList();
            this.getAllParentTheme();
          });
      } else {
        const params = {
          ...values,
          img: imgUrl,
          parentid
        };
        this.props.addTheme(params)
          .then(() => {
            message.success('新增成功');
            this.closeModal();
            this.getList();
            this.getAllParentTheme();
          });
      }
    });
  }

  handleChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const obj = {
      pageIndex: current,
      pageSize,
    };
    this.setState({ ...obj }, () => this.getList());
  };

  deleteTheme = (item) => {
    this.props.deleteTheme({
      key: [item.id]
    }
    ).then(res => {
      if (res) {
        message.success("删除成功");
        this.getList();
      }
    });
  }

  batchDelete = () => {
    const { deleteIds } = this.state;
    if (deleteIds.length === 0) {
      message.warning("请选择需要删除的分类");
      return;
    }

    this.props.deleteTheme({
      key: deleteIds
    }).then(res => {
      if (res) {
        message.success('删除成功');
        this.getList();
      }
    });
  }

  getDetail = () => {
    const { id } = this.state.currentItem;
    this.props.getDetailTheme({
      id,
    });
  }

  getAllParentTheme = () => {
    this.props.getParentTheme();
  }

  onSelectChange = (value) => {
    this.setState({
      pageIndex: 1,
      choseParentThemeId: value,
    }, this.getList);
  }

  renderTable = () => {
    const columns = [
      {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        width: '10%',
      }, {
        title: '简标题',
        dataIndex: 'shorttitle',
        key: 'shorttitle',
        width: '10%',
      }, {
        title: '导航分类',
        dataIndex: 'typeid',
        key: 'typeid',
        width: '10%',
        render:text =>{
          return ( text==1?'标题导航':'百度导航')
        }
      }, {
        title: '父类名称',
        dataIndex: 'parentname',
        key: 'parentname',
        width: '10%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '课程节数',
        dataIndex: 'videocount',
        key: 'videocount',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '图片',
        dataIndex: 'img',
        key: 'img',
        width: '15%',
        render: text => {
          if (text) {
            return <img src={text} alt="图片" height="40" width="40" />;
          }
          return <img src={defaultPicUrl} alt="图片" height="40" width="40" />;
        }
      }, {
        title: 'url',
        dataIndex: 'url',
        key: 'url',
        width: '20%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      },{
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        width: "6%",
        render: (text, record) => (
          <div className="operate-col">
            <Icon
              type="edit"
              title="编辑"
              style={{ fontSize: 16, color: '#83B6FF', marginRight: 10 }}
              onClick={() => {
                this.showModal({ modalVisible: true, updateStatus: true, currentItem: record }, this.getDetail);
              }}
            />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={() => this.deleteTheme(record)}
              okText="确定"
              cancelText="取消"
            >
              <Icon type="delete" title="删除" style={{ fontSize: 16, color: '#FF4A4A' }} />
            </Popconfirm>
          </div>
        ),
      }];
    const { list, loading, total } = this.props;
    const { pageIndex, pageSize, deleteIds } = this.state;
    const pagination = {
      total,
      pageSize,
      current: pageIndex,
      showTotal: num => `共${num}项`,
      pageSizeOptions: ['10', '20', '30', '40'],
      showSizeChanger: true,
      showQuickJumper: true,
    };

    const rowSelection = {
      columnWidth: '5%',
      selectedRowKeys: deleteIds,
      onChange: (selectedRowKeys: string[], selectedRows: Object[]) => {
        this.setState({ deleteIds: selectedRowKeys });
      },
    };

    return <Table
      rowKey={record => record.id}
      dataSource={list}
      columns={columns} loading={loading}
      pagination={pagination}
      rowSelection={rowSelection}
      onChange={this.handleChange}
    />;
  }

  render() {
    const { modalVisible, updateStatus, currentItem } = this.state;
    const { parentTheme, ThemeLoading, loading, detailLoading, ThemeDetail } = this.props;
    return (
      <div className="container-wraper">
        <Row className="page-header">
          <Col span={10}>
            <Button type="primary" onClick={() => this.showModal({ modalVisible: true, updateStatus: false })}
            >新增</Button>
            <Divider type="vertical" />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={this.batchDelete}
              okText="确定"
              cancelText="取消"
            >
              {/* <Button className="danger-btn" >批量删除</Button> */}
            </Popconfirm>
          </Col>
          <Col span={14} className="search-wraper">
          {/* 导航类型：&nbsp;
            <Select
            style={{ width: 200, marginRight: '16px' }}
            >
              <Option value="1">标题导航</Option>
              <Option value="2">图片导航</Option>
            </Select> */}
            一级专题：&nbsp;
                        <Select
              showSearch
              style={{ width: 200, marginRight: '16px' }}
              optionFilterProp="children"
              onChange={this.onSelectChange}
              placeholder="请选择"
              loading={ThemeLoading}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {parentTheme.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
            <Search
              placeholder="输入专题名称查询"
              onSearch={value => this.setState({ query: value }, this.getList)}
              enterButton
            />
          </Col>
        </Row>
        <div className="table-wraper">
          {this.renderTable()}
        </div>
        <Modal
          title={updateStatus ? '编辑' : '新增'}
          visible={modalVisible}
          onOk={this.submit}
          onCancel={this.handleCancel}
          confirmLoading={loading}
          destroyOnClose={true}
        >
          <Spin spinning={detailLoading}>
            <NavForm
              currentItem={updateStatus ? ThemeDetail : {}}
              imgUrl={currentItem.img}
              updateStatus={updateStatus}
              parentTheme={parentTheme}
              ref={(form) => { return form ? this.form = form : null; }}
            />
          </Spin>

        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { cNavRedux } = state;
    return ({ ...cNavRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      fetchThemeList, addTheme, updateTheme, deleteTheme, getDetailTheme,getParentTheme
    }, dispatch,
  ),
)(Cnav);