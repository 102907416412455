/**
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

import { routerConfig } from '../../../routers';
import './index.less';

const { SubMenu } = Menu;

type Props = {
  breadcrumbs: Array<Object>,
}

type State = {
  currentkey: string,
  openKeys: string
}
class LeftNav extends Component<Props, State> {

  state = {
    currentkey: '',
    openKeys: ''
  }

  componentDidMount = () => {
    this.setOpenKeysByCurrentKey(this.state.currentkey);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.currentkey !== prevState.currentkey && this.state.currentkey) {
      // 更新openkeys 
      this.setOpenKeysByCurrentKey(this.state.currentkey);
    }
  }

  // window.location变化的时候更新
  static getDerivedStateFromProps() {
    const { href } = window.location;
    // const href = 'http://localhost:3001/admin/ecommerce/article';

    let tempRouter = null;
    routerConfig.forEach(item => {
      if (item.name && item.router && href.indexOf(item.router.path) > -1) {
        // keypath = item.router.path;
        tempRouter = item;
        item.children && item.children.forEach(child => {
          if (href.indexOf(child.router.path) > -1) {
            // keypath = child.router.path;
            tempRouter = child;
          }
        });
      }
    });
    if (!tempRouter || tempRouter.hide) {
      return null;
    }
    return {
      currentkey: tempRouter.router.path,
    };
  }


  setOpenKeysByCurrentKey = (currentkey) => {

    // 更新openkeys 
    const router = this.getRouterByPath(currentkey);
    const prouter = router ? this.getRouterByPath(router.ppath) : undefined;
    if (router && prouter) {
      this.setState({
        openKeys: prouter.router.path
      });
    }
  }

  getRouterByPath = (path) => {
    const routerList = [];
    const getAllRouter = (routers) => {
      routers.map(item => {
        routerList.push(item);
        if (item.children) {
          getAllRouter(item.children);
        }
      });
    };
    getAllRouter(routerConfig);
    return routerList.find(item => item.router.path === path);
  }

  handleSelect = (type: Object) => {

    // 获取多个key
    this.setState({
      currentkey: type.key,
    });
  }

  onOpenChange = (openKeys) => {
    this.setState({
      openKeys: openKeys.length === 2 ? openKeys[1] : ''
    });
  }
  render() {
    const { currentkey, openKeys } = this.state;
    const { collapsed } = this.props;
    // console.log(collapsed);
    return (
      <Menu
        theme="dark"
        className="left-nav"
        onSelect={this.handleSelect}
        selectedKeys={[currentkey]}
        openKeys={!collapsed ? [openKeys] : []}
        onOpenChange={this.onOpenChange}
        mode="inline"
        inlineCollapsed={collapsed}
      // triggerSubMenuAction="click"
      >
        {routerConfig.map(item => (
          item.children ?
            <SubMenu
              key={item.router.path}
              title={<span>{item.image}<span styleName="title">{item.name}</span></span>}
            >
              {item.children && item.children.map(child => (
                child.name ?
                  <Menu.Item key={child.router.path} style={{ display: child.hide ? 'none' : '' }}>
                    <Link to={child.router.path ? `${ROUTE_PREFIX}/${child.router.path}` : `${ROUTE_PREFIX}/`}>
                      <span styleName="title">{child.name}</span>
                    </Link>
                  </Menu.Item> : null
              ))}
            </SubMenu> :
            <Menu.Item key={item.router.path} style={{ display: item.hide ? 'none' : '' }}>
              <Link to={item.router.path ? `${ROUTE_PREFIX}/${item.router.path}` : `${ROUTE_PREFIX}/`}>
                {item.image}
                <span styleName="title">{item.name}</span>
              </Link>
            </Menu.Item>
        ))}
      </Menu>
    );
  }
}

export default connect(
  (state) => {
    const { systemReducer } = state;
    return ({ breadcrumbs: systemReducer.breadcrumbs });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
    }, dispatch,
  ),
)(LeftNav);
