/**
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Button, Table, Modal,
  Icon, Popconfirm, message, Row, Col,
  Select, Divider, Spin
} from 'antd';
import { fetchClassList, addClass, updateClass, deleteClass, getParentClass, getDetailClass,fetchAllLabel } from 'modules/cClassRedux';
import defaultPicUrl from '../../../assets/images/default-pic.png';


import ClassForm from './form';
const { Search } = Input;
const { Option } = Select;

import './index.less';

type Props = {
  list1: Array<Object>,
  loading: boolean,
  parentClass: Array<Object>,
  classLoading: Boolean,
  fetchClassList: typeof fetchClassList,
  addClass: typeof addClass,
  updateClass: typeof updateClass,
  deleteClass: typeof deleteClass,
  getParentClass: typeof getParentClass,
  detailLoading: boolean,
  classDetail: Object,
  fetchAllLabel: typeof fetchAllLabel,
};
type State = {
  modalVisible: boolean,
  updateStatus: boolean,
  updateStatus1: boolean,
  query: string,
  pageIndex: number,
  pageSize: number,
  deleteIds: Array<number>,
  currentItem: Object,
  choseParentClassId: Number,
};
class CourseClass extends Component<Props, State> {
  form: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVisible: false,
      updateStatus: false,
      updateStatus1:false,
      query: '',
      pageIndex: 1,
      pageSize: 10,
      deleteIds: [],
      currentItem: {},
      choseParentClassId: '',
      routerName:2,
      istype:1,
      expandedRowKeysArr:[],
      expandedRowKeyStatus1:false,
    };
  }

  componentDidMount = () => {
    this.getList();
    this.getAllParentClass();
    this.props.fetchAllLabel();
    // console.log(this.props)
  }
  getList = () => {
    const { pageIndex, pageSize, query, choseParentClassId,istype } = this.state;
    const params = {
      pageIndex, pageSize, name: query,
    };
    if (choseParentClassId) params.parentId = choseParentClassId;
    else if(choseParentClassId === 0) params.parentId = choseParentClassId;
    this.props.fetchClassList({
      ...params
    });
  }

  showModal = (obj, cb) => {
   
    this.setState({ ...obj }, cb);
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
    this.form.resetFields();
  }


  handleCancel = () => {
    this.form.resetFields();
    this.setState({
      modalVisible: false,
    });
  }

  submit = () => {
    this.form.validateFields((err, values) => {
      if (err) return;
      const { updateStatus } = this.state;
      const { currentItem } = this.state;
      const imgUrl = values.detailimg && values.detailimg[0] ?
        (values.detailimg[0].response ? values.detailimg[0].response.data : values.detailimg) : '';
      const imgUrl1 = values.seriesimg && values.seriesimg[0] ?
      (values.seriesimg[0].response ? values.seriesimg[0].response.data : values.seriesimg) : '';
      const imgUrl1_m = values.seriesimg_m && values.seriesimg_m[0] ?
      (values.seriesimg_m[0].response ? values.seriesimg_m[0].response.data : values.seriesimg_m) : '';
      const imgUrl_m = values.detailimg_m && values.detailimg_m[0] ?
      (values.detailimg_m[0].response ? values.detailimg_m[0].response.data : values.detailimg_m) : '';
      if(!values.spell.indexOf('i')||!values.spell.indexOf('I')||!values.spell.indexOf('v')||!values.spell.indexOf('V')){
        alert('不能首字母为i,I,v,V开头')
      }else{
        if (updateStatus) {
          this.props.updateClass({ ...currentItem, ...values, detailimg: imgUrl,seriesimg:imgUrl1,seriesimg_m:imgUrl1_m,detailimg_m:imgUrl_m})
            .then(() => {
              message.success('编辑成功');
              this.closeModal();
              this.getList();
              this.getAllParentClass();
            });
        } else {
          const params = {
            ...values,
            detailimg: imgUrl,
            seriesimg:imgUrl1,
            seriesimg_m:imgUrl1_m,
            detailimg_m:imgUrl_m
          };
          console.log(params);
          params.parentid = +values.parentid;
          this.props.addClass(params)
            .then(() => {
              message.success('新增成功');
              this.closeModal();
              this.getList();
              this.getAllParentClass();
            });
        }
      }
    });
  }
  
  customExpandIcon=(props)=>{
    if(props.record.children.length > 0){
      if (props.expanded) {
          return <div role="button" tabindex="0" class="ant-table-row-expand-icon ant-table-row-expanded" aria-label="关闭行" onClick={e => {
                 props.onExpand(props.record, e)}}></div>
      } else {
          return <div role="button" tabindex="0" class="ant-table-row-expand-icon ant-table-row-collapsed" aria-label="展开行" aria-label="关闭行" onClick={e => {
            props.onExpand(props.record, e)}}></div>
      }
  }else{
      return <span style={{marginRight:8 }}></span>
  }
  }
  // http://www.matools.com/blog/190318329
  handleChange = (pagination) => {
    const { current, pageSize } = pagination;
    // console.log(pagination)
    const obj = {
      pageIndex: current,
      pageSize,
    };
    this.setState({ ...obj }, () => this.getList());
  };
  handleSelect=(record, selected, selectedRows, nativeEvent)=>
  {
  }
  click = (record,rowkey)=>{

  }
  deleteClass = (item) => {
    this.props.deleteClass({
      key: item.id
    }
    ).then(res => {
      if (res) {
        message.success("删除成功");
        this.getList();
      }
    });
  }

  batchDelete = () => {
    const { deleteIds } = this.state;
    if (deleteIds.length === 0) {
      message.warning("请选择需要删除的分类");
      return;
    }

    this.props.deleteClass({
      key: deleteIds.join(',')
    }).then(res => {
      if (res) {
        message.success('删除成功');
        this.getList();
      }
    });
  }

  getDetail = () => {
    const { id,parentid } = this.state.currentItem;

    this.props.getDetailClass({
      id,
    });
  }

  getAllParentClass = () => {
    this.props.getParentClass();
  }

  onSelectChange = (value) => {
    // console.log(value)
    this.setState({
      pageIndex: 1,
      choseParentClassId: value,
    }, this.getList);
  }
  onExpandedRowsChange=(expandedRow)=>{
    // console.log(expandedRow)
    this.setState({
      expandedRowKeysArr:expandedRow,
      expandedRowKeyStatus1:true
    })
  }
  expandedRowKeysHandle =(data)=>{
    // console.log(data)
    return data.map(item => item.id)
  }
  expandFunction = (expanded, record)=>{

  }
  setRowClassName = (record) => {
    if(record.children.length>0){
      return 'blodfont'
    }
  }
  renderTable = () => {
    const columns = [{
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      },
      {
        title: '拼音',
        dataIndex: 'spell',
        key: 'spell',
        width: '10%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      },  {
        title: '简标题',
        dataIndex: 'shorttitle',
        key: 'shorttitle',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        width: "6%",
        render: (text, record) => (
          <div className="operate-col">
            <Icon
              type="edit"
              title="编辑"
              style={{ fontSize: 16, color: '#83B6FF', marginRight: 10 }}
              onClick={() => {
                this.showModal({ modalVisible: true, updateStatus: true, currentItem: record }, this.getDetail);
              }}
            />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={() => this.deleteClass(record)}
              okText="确定"
              cancelText="取消"
            >
              <Icon type="delete" title="删除" style={{ fontSize: 16, color: '#FF4A4A' }} />
            </Popconfirm>
          </div>
        ),
      }
      // {
      //   title: '缩略图',
      //   dataIndex: 'img',
      //   key: 'img',
      //   width: "7%",
      //   render: text => {
      //     if (text) {
      //       return <img src={text} alt="图片" height="40" width="40" />;
      //     }
      //     return <img src={defaultPicUrl} alt="图片" height="40" width="40" />;
      //   }
      // }, 
];
    const { list1, loading, total } = this.props;
    const { pageIndex, pageSize, deleteIds,expandedRowKeyStatus1,expandedRowKeysArr } = this.state;

    const rowSelection = {
      columnWidth: '5%',
      selectedRowKeys: deleteIds,
      onChange: (selectedRowKeys: string[], selectedRows: Object[]) => {
        this.setState({ deleteIds: selectedRowKeys });
      }
    }

    return <Table
      rowKey={record => record.id}
      dataSource={Array.from(list1)}
      columns={columns} loading={loading}
      rowSelection={rowSelection}
      onChange={this.handleChange}
      onSelect={this.handleSelect}
      rowClassName={this.setRowClassName}
      pagination={ false } 
      childrenColumnName={'children'}
      onExpand={(expanded, record) => { return this.expandFunction(expanded, record) || null; }}
      expandedRowKeys={expandedRowKeyStatus1?expandedRowKeysArr:Array.from(list1).map(item=>{
        return item.id})}
      onExpandedRowsChange={this.onExpandedRowsChange}
      expandIcon={(props) => this.customExpandIcon(props)}
    />;
  }

  render() {
    const { modalVisible, updateStatus,updateStatus1, currentItem } = this.state;
    const { parentClass, classLoading, loading, detailLoading, classDetail ,labelList} = this.props;
    return (
      <div className="container-wraper">
        <Row className="page-header">
          <Col span={10}>
            <Button type="primary" onClick={() => this.showModal({ modalVisible: true, updateStatus: false,istype:1 })}>新增分类</Button>
            <Divider type="vertical" />
            <Button type="primary" onClick={() => this.showModal({ modalVisible: true, updateStatus: false,istype:0 })}>新增教程</Button>
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={this.batchDelete}
              okText="确定"
              cancelText="取消"
            >
              {/* <Button className="danger-btn" >批量删除</Button> */}
            </Popconfirm>
          </Col>
          <Col span={14} className="search-wraper">
            一级分类：&nbsp;
                        <Select
              showSearch
              style={{ width: 200, marginRight: '16px' }}
              optionFilterProp="children"
              onChange={this.onSelectChange}
              placeholder="请选择"
              loading={classLoading}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {parentClass.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
            <Search
              placeholder="输入分类名查询"
              onSearch={value => this.setState({ query: value, pageIndex: 1, }, this.getList)}
              enterButton
            />
          </Col>
        </Row>
        <div className="table-wraper table-more-btn2">
              {this.renderTable()}
        </div>
        <Modal
          title={updateStatus ? '编辑' : '新增'}
          visible={modalVisible}
          onOk={this.submit}
          onCancel={this.handleCancel}
          confirmLoading={loading}
          destroyOnClose={true}
          className="course-class-wraper"
          popup="true"
          style={{top:20}}
        >
          <Spin spinning={detailLoading}>
            <ClassForm
              currentItem={updateStatus ? classDetail : {}}
              imgUrl={currentItem.detailimg}
              imgUrl_m={currentItem.detailimg_m}
              imgUrl1={currentItem.seriesimg}
              imgUrl1_m={currentItem.seriesimg_m}
              updateStatus={updateStatus}
              updateStatus1={updateStatus1}
              parentClass={parentClass}
              labelList={labelList}
              istype={this.state.istype}
              ref={(form) => { return form ? this.form = form : null; }}
            />
          </Spin>

        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { cClassRedux } = state;
    return ({ ...cClassRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      fetchClassList, addClass, updateClass, deleteClass, getParentClass, getDetailClass,fetchAllLabel
    }, dispatch,
  ),
)(CourseClass);
