/**
 * @flow
 * @author wanglei
 */

import React, { Component } from 'react';
import {Form, Input} from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;

class RepForm extends Component{

    render(){
        const formItemLayout = {
            labelCol: {
                xs: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 19 },
            },
        };
        const {
            getFieldDecorator, // 用于和表单进行双向数据绑定
        } = this.props.form;

        const {
            info
        } = this.props;
        return (
            <Form>
                <FormItem
                    label="回复"
                    {...formItemLayout}
                >
                    {getFieldDecorator('info', {
                        initialValue:  info || '',
                        rules: [{
                            required: true, message: '回复不能为空',
                        }]
                    })(
                        <TextArea placeholder="请输入回复" />
                    )}
                </FormItem>
            </Form>
        )
    }
}

export default Form.create()(RepForm);
