/**
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Button, Table, Modal,
  Icon, Popconfirm, message, Row, Col,
  Select, Divider, Spin
} from 'antd';
import { fetchList, add, update, deleteAdv, getDetail } from 'modules/advRedux';
import defaultPicUrl from 'images/default-pic.png';
import Iframe from './iframe';

import AdvForm from './form';
const { Search } = Input;
const { Option } = Select;

import './index.less';

type Props = {
  list: Array<Object>,
  loading: boolean,
  parentClass: Array<Object>,
  classLoading: Boolean,
  fetchList: typeof fetchList,
  add: typeof add,
  update: typeof update,
  deleteAdv: typeof deleteAdv,
  detailLoading: boolean,
  advDetail: Object,
};
type State = {
  modalVisible: boolean,
  updateStatus: boolean,
  query: string,
  pageurl: string,
  pagelocation: string,
  pageIndex: number,
  pageSize: number,
  deleteIds: Array<number>,
  currentItem: Object,
};
class Nav extends Component<Props, State> {
  form: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVisible: false,
      updateStatus: false,
      query: '',
      pageurl: '',
      pagelocation: '',
      pageIndex: 1,
      pageSize: 10,
      deleteIds: [],
      currentItem: {},
    };
  }

  componentDidMount = () => {
    this.getList();
  }

  getList = () => {
    const { pageIndex, pageSize, query, pageurl, pagelocation } = this.state;

    console.log(pagelocation);

    const params = {
      pageIndex, pageSize, searchkey: query, pageurl: pageurl, pagelocation: pagelocation

    };
    this.props.fetchList({
      ...params
    });
  }

  showModal = (obj, cb) => {
    this.setState({ ...obj }, cb);
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
    this.form.resetFields();
  }


  handleCancel = () => {
    this.form.resetFields();
    this.setState({
      modalVisible: false,
    });
  }

  submit = () => {
    this.form.validateFields((err, values) => {
      if (err) return;
      const { updateStatus } = this.state;
      const { currentItem } = this.state;
      const imgUrl = values.img && values.img[0] ?
        (values.img[0].response ? values.img[0].response.data : values.img) : '';
      if (updateStatus) {
        this.props.update({ ...currentItem, ...values, img: imgUrl })
          .then(() => {
            message.success('编辑成功');
            this.closeModal();
            this.getList();
          });
      } else {
        const params = {
          ...values,
          img: imgUrl
        };
        params.parentid = +values.parentid;
        this.props.add(params)
          .then(() => {
            message.success('新增成功');
            this.closeModal();
            this.getList();
          });
      }
    });
  }

  handleChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const obj = {
      pageIndex: current,
      pageSize,
    };
    this.setState({ ...obj }, () => this.getList());
  };

  deleteAdv = (item) => {
    this.props.deleteAdv({
      key: [item.id]
    }
    ).then(res => {
      if (res) {
        message.success("删除成功");
        this.getList();
      }
    });
  }

  batchDelete = () => {
    const { deleteIds } = this.state;
    if (deleteIds.length === 0) {
      message.warning("请选择需要删除的分类");
      return;
    }

    this.props.deleteAdv({
      key: deleteIds
    }).then(res => {
      if (res) {
        message.success('删除成功');
        this.getList();
      }
    });
  }

  getDetail = () => {
    const { id } = this.state.currentItem;
    this.props.getDetail({
      id,
    });
  }

  renderTable = () => {
    let result;
    const columns = [
      {
        title: '页面URL',
        dataIndex: 'url',
        key: 'url',
        width: '10%',
      }, {
        title: '页面标识',
        dataIndex: 'pagelocation',
        key: 'pagelocation',
        width: '20%',
        render:text =>{
          // <div>{text==201?'陈奕迅陈奕迅陈奕迅陈奕迅陈奕迅':text}</div>
            if(text==101){
              return <div className="text-overflow-class" title="电商行情首页顶部广告图(349px*225px)">电商行情首页顶部广告图(349px*225px)</div>
            }
            if(text==102){
              return <div className="text-overflow-class" title="电商行情左侧推荐关注(258px*173px)">电商行情左侧推荐关注(258px*173px)</div>
            }
            if(text==103){
              return <div className="text-overflow-class" title="电商百科广告图(842px*107px)">电商百科广告图(842px*107px)</div>
            }
            if(text==104){
              return <div className="text-overflow-class" title="电商问答广告图">电商问答广告图</div>
            }
            if(text==105){
              return <div className="text-overflow-class" title="电商教程广告图(258px*173px)">电商教程广告图(258px*173px)</div>
            }
            if(text==201){
              return <div className="text-overflow-class" title="电商智库首页轮播移动(1008px*432px)">电商智库首页轮播移动(1008px*432px)</div>
            }
            if(text==202){
              return <div className="text-overflow-class" title="电商智库首页突出点左移动(480px*504px)">电商智库首页突出点左移动(480px*504px)</div>
            }
            if(text==203){
              return <div className="text-overflow-class" title="电商智库首页突出点右移动(492px*234px)">电商智库首页突出点右移动(492px*234px)</div>
            }
            if(text==204){
              return <div className="text-overflow-class" title="电商行情首页轮播移动(1008px*432px)">电商行情首页轮播移动(1008px*432px)</div>
            }
          }
      }, {
        title: '广告描述',
        dataIndex: 'advdescription',
        key: 'advdescription',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '链接URL',
        dataIndex: 'linkurl',
        key: 'linkurl',
        width: '8%',
      }, {
        title: '关键字',
        dataIndex: 'keywords',
        key: 'keywords',
        width: '10%',
      }, {
        title: '广告图片',
        dataIndex: 'img',
        key: 'img',
        width: "7%",
        render: text => {
          if (text) {
            return <img src={text} alt="图片" height="40" width="40" />;
          }
          return <img src={defaultPicUrl} alt="图片" height="40" width="40" />;
        }
      }, {
        title: '创建人',
        dataIndex: 'creatorname',
        key: 'creatorname',
        width: "10%",
      }, {
        title: '创建时间',
        dataIndex: 'createtime_t',
        key: 'createtime_t',
        width: "10%",
      }, {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        width: "7%",
        render: (text, record) => (
          <div className="operate-col">
            <Icon
              type="edit"
              title="编辑"
              style={{ fontSize: 16, color: '#83B6FF', marginRight: 10 }}
              onClick={() => {
                this.showModal({ modalVisible: true, updateStatus: true, currentItem: record }, this.getDetail);
              }}
            />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={() => this.deleteAdv(record)}
              okText="确定"
              cancelText="取消"
            >
              <Icon type="delete" title="删除" style={{ fontSize: 16, color: '#FF4A4A' }} />
            </Popconfirm>
          </div>
        ),
      }];
    const { list, loading, total } = this.props;
    const { pageIndex, pageSize, deleteIds } = this.state;
    const pagination = {
      total,
      pageSize,
      current: pageIndex,
      showTotal: num => `共${num}项`,
      pageSizeOptions: ['10', '20', '30', '40'],
      showSizeChanger: true,
      showQuickJumper: true,
    };

    const rowSelection = {
      columnWidth: '5%',
      selectedRowKeys: deleteIds,
      onChange: (selectedRowKeys: string[], selectedRows: Object[]) => {
        this.setState({ deleteIds: selectedRowKeys });
      },
    };

    return <Table
      rowKey={record => record.id}
      dataSource={list}
      columns={columns} loading={loading}
      pagination={pagination}
      rowSelection={rowSelection}
      onChange={this.handleChange}
    />;
  }

  render() {
    const { modalVisible, updateStatus, currentItem } = this.state;
    const { parentClass, loading, detailLoading, advDetail } = this.props;
    return (
      <div className="container-wraper">
          <Iframe />
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { advRedux } = state;
    return ({ ...advRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      fetchList, add, update, deleteAdv, getDetail
    }, dispatch,
  ),
)(Nav);