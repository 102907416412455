/**
 * @flow
 */
import { ansyHandleFactory, handleActions } from 'utils/actionFactory';
import { createAsyncAction } from 'utils/request';
import { eClassUrl,eLabelUrl } from './url';

const fetchClassListData = ansyHandleFactory('fetchClassList');
const addClassData = ansyHandleFactory('addClass');
const updateClassData = ansyHandleFactory('updateClass');
const deleteClassData = ansyHandleFactory('deleteClass');
const getDetailData = ansyHandleFactory('getDetail');
const getParentClassData = ansyHandleFactory('getParentClass');
const fetchAllLabelListData = ansyHandleFactory('fetchAllLabelListData'); // 获取所有标签

const ClassRedux = handleActions(
    fetchClassListData('GET', {
        pending: state => ({ ...state, list: [], loading: true,total: 0 }),
        accept: (state, action) => ({ ...state, list: action.payload, total: '',loading: false }),
        reject: state  => ({ ...state, list: [], loading: false,total: 0 }),
    }),
    addClassData('POST', {
        accept: (state) => ({ ...state, loading: false }),
    }),
    updateClassData('POST', {
        accept: (state) => ({ ...state, loading: false }),
    }),
    deleteClassData('GET', {
        accept: (state) => ({ ...state, loading: false }),
    }),
    getDetailData('GET', {
        pending: (state) => ({ ...state, classDetail:{}, detailLoading: true, }),
        accept: (state,action) => ({ ...state, detailLoading: false, classDetail: action.payload}),
        reject: state => ({ ...state, classDetail:{}, detailLoading: false,})
    }),
    getParentClassData('GET', {
        pending: (state) => ({ ...state, parentClass: [], classLoading: true }),
        accept: (state, action) => ({ ...state, parentClass: action.payload, classLoading: false }),
        reject: (state) => ({ ...state, parentClass: [], classLoading: false }),
    }),
    fetchAllLabelListData('GET', {
        pending: state => ({ ...state, labelList: [], labelLoading: true }),
        accept: (state, action) => {
          return {
            ...state, labelList: action.payload, labelLoading: false
          };
        },
        reject: state => ({ ...state, labelList: [], labelLoading: false }),
      }),
    {
        loading: false,
        list: [],
        parentClass: [],
        classLoading: false,
        detailLoading: false,
        classDetail: {},
        total: 0,
    },
);

export default ClassRedux;

/* ************************************************** 同步请求数据 ************************************************ */

/* ************************************************** 异步请求数据 *********************************************** */
type fetchClassListParams = {
};

export const fetchClassList: (obj: fetchClassListParams) => disPromise<*> = createAsyncAction(
    `${eClassUrl}/List`,
    fetchClassListData('GET'),
);

type addClassParams = {
};

export const addClass: (obj: addClassParams) => disPromise<*> = createAsyncAction(
    `${eClassUrl}/create`,
    addClassData('POST'),
);

type updateClassParams = {
};

export const updateClass: (obj: updateClassParams) => disPromise<*> = createAsyncAction(
    `${eClassUrl}/update`,
    updateClassData('POST'),
);

type deleteClassParams = {
};
export const deleteClass: (obj: deleteClassParams) => disPromise<*> = createAsyncAction(
    `${eClassUrl}/delete`,
    deleteClassData('POST'),
);

type getDetailParam = {
};
export const getDetailClass: (obj: getDetailParam) => disPromise<*> = createAsyncAction(
    `${eClassUrl}/detail`,
    getDetailData('GET'),
);

type getParentParams = {
};
export const getParentClass: (obj: getParentParams) => disPromise<*> = createAsyncAction(
    `${eClassUrl}/getparenttype`,
    getParentClassData('GET'),
);
type fetchAllLabelListParams = {
};
export const fetchAllLabel: (obj: fetchAllLabelListParams) => disPromise<*> = createAsyncAction(
    `${eLabelUrl}/GetAllList`,
    fetchAllLabelListData('GET'),
  );
