/**
 * @flow
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Input, Button, Table, Icon,
  Row, Col, Popconfirm, Divider, Modal, Form, message
} from 'antd';
import { fetchList, add, update, deleteComment, fetchRepList, addRep, updateRep, deleteRep } from 'modules/eCommentRedux';
import CommentForm from './form';
import RepForm from './repform';
import './index.less';
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";

const { Search } = Input;
const { TextArea } = Input;
const FormItem = Form.Item;


type Props = {
  form: Form,
  list: Array<Object>,
  listRep: Array<Object>,
  loading: boolean,
  fetchList: typeof fetchList,
  add: typeof add,
  update: typeof update,
  deleteComment: typeof deleteComment,
  fetchRepList: typeof fetchRepList,
  addRep: typeof addRep,
  updateRep: typeof updateRep,
  deleteRep: typeof deleteRep,
  detailLoading: boolean,
  Detail: Object,
};

type State = {
  modalVisible: boolean,
  updateStatus: boolean,
  query: string,
  pageIndex: number,
  pageSize: number,
  deleteIds: Array<number>,
  deleteRepIds: Array<number>,
  currentItem: Object,
  commontId: string,
  rowId: 0,
}

class Comment extends Component {
  form: Object;
  repform: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVisible: false,
      updateStatus: false,
      query: '',
      pageIndex: 1,
      pageSize: 10,
      currentItem: {},
      deleteIds: [],
      deleteRepIds: [],
      modalTitle: '',
      repModalVisible: false,
      commentId: '',
    };

    const { location } = this.props;
    if (!location.state) {
      // 直接跳转链接 返回列表页面
      this.jumpToPrev();
      return;
    }
  }


  componentDidMount = () => {
    this.getList();
  }


  showModal = (obj, cb) => {
    this.setState({ ...obj }, cb);
  }

  jumpToPrev = () => {
    this.props.history.replace(
      `${ROUTE_PREFIX}/ecommerce/article`
    );
  };


  getList = () => {
    const { pageIndex, pageSize, query } = this.state;
    const params = {
      pageIndex, pageSize, searchkey: query, newsid: this.props.location.state.id

    };
    this.props.fetchList({
      ...params
    }).then(res => {
      if (res) {
        if (res.pageData.length > 0) {
          this.setState({
            commentId: res.pageData[0].id
          });
          this.getResList(res.pageData[0].id);
        }
      }
    });
  }

  deleteComment = (item) => {
    this.props.deleteComment({
      key: [item.id]
    }
    ).then(res => {
      if (res) {
        message.success("删除成功");
        this.getList();
      }
    });
  }

  batchDeleteComment = () => {
    const { deleteIds } = this.state;
    if (deleteIds.length === 0) {
      message.warning("请选择需要删除的评论");
      return;
    }

    this.props.deleteComment({
      key: deleteIds
    }).then(res => {
      if (res) {
        message.success('删除成功');
        this.getList();
      }
    });
  }

  deleteRep = (item) => {
    this.props.deleteRep({
      key: [item.id]
    }
    ).then(res => {
      if (res) {
        message.success("删除成功");
        this.getResList();
      }
    });
  }

  batchDeleteRep = () => {
    const { deleteRepIds } = this.state;
    if (deleteRepIds.length === 0) {
      message.warning("请选择需要删除的回复");
      return;
    }

    this.props.deleteRep({
      key: deleteRepIds
    }).then(res => {
      if (res) {
        message.success('删除成功');
        this.getResList();
      }
    });
  }
  renderLeftTable = () => {
    const columns = [
      {
        title: '用户名',
        dataIndex: 'username',
        key: 'username',
      }, {
        title: '是否系统回复',
        dataIndex: 'issyscomment',
        key: 'issyscomment',
        render: (text) => (text ? '是' : '否'),
      }, {
        title: '回复数',
        dataIndex: 'replycount',
        key: 'replycount',
      }, {
        title: '信息',
        dataIndex: 'info',
        key: 'info',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '创建',
        dataIndex: 'createtime_t',
        key: 'createtime_t',
      }, {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        render: (text, record) => (
          <div className="operate-col">
            <Icon
              type="edit"
              title="编辑"
              style={{ fontSize: 16, color: '#83B6FF', marginRight: 10 }}
              onClick={() => {
                this.showModal({ modalVisible: true, updateStatus: true, currentItem: record }, this.getDetail);
              }}
            />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={() => this.deleteComment(record)}
              okText="确定"
              cancelText="取消"
            >
              <Icon type="delete" title="删除" style={{ fontSize: 16, color: '#FF4A4A' }} />
            </Popconfirm>
          </div>
        ),
      }];

    const { list, loading, total } = this.props;
    console.log(this.props)
    const { pageIndex, pageSize, deleteIds } = this.state;
    const pagination = {
      total,
      pageSize,
      current: pageIndex,
      showTotal: num => `共${num}项`,
      pageSizeOptions: ['10', '20', '30', '40'],
      showSizeChanger: true,
      showQuickJumper: true,
    };

    const rowSelection = {
      columnWidth: '1%',
      selectedRowKeys: deleteIds,
      onChange: (selectedRowKeys: string[], selectedRows: Object[]) => {
        this.setState({ deleteIds: selectedRowKeys });
      },
    };

    return <Table
      rowKey={record => record.id}
      dataSource={list}
      columns={columns} loading={loading}
      pagination={pagination}
      rowSelection={rowSelection}
      onChange={this.handleChange}
      rowClassName={this.setRowClassName}
      onRow={record => {
        return {
          onDoubleClick: event => {
            this.setState({
              commentId: record.id
            });
            this.setState({
              rowId: record.id
            });
            this.getResList();
          },
        };
      }}
    />;
  }

  setRowClassName = (record) => {
    return record.id === this.state.rowId ? 'clickComnentRowStyl' : '';
  }

  getResList = () => {
    const { pageIndex, pageSize, commentId } = this.state;
    const params = {
      pageIndex, pageSize, newsid: this.props.location.state.id, commentid: commentId
    };
    this.props.fetchRepList({
      ...params
    });
  }

  renderRightTable = () => {
    const columns = [
      {
        title: '回复者',
        dataIndex: 'reusername',
        key: 'reusername',
      }, {
        title: '被回复者',
        dataIndex: 'username',
        key: 'username',
      }, {
        title: '是否系统回复',
        dataIndex: 'issyscomment',
        key: 'issyscomment',
        render: (text) => (text ? '是' : '否'),
      }, {
        title: '回复信息',
        dataIndex: 'info',
        key: 'inf',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        render: (text, record) => (
          <div className="operate-col">
            <Icon
              type="edit"
              title="编辑"
              style={{ fontSize: 16, color: '#83B6FF', marginRight: 10 }}
              onClick={() => {
                this.showModal({ repModalVisible: true, updateStatus: true, currentItem: record }, this.getDetail);
              }}
            />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={() => this.deleteRep(record)}
              okText="确定"
              cancelText="取消"
            >
              <Icon type="delete" title="删除" style={{ fontSize: 16, color: '#FF4A4A' }} />
            </Popconfirm>
          </div>
        ),
      }];

    const { listRep, loadingRep, totalRep } = this.props;
    const { pageIndex, pageSize, deleteRepIds } = this.state;

    const rowSelection = {
      columnWidth: '1%',
      selectedRowKeys: deleteRepIds,
      onChange: (selectedRowKeys: string[], selectedRows: Object[]) => {
        this.setState({ deleteRepIds: selectedRowKeys });
      },
    };

    return <Table
      rowKey={record => record.id}
      dataSource={listRep}
      columns={columns} loading={loadingRep}
      pagination={false}
      rowSelection={rowSelection}
      onChange={this.handleChange}
    />;
  }

  submit = e => {
    this.form.validateFields((err, values) => {
      if (err) return;
      const { updateStatus } = this.state;

      if (updateStatus) {
        this.props.update({ ...values, newsid: this.props.location.state.id, id: this.state.currentItem.id })
          .then(() => {
            message.success('修改成功');
            this.setState({
              modalVisible: false,
            });
            this.getList();
          });
      }
      else {
        this.props.add({ ...values, newsid: this.props.location.state.id })
          .then(() => {
            message.success('新增成功');
            this.setState({
              modalVisible: false,
            });
            this.getList();
          });
      }
    });
  };

  submitRep = e => {
    this.repform.validateFields((err, values) => {
      if (err) return;

      const { updateStatus, commentId } = this.state;

      if (updateStatus) {
        this.props.updateRep({
          ...values, newsid: this.props.location.state.id, id: this.state.currentItem.id,
          commentid: commentId
        })
          .then(() => {
            message.success('修改成功');
            this.setState({
              repModalVisible: false,
            });
            this.getResList();
          });
      }
      else {
        this.props.addRep({ ...values, newsid: this.props.location.state.id, commentid: commentId })
          .then(() => {
            message.success('新增成功');
            this.setState({
              repModalVisible: false,
            });
            this.getResList();
          });
      }

    });
  };
  render() {
    const { modalTitle, modalVisible, updateStatus, currentItem, repModalVisible } = this.state;
    return (
      <div className="container-wraper">
        <div className="page-list-wraper">
          <Row className="page-header">
            <Col span={4}>
              <Link
                to={{
                  pathname: `${ROUTE_PREFIX}/ecommerce/article`,
                }}
              >
                <Button className="btn-default" >返回上级</Button>
              </Link>
            </Col>
            <Col span={20} className="search-wraper">
            </Col>
          </Row>
          <div className="table-wraper" style={{ display: 'flex' }}>
            <div className="left-table" style={{ padding: '10px', border: '1px solid #e6e6e6', width: 'calc(50% - 20px)', marginRight: '10px' }}>
              <Row className="page-header">
                <Col span={12}>
                  <Button type="primary" onClick={() => this.showModal({ modalVisible: true, updateStatus: false, modalTitle: "添加评论" })}
                  >新增</Button>
                  <Divider type="vertical" />
                  <Popconfirm
                    placement="top"
                    title="确定要删除吗"
                    onConfirm={this.batchDeleteComment}
                    okText="确定"
                    cancelText="取消"
                  >
                    <Button className="danger-btn" >批量删除</Button>
                  </Popconfirm>
                </Col>
                <Col span={12}>
                  <Search
                    placeholder="输入评论者信息 评论信息查询"
                    onSearch={value => this.setState({ query: value }, this.getList)}
                    enterButton
                  />
                </Col>
              </Row>
              {this.renderLeftTable()}
            </div>
            <div className="right-table" style={{ padding: '10px', border: '1px solid #e6e6e6', width: 'calc(50% - 15px)' }}>
              <Row className="page-header">
                <Col span={12}>
                  <Button type="primary" onClick={() => this.showModal({ repModalVisible: true, updateStatus: false, modalTitle: '添加回复' })}
                  >新增</Button>
                  <Divider type="vertical" />
                  <Popconfirm
                    placement="top"
                    title="确定要删除吗"
                    onConfirm={this.batchDeleteRep}
                    okText="确定"
                    cancelText="取消"
                  >
                    <Button className="danger-btn" >批量删除</Button>
                  </Popconfirm>
                </Col>
              </Row>
              {this.renderRightTable()}
            </div>
          </div>
        </div>
        <Modal
          title={modalTitle}
          visible={modalVisible}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={this.submit}
          destroyOnClose={true}
        >
          <CommentForm
            info={updateStatus ? currentItem.info : ""}
            ref={(form) => { return form ? this.form = form : null; }}
          ></CommentForm>
        </Modal>
        <Modal
          title={modalTitle}
          visible={repModalVisible}
          onCancel={() => this.setState({ repModalVisible: false })}
          onOk={this.submitRep}
          destroyOnClose={true}
        >
          <RepForm
            info={updateStatus ? currentItem.info : ''}
            ref={(form) => { return form ? this.repform = form : null; }}
          ></RepForm>
        </Modal>
      </div>
    );
  }
}
export default connect(
  (state) => {
    const { eCommentRedux } = state;
    return ({ ...eCommentRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      fetchList, add, update, deleteComment, fetchRepList, addRep, updateRep, deleteRep
    }, dispatch,
  ),
)(Comment);