/**
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Button, Table, Modal, Icon, Popconfirm, message, Row, Col, Divider } from 'antd';
import { fetchLabelList, addLabel, updateLabel, deleteLabel, getDetailClass } from 'modules/eLabelRedux';
import defaultPicUrl from 'images/default-pic.png';

import LabelForm from './form';
const { Search } = Input;

import './index.less';

type Props = {
  list: Array<Object>,
  total: number,
  loading: boolean,
  fetchLabelList: typeof fetchLabelList,
  addLabel: typeof addLabel,
  updateLabel: typeof updateLabel,
  deleteLabel: typeof deleteLabel,
  detailLoading: boolean,
};
type State = {
  modalVisible: boolean,
  updateStatus: boolean,
  query: string,
  pageIndex: number,
  pageSize: number,
  deleteIds: Array<number>,
  currentItem: Object,
};
class EcomLabel extends Component<Props, State> {
  form: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVisible: false,
      updateStatus: false,
      query: '',
      pageIndex: 1,
      pageSize: 10,
      deleteIds: [],
      currentItem: {},
    };
  }

  componentDidMount = () => {
    this.getList();
  }

  getList = () => {
    const { pageIndex, pageSize, query } = this.state;
    this.props.fetchLabelList({
      pageIndex, pageSize, titlename: query
    });
  }

  showModal = (obj, cb) => {
    this.setState({ ...obj }, cb);
  }

  closeModal = () => {
    this.form.resetFields();
    this.setState({
      modalVisible: false,
    });
  }


  handleCancel = () => {
    this.form.resetFields();
    this.setState({
      modalVisible: false,
    });
  }

  submit = () => {
    this.form.validateFields((err, values) => {
      if (err) return;
      const { updateStatus } = this.state;
      const { currentItem } = this.state;
      const imgUrl = values.img && values.img[0] ?
        (values.img[0].response ? values.img[0].response.data : values.img) : '';
      if (updateStatus) {
        this.props.updateLabel({ ...currentItem, ...values, img: imgUrl })
          .then(() => {
            message.success('编辑成功');
            this.closeModal();
            this.getList();
          });
      } else {
        const params = {
          ...values,
          img: imgUrl
        };
        this.props.addLabel({ ...params })
          .then(() => {
            message.success('新增成功');
            this.closeModal();
            this.getList();
          });
      }

    });
  }

  handleChange = (pagination, filters, sorter) => {
    // console.log(pagination);
    const { current, pageSize } = pagination;
    const obj = {
      pageIndex: current,
      pageSize,
    };
    this.setState({ ...obj }, () => this.getList());
  };

  getDetail = () => {
    const { id } = this.state.currentItem;
    this.props.getDetailClass({
      id,
    });
  }

  deleteLabel = (item) => {
    this.props.deleteLabel({
      Key: [item.id]
    }).then(res => {
      if (res) {
        message.success("删除成功");
        this.getList();
      }
    });
  }

  batchDelete = () => {
    const { deleteIds } = this.state;
    if (deleteIds.length === 0) {
      message.warning("请选择需要删除的文章");
      return;
    }

    this.props.deleteLabel({
      Key: deleteIds
    }).then(res => {
      if (res) {
        message.success('删除成功');
        this.getList();
      }
    });
  }

  renderTable = () => {
    const columns = [
      {
        title: '标签名称',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
      },
      {
        title: '网页标签',
        dataIndex: 'showtitle',
        key: 'showtitle',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '网页关键字',
        dataIndex: 'keywords',
        key: 'keywords',
        width: '15%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      }, {
        title: '网页描述',
        dataIndex: 'description',
        key: 'description',
        width: '20%',
        render: text => (<div className="text-overflow-class" title={text}>{text}</div>)
      },
      {
        title: '缩略图',
        dataIndex: 'img',
        key: 'img',
        width: "7%",
        render: text => {
          if (text) {
            return <img src={text} alt="图片" height="40" width="40" />;
          }
          return <img src={defaultPicUrl} alt="图片" height="40" width="40" />;
        }
      },
      {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        width: "6%",
        render: (text, record) => (
          <div className="operate-col">
            <Icon
              type="edit"
              title="编辑"
              style={{ fontSize: 16, color: '#83B6FF', marginRight: 10 }}
              onClick={() => this.showModal({ modalVisible: true, updateStatus: true, currentItem: record }, this.getDetail)}
            />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={() => this.deleteLabel(record)}
              okText="确定"
              cancelText="取消"
            >
              <Icon type="delete" title="删除" style={{ fontSize: 16, color: '#FF4A4A' }} />
            </Popconfirm>
          </div>
        ),
      }];
    const { list, loading, total } = this.props;
    const { pageIndex, pageSize, deleteIds } = this.state;
    const pagination = {
      total,
      pageSize,
      current: pageIndex,
      showTotal: num => `共${num}项`,
      pageSizeOptions: ['10', '20', '30', '40'],
      showSizeChanger: true,
      showQuickJumper: true,
    };

    const rowSelection = {
      columnWidth: '5%',
      selectedRowKeys: deleteIds,
      onChange: (selectedRowKeys: string[], selectedRows: Object[]) => {
        this.setState({ deleteIds: selectedRowKeys });
      },
    };

    return <Table
      rowKey={record => record.id}
      dataSource={list}
      columns={columns} loading={loading}
      pagination={pagination}
      rowSelection={rowSelection}
      onChange={this.handleChange}
    />;
  }

  render() {
    const { modalVisible, updateStatus, currentItem } = this.state;
    const { loading, labelDetail } = this.props;
    return (
      <div className="container-wraper">
        <Row className="page-header">
          <Col span={14}>
            <Button type="primary" onClick={() => this.showModal({ modalVisible: true, updateStatus: false })}>新增</Button>
            <Divider type="vertical" />
            <Popconfirm
              placement="top"
              title="确定要删除吗"
              onConfirm={this.batchDelete}
              okText="确定"
              cancelText="取消"
            >
              <Button className="danger-btn" >批量删除</Button>
            </Popconfirm>
          </Col>
          <Col span={10} className="search-wraper">
            <Search
              placeholder="输入标签名称查询"
              onSearch={value => this.setState({ query: value, pageIndex: 1, }, this.getList)}
              enterButton
            />
          </Col>
        </Row>
        <div className="table-wraper">
          {this.renderTable()}
        </div>
        <Modal
          title={updateStatus ? '编辑' : '新增'}
          visible={modalVisible}
          onOk={this.submit}
          onCancel={this.handleCancel}
          confirmLoading={loading}
          destroyOnClose={true}
        >
          <LabelForm
            currentItem={updateStatus ? currentItem : {}}
            imgUrl={currentItem.img}
            updateStatus={updateStatus}
            ref={(form) => { return form ? this.form = form : null; }}
          />
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { labelRedux } = state;
    return ({ ...labelRedux });
  },
  (dispatch: Dispatch) => bindActionCreators(
    {
      fetchLabelList, addLabel, updateLabel, deleteLabel, getDetailClass
    }, dispatch,
  ),
)(EcomLabel);