/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import { Form, Input, Select, Checkbox, Upload, Icon, message, Radio, Col ,Row} from 'antd';
import * as Validator from 'utils/validator';
const { Option } = Select;
import { fileUploadUrl } from 'modules/url';

const { TextArea } = Input;
const FormItem = Form.Item;
type Props = {
  form: Form,
  getDetailClass: typeof getDetailClass,
  parentClass: Array<Object>,
  updateStatus: boolean,
  currentItem: Object,
  imgUrl: String,
  imgUrl1: String,
  labelList: Array<Object>,
}
type State = {
  fileList: Array<Object>,
}
type State1 = {
  fileList1: Array<Object>,
}
class ClassForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { updateStatus, imgUrl,imgUrl1 ,imgUrl1_m,imgUrl_m} = this.props;
    const fileList = [];
    const fileList1 = [];
    const mfileList1 = [];
    const mfileList = [];
    if (updateStatus && imgUrl) {
      fileList.push({
        uid: '-1',
        name: '详情图',
        status: 'done',
        url: imgUrl,
      });
    }
    if (updateStatus&& imgUrl1) {
      fileList1.push({
        uid: '-2',
        name: '列表展示图',
        status: 'done',
        url: imgUrl1,
      });
    }
    if (updateStatus&& imgUrl1_m) {
      mfileList1.push({
        uid: '-2',
        name: '列表展示图',
        status: 'done',
        url: imgUrl1_m,
      });
    }
    if (updateStatus&& imgUrl_m) {
      mfileList.push({
        uid: '-2',
        name: '列表展示图',
        status: 'done',
        url: imgUrl_m,
      });
    }
    this.state = {
      fileList:fileList,
      fileList1:fileList1,
      mfileList1:mfileList1,
      mfileList:mfileList,
    };
    // console.log(this)
  }
  onSearchHandler (val){
    // console.log(val)
  }
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('图片大小不能超过20MB!');
    }
    return isLt2M;
  };
 //详情图
  upload = (e) => {
    const { fileList } = this.state;
    const uploadButton = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
    <div style={{color: 'red',position: 'absolute',right: '-200px',top: '-15px',pointerEvents:'none'}}>一级图片大小:750px*500px</div>
    <div style={{color: 'red',position: 'absolute',right: '-200px',top: '15px',pointerEvents:'none'}}>二级图片大小:750px*500px</div>
  </div>
    );
    const headers = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=CourseType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={fileList}
        headers={headers}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          // console.log(fileList)
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState({ fileList });
        }}
      >
        {this.state.fileList.length >= 1 ? null : uploadButton}
      </Upload>
    );

  };
  //列表图
  upload1 = () => {
    const { fileList1 } = this.state;
    const uploadButton1 = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
    <div style={{color: 'red',position: 'absolute',right: '-200px',top: '-15px',pointerEvents:'none'}}>一级图片大小:750px*500px</div>
    <div style={{color: 'red',position: 'absolute',right: '-200px',top: '15px',pointerEvents:'none'}}>二级图片大小:750px*500px</div>
  </div>
    );
    const headers1 = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=CourseType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={fileList1}
        headers={headers1}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState((state1)=>({fileList1:fileList}));
        }}
      >
        {this.state.fileList1.length >= 1 ? null : uploadButton1}
      </Upload>
    );

  };
  mupload1 = () => {
    const { mfileList1 } = this.state;
    const uploadButton1 = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '-15px',pointerEvents:'none'}}>一级列表图片大小:116px*101px</div>
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '15px',pointerEvents:'none'}}>二级列表图片大小:116px*101px</div>
  </div>
    );
    const headers1 = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=CourseType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={mfileList1}
        headers={headers1}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState((state1)=>({mfileList1:fileList}));
        }}
      >
        {this.state.mfileList1.length >= 1 ? null : uploadButton1}
      </Upload>
    );

  };
  mupload = () => {
    const { mfileList } = this.state;
    const uploadButton1 = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '-15px',pointerEvents:'none'}}>一级详情图片大小:360px*200px</div>
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '15px',pointerEvents:'none'}}>二级详情图片大小:360px*200px</div>
  </div>
    );
    const headers1 = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=CourseType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={mfileList}
        headers={headers1}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState((state1)=>({mfileList:fileList}));
        }}
      >
        {this.state.mfileList.length >= 1 ? null : uploadButton1}
      </Upload>
    );

  };
  normFile = e => {
    // console.log('~~~~~~',e)
    if (e && e.fileList) {
      if (!e.fileList[0]) {
        return [];
      }
      const { status } = e.fileList[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList;
    }
    return [];
  };
  normFile1 = e => {
    // console.log('!!!!!',e)
    if (e && e.fileList) {
      if (!e.fileList[0]) {
        return [];
      }
      const { status } = e.fileList[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList;
    }
    return [];
  };
  getParentClassData = () => {
    const { parentClass } = this.props;
    const { id } = this.props.currentItem;

    var data = [];
    parentClass.forEach((item) => {
      if (item.id != id) {
        data.push(item);
      }
    });
    return data;
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 19 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;
    
    const radioGroup = (
      <Radio.Group  >
        <Radio value={1}>是</Radio>
        <Radio value={0}>否</Radio>
      </Radio.Group>
    );
    const {
       parentid,
       spell,
       showtitle,
      shorttitle,
      contents,
      seriesimg,
      detailimg,
      isvip,
      price,
      isnew,
      ishot,
      briefdesc,
      title, 
      keywords,
      description, 
      plids, isshow, sort,
      isseri,
      teacher,
      videolevel,
      crowd,
      purpose,
      isrecommend,
      seriesimg_m,
      detailimg_m,
      istype
    } = this.props.currentItem;
    const { labelList} = this.props;
    //console.log(this.props.currentItem)
    return (
      <Form title="sss">
        <Row>
        <Col span={12}>
          <p style={{'color':'red','paddingLeft':'30px'}}>
            {this.props.updateStatus?(this.props.currentItem.istype?"【编辑分类】":"【编辑课程】"):(this.props.istype?"【新增分类】":"【新增课程】")}
          </p>
        </Col>
        </Row>
        <Row>        
          <Col span={8}>
          <FormItem
          label="所属分类"
          {...formItemLayout}
        >
          {getFieldDecorator('parentid', {
            initialValue: parentid ? parseInt(parentid, 0) : 0,
          })(
            <Select placeholder="请选择一级分类" 
            onSearch={this.onSearchHandler}
            disabled={this.props.updateStatus?(this.props.currentItem.istype?true:false):(this.props.istype?true:false) }
            >
              {this.getParentClassData().map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
            <FormItem
              label={this.props.updateStatus?(this.props.currentItem.istype?"分类标题":"课程名称"):(this.props.istype?"分类标题":"课程名称") }
              {...formItemLayout}
            >
              {getFieldDecorator('title', {
                initialValue: title || '',
                rules: [
                  {
                    required: true, message: '标题不能为空',
                  }],
              })(
                <Input placeholder="请输入标题" />,
              )}
            </FormItem>
            <FormItem
              label="标签"
              {...formItemLayout}
            >
              {getFieldDecorator('plids', {
                initialValue: plids || [],
                rules: [
                  {
                    required: true, message: '标签不能为空',
                  }],
              })(
                <Select
                  mode="multiple"
                  placeholder="请选择文章标签"
                  optionFilterProp="children"
                  showSearch
                >
                  {labelList ? labelList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>) : ''}
                </Select>,
              )}
            </FormItem>
            <FormItem
                label="拼音"
                {...formItemLayout}
              >
                {getFieldDecorator('spell', {
                  initialValue: spell || '',
                  rules: [{
                    required: parentid>0?false:true, message: '请输入拼音',
                  }, {
                    message: '请输入正确格式',
                    pattern: /^(?!\d+$)[\da-zA-Z]*$/,
                  }],
                })(
                  <Input placeholder="请输入拼音" />,
                )}
              </FormItem>
            <FormItem
              label="简标题"
              {...formItemLayout}
            >
              {getFieldDecorator('shorttitle', {
                initialValue: shorttitle || '',
              })(
                <Input placeholder="请输入简标题" />,
              )}
            </FormItem>
            <FormItem
              label="网页标签"
              {...formItemLayout}
            >
              {getFieldDecorator('showtitle', {
                initialValue: showtitle || '',
              })(
                <Input placeholder={this.props.updateStatus?(this.props.currentItem.istype?"请输入网页标签(编辑自行设置)":"如果为空 系统按照规则自动生成(规则：取课程名称)"):
                (this.props.istype?"请输入网页标签(编辑自行设置)":"如果为空 系统按照规则自动生成(规则：取课程名称)")} />,
              )}
            </FormItem>
            <FormItem
              label="网页关键字"
              {...formItemLayout}
            >
              {getFieldDecorator('keywords', {
                initialValue: keywords || '',
              })(
                <Input placeholder="请输入网页关键字(编辑自行设置)" />,
              )}
            </FormItem>
            <FormItem
              label="网页描述"
              {...formItemLayout}
            >
              {getFieldDecorator('description', {
                initialValue: description || '',
              })(
                <TextArea placeholder="请输入网页描述(编辑自行设置)" />,
              )}
            </FormItem>
            <FormItem
              label="简介"
              {...formItemLayout}
            >
              {getFieldDecorator('contents', {
                initialValue: contents || '',
              })(
                <TextArea placeholder="请输入内容" />,
              )}
            </FormItem>
            {/* <FormItem
              label="列表展示图"
              {...formItemLayout}
            >
              {getFieldDecorator('seriesimg', {
                initialValue: seriesimg || '',
              })(
                <Input placeholder="请输入列表展示图" />,
              )}
            </FormItem> */}
            {/* <FormItem
              label="列表展示图"
              {...formItemLayout}
            >
              {getFieldDecorator('seriesimg', {
                initialValue: seriesimg || '',
              })(
                <Input placeholder="请输入详情图" />,
              )}
            </FormItem> */}
            <FormItem
              label="老师"
              {...formItemLayout}
            >
              {getFieldDecorator('teacher', {
                initialValue: teacher || '',
              })(
                <Input placeholder="请输入老师姓名" />,
              )}
            </FormItem>

          </Col>
          <Col span={8}>
          <FormItem
              label="课程级别"
              {...formItemLayout}
            >
              {getFieldDecorator('videolevel', {
                initialValue: videolevel || '',
              })(
                <Input placeholder="请输入课程级别" />,
              )}
            </FormItem>
            <FormItem
              label="适合人群"
              {...formItemLayout}
            >
              {getFieldDecorator('crowd', {
                initialValue: crowd || '',
              })(
                <Input placeholder="请输入适合人群" />,
              )}
            </FormItem>
            <FormItem
              label="价格"
              {...formItemLayout}
            >
              {getFieldDecorator('price', {
                initialValue: price || 0,
              })(
                <Input placeholder="请输入适合人群" />,
              )}
            </FormItem>
            <FormItem
              label="目的"
              {...formItemLayout}
            >
              {getFieldDecorator('purpose', {
                initialValue: purpose || '',
              })(
                <Input placeholder="请输入目的" />,
              )}
            </FormItem>
            <FormItem
              label="排序"
              {...formItemLayout}
            >
              {getFieldDecorator('sort', {
                initialValue: sort || 0,
                rules: [{
                  required: false, message: '',
                }, {
                  message: '请输入正整数',
                  pattern: /^[0-9]\d*$/,
                }],
              })(
                <Input placeholder="越小越靠前" />,
              )}
            </FormItem>
            <FormItem
              label="简介描述"
              {...formItemLayout}
            >
              {getFieldDecorator('briefdesc', {
                initialValue: briefdesc || '',
              })(
                <TextArea placeholder="请输入简介描述" rows="5" />,
              )}
            </FormItem>
            {/* <FormItem
              label="列展示图(移动)"
              {...formItemLayout}
            >
              {getFieldDecorator('seriesimg_m', {
                initialValue: seriesimg_m || '',
                getValueFromEvent: this.normFile
              })(
                // <div>123</div>
                this.mupload1()
              )}
            </FormItem>
            <FormItem
          label="详情图(移动)"
          {...formItemLayout}
        >
          {getFieldDecorator('detailimg_m', {
            initialValue: detailimg_m || '',
            getValueFromEvent: this.normFile
          })(
            this.mupload()
          )}
        </FormItem> */}
          </Col>
          <Col span={8}>
          <FormItem
          label="列展示图(PC)"
          {...formItemLayout}
        >
          {getFieldDecorator('seriesimg', {
            initialValue: seriesimg || '',
            getValueFromEvent: this.normFile
          })(
            // <div>123</div>
            this.upload1()
          )}
        </FormItem>
          <FormItem
          label="详情图(PC)"
          {...formItemLayout}
        >
          {getFieldDecorator('detailimg', {
            initialValue: detailimg || '',
            getValueFromEvent: this.normFile
          })(
            this.upload()
          )}
        </FormItem>
          <Form.Item label="是否vip"  {...formItemLayout}>
              {getFieldDecorator('isvip', { initialValue: isvip })(radioGroup)}
              <p style={{color:'red',marginBottom:0}}>(只有所属分类不为空时有效,预留字段暂时不做控制)</p>
            </Form.Item>
            <Form.Item label="是否更新"  {...formItemLayout}>
              {getFieldDecorator('isnew', { initialValue: isnew })(radioGroup)}
            </Form.Item>
            <Form.Item label="是否热门"  {...formItemLayout}>
              {getFieldDecorator('ishot', { initialValue: ishot })(radioGroup)}
            </Form.Item>
            <Form.Item label="是否显示"  {...formItemLayout}>
              {getFieldDecorator('isshow', { initialValue: isshow })(radioGroup)}
            </Form.Item>
            <Form.Item label="是否连载"  {...formItemLayout}>
              {getFieldDecorator('isseri', { initialValue: isseri })(radioGroup)}
            </Form.Item>
            <Form.Item label="是否推荐"  {...formItemLayout}>
              {getFieldDecorator('isrecommend', { initialValue: isrecommend })(radioGroup)}
            </Form.Item>
          </Col>
        </Row>
      </Form >
    );
  };
}

export default Form.create()(ClassForm);
