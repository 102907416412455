import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
/* eslint camelcase: 0 */
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import configureStore from './redux/store/configureStore';
import Routers from './routers';
import env from 'utils/env';

/* $FlowFixMe */
import './styles/index.less';

window.ROUTE_PREFIX = env.ROUTE_PREFIX;
const store = configureStore();
if (process.env.NODE_ENV !== 'production') {
  window.React = React;
  //  sessionStorage.setItem('loginStatus','login'); // 直接登录
}

ReactDOM.render(
  <Provider store={store}>
    <div style={{ height: '100%' }}>
      <ConfigProvider locale={zhCN}>
        <Routers />
      </ConfigProvider>
    </div>
  </Provider>,
  document.getElementById('root'),
);
