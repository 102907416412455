/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Form, Input, Select, Checkbox, Upload, Icon, message, TreeSelect,Radio, Col,Row } from 'antd';
import * as Validator from 'utils/validator';
import './richTextExtends.less';
import './form.less';
const { Option } = Select;
import { fileUploadUrl } from 'modules/url';

const { TextArea } = Input;
const FormItem = Form.Item;
var Editor = require('wangeditor');
window.Editor = Editor;
type Props = {
  form: Form,
  getDetailClass: typeof getDetailClass,
  parentClass: Array<Object>,
  updateStatus: boolean,
  curretnItem: Object,
  imgUrl: String,
  labelList: Array<Object>,
}
type State = {
  fileList: Array<Object>,
}
class CopyChapterForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { updateStatus, imgUrl ,imgUrl_m} = this.props;
    const fileList = [];
    const mfileList = [];
    if (updateStatus && imgUrl) {
      fileList.push({
        uid: '-1',
        name: '缩略图',
        status: 'done',
        url: imgUrl,
      });
    }
    if (updateStatus&& imgUrl_m) {
      mfileList.push({
        uid: '-2',
        name: '列表展示图',
        status: 'done',
        url: imgUrl_m,
      });
    }
    this.state = {
      fileList:fileList,
      mfileList:mfileList,
    };
  }

  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('图片大小不能超过20MB!');
    }
    return isLt2M;
  };

  upload = () => {

    const { fileList } = this.state;
    const uploadButton = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
    <div style={{color: 'red',position: 'absolute',right: '-200px',top: '-5px',pointerEvents:'none'}}>图片大小:900px*605px</div>
  </div>
    );
    const headers = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=Course'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={fileList}
        headers={headers}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState({ fileList });
        }}
      >
        {this.state.fileList.length >= 1 ? null : uploadButton}
      </Upload>
    );

  };
  mupload = () => {
    const { mfileList } = this.state;
    const uploadButton1 = (
      <div style={{display:'flex',position:'relative',justifyContent:'center'}}>
      <Icon type="plus" />
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '-15px',pointerEvents:'none'}}>一级列表图片大小:227px*140px</div>
    <div style={{color: 'red',position: 'absolute',right: '-226px',top: '15px',pointerEvents:'none'}}>二级列表图片大小:227px*140px</div>
  </div>
    );
    const headers1 = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=CourseType'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        fileList={mfileList}
        headers={headers1}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState((state1)=>({mfileList:fileList}));
        }}
      >
        {this.state.mfileList.length >= 1 ? null : uploadButton1}
      </Upload>
    );

  };
  normFile = e => {
    if (e && e.fileList) {
      if (!e.fileList[0]) {
        return [];
      }
      const { status } = e.fileList[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList;
    }
    return [];
  };

  getParentClassData = () => {
    const { parentClass } = this.props;
    const { id } = this.props.curretnItem;

    var data = [];
    parentClass.forEach((item) => {
      if (item.id != id) {
        data.push(item);
      }
    });
    return data;
  }
  traversalTree = (tree) => {
    if (!tree) return;
    tree.map(item => {
      if(item.children.length>0){
        item.disabled = true;
      }
      if (item.children) {
        // item.disabled = true;
        this.traversalTree(item.children);
      }
      if (item.level === 0) {
        item.disabled = true;
      }
      item.key = item.id;
      item.title = item.title;
      item.value = item.id;
    });
    return tree;
  }
  componentDidMount = () => {
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.curretnItem !== prevProps.curretnItem && this.props.updateStatus) {
    }
  }
  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 19 },
      },
    };
    const contentLayout = {
      labelCol: {
        xs: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 20 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;
    // console.log(this.props)
    const radioGroup = (
      <Radio.Group  >
        <Radio value={1}>是</Radio>
        <Radio value={0}>否</Radio>
      </Radio.Group>
    );
    const {
       parentid,
       showtitle,
      shorttitle,
      contents,
      seriesimg,
      detailimg,
      detailimg_m,
      isvip,
      price,
      ischarge,
      isrecommend,
      briefdesc,
      title, 
      keywords,
      description, 
      plids, isshow, sort,
      isseri,
      teacher,
      videolevel,
      crowd,
      purpose,
      typeid,
      themeid,
      seconds,
      fullname,
      videourl,
      mp4url,
      tokenid,
      aliid,
      istype
    } = this.props.curretnItem;
    // console.log(this.props.curretnItem.contents)
    const { labelList,deleteIds,list,chapterArr,chapterArrType} = this.props;
    return (
      <Form>
        <Row>
        <Col>
                {/* <FormItem
          label="所属章节"
          {...formItemLayout}
        >
          {getFieldDecorator('typeid', {
            initialValue: parentid ? parseInt(parentid, 0) : 0,
          })(
            <Select placeholder="请选择教程分类" >
              {this.props.parentClass.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>         */}
        <FormItem
          label="所属章节"
          {...formItemLayout}
        >
          {getFieldDecorator('typeid', {
            initialValue: parentid ? parseInt(parentid, 0) : 0,
            rules: [
              {
                required: true, message: '请选择课时管理',
              }],
          })(
            <TreeSelect
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={this.traversalTree(chapterArrType)}
                  placeholder="请选择课时管理"
                  treeDefaultExpandAll
                />
          )}
        </FormItem>
            </Col>
            </Row>
      </Form >
    );
  };
}

export default Form.create()(CopyChapterForm);
