/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Form, Input, Select, Checkbox, Upload, Icon, message, TreeSelect,Radio, Col,Row } from 'antd';
import * as Validator from 'utils/validator';
import './richTextExtends.less';
import './form.less';
const { Option } = Select;
import { fileUploadUrl } from 'modules/url';

const { TextArea } = Input;
const FormItem = Form.Item;
var Editor = require('wangeditor');
window.Editor = Editor;
type Props = {
  form: Form,
  getDetailClass: typeof getDetailClass,
  parentClass: Array<Object>,
  updateStatus: boolean,
  curretnItem: Object,
  imgUrl: String,
  labelList: Array<Object>,
  listCourse: Array<Object>,
}
type State = {
  fileList: Array<Object>,
}
class CopyChapterForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { updateStatus, imgUrl ,imgUrl_m} = this.props;
    const fileList = [];
    const mfileList = [];
    this.state = {
      fileList:fileList,
      mfileList:mfileList,
    };
  }


  getParentClassData = () => {
    const { parentClass } = this.props;
    const { id } = this.props.curretnItem;

    var data = [];
    parentClass.forEach((item) => {
      if (item.id != id) {
        data.push(item);
      }
    });
    return data;
  }
  traversalTree = (tree) => {
    if (!tree) return;
    tree.map(item => {
      if(item.children.length>0){
        item.disabled = true;
      }
      if (item.children) {
        // item.disabled = true;
        this.traversalTree(item.children);
      }
      if (item.level === 0) {
        item.disabled = true;
      }
      item.key = item.id;
      item.title = item.title;
      item.value = item.id;
    });
    return tree;
  }  
  traversalTreeCourse = (tree) => {
    if (!tree) return;
    const courseLst = [];
    tree.map(item => {
      if (item.level === 0) {
        item.disabled = true;
      }
      if (item.children){
        item.children.length = 0;
      }
      item.key = item.id;
      item.title = item.title;
      item.value = item.id;
    });
    return tree;
  }
  onChange = value => {
    this.setState({ value });
  };
  onSelectCapter = (value)=>{
   this.getList(value)
  }
  getList = (num) => {
    const params = {
      typeid:num?num:'141'
    };
    this.props.fetchClassCourseList({
      ...params
    });
  }
  componentDidMount = () => {
    this.getList()
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.curretnItem !== prevProps.curretnItem && this.props.updateStatus) {
    }
  }
  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 19 },
      },
    };
    const contentLayout = {
      labelCol: {
        xs: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 20 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;
    // console.log(this.props)
    const radioGroup = (
      <Radio.Group  >
        <Radio value={1}>是</Radio>
        <Radio value={0}>否</Radio>
      </Radio.Group>
    );
    const {
       parentid,
       showtitle,
      shorttitle,
      contents,
      seriesimg,
      detailimg,
      detailimg_m,
      isvip,
      price,
      ischarge,
      isrecommend,
      briefdesc,
      title, 
      keywords,
      description, 
      plids, isshow, sort,
      isseri,
      teacher,
      videolevel,
      crowd,
      purpose,
      typeid,
      themeid,
      seconds,
      fullname,
      videourl,
      mp4url,
      tokenid,
      aliid,
      istype
    } = this.props.curretnItem;
    // console.log(this.props.curretnItem.contents)
    const { labelList,deleteIds,list,chapterArr,courseArrType,courseArr,fetchClassList,listCourse} = this.props;
    // console.log(listCourse)
    return (
      <Form>
      <Row>
      <Col>
      <FormItem
        label="所属分类"
        {...formItemLayout}
      >
        {getFieldDecorator('typeid', {
          initialValue: parentid ? parseInt(parentid, 0) : 0,
          rules: [
            {
              required: true, message: '请选择课时管理',
            }],
        })(
          <TreeSelect
                treeDataSimpleMode
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={this.traversalTree(courseArrType)}
                placeholder="请选择课时管理"
                onSelect={this.onSelectCapter}
              />
        )}
      </FormItem>      
      <FormItem
        label="所属章节"
        {...formItemLayout}
      >
        {getFieldDecorator('pid', {
          initialValue: parentid ? parseInt(parentid, 0) : 0,
          rules: [
            {
              required: true, message: '请选择章节管理',
            }],
        })(
          <TreeSelect
                treeDataSimpleMode
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={this.traversalTreeCourse(listCourse)}
                placeholder="请选择章节管理"
              />
        )}
      </FormItem>
          </Col>
          </Row>
    </Form >
    );
  };
}

export default Form.create()(CopyChapterForm);


