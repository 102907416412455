/**
 * @flow
 * @author wanglei
 */
import React, { Component } from 'react';
import { Form, Input, Upload, Icon, message } from 'antd';
import * as Validator from 'utils/validator';
import { fileUploadUrl } from 'modules/url';

const { TextArea } = Input;
const FormItem = Form.Item;
type Props = {
  form: Form,
  getDetailClass: typeof getDetailClass,
  updateStatus: boolean,
  curretnItem: Object,
  imgUrl: String,
}
type State = {
  fileList: Array<Object>,
}
class LabelForm extends Component<Props, State>  {
  constructor(props) {
    super(props);
    const { updateStatus, imgUrl } = this.props;
    const fileList = [];
    if (updateStatus && imgUrl) {
      fileList.push({
        uid: '-1',
        name: '缩略图',
        status: 'done',
        url: imgUrl,
      });
    }
    this.state = {
      fileList
    };
  }

  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('图片大小不能超过20MB!');
    }
    return isLt2M;
  };

  upload = () => {

    const { fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
      </div>
    );
    const headers = {
      "Authorization": sessionStorage.getItem('Authorization')
    };
    return (
      <Upload
        action={fileUploadUrl + '?type=ProductLabel'}
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/bmp"
        headers={headers}
        fileList={fileList}
        beforeUpload={this.beforeUpload}
        onChange={({ fileList }) => {
          if (!fileList[0]) {
            fileList = [];
          } else {
            const { status } = fileList[0];
            if (!status || status === 'error' || status === 'remove') {
              fileList = [];
            }
          }
          this.setState({ fileList });
        }}
      >
        {this.state.fileList.length >= 1 ? null : uploadButton}
      </Upload>
    );
  };

  normFile = e => {
    if (e && e.fileList) {
      if (!e.fileList[0]) {
        return [];
      }
      const { status } = e.fileList[0];
      if (!status || status === 'error' || status === 'remove') {
        return [];
      }
      return e.fileList;
    }
    return [];
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 19 },
      },
    };
    const {
      getFieldDecorator, // 用于和表单进行双向数据绑定
    } = this.props.form;
    const {
      name, keywords, description, img, showtitle, remark
    } = this.props.currentItem;
    return (
      <Form>
        <FormItem
          label="标签名称"
          {...formItemLayout}
        >
          {getFieldDecorator('name', {
            initialValue: name || '',
            rules: [
              {
                required: true, message: '标签名称不能为空',
              }],
          })(
            <Input placeholder="请输入标签名称" />,
          )}
        </FormItem>
        {/* <FormItem
            label="网页标签"
            {...formItemLayout}
        >
            {getFieldDecorator('showtitle', {
                initialValue: showtitle || '',
            })(
                <Input placeholder="请输入网页标签" />,
            )}
        </FormItem>
        <FormItem
            label="网页关键字"
            {...formItemLayout}
        >
            {getFieldDecorator('keywords', {
                initialValue: keywords || '',
            })(
                <Input placeholder="请输入网页关键字" />,
            )}
        </FormItem>
        <FormItem
            label="网页描述"
            {...formItemLayout}
        >
            {getFieldDecorator('description', {
                initialValue: description || '',
                rules: [
                    {
                        required: true, message: '网页描述不能为空',
                    }],
            })(
                <TextArea placeholder="请输入网页描述" />,
            )}
        </FormItem> */}
        <FormItem
          label="缩略图"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
        >
          {getFieldDecorator('img', {
            initialValue: img,
            getValueFromEvent: this.normFile
          })(
            this.upload()
          )}
        </FormItem>
        <FormItem
          label="备注"
          {...formItemLayout}
        >
          {getFieldDecorator('remark', {
            initialValue: remark || '',
          })(
            <TextArea placeholder="请输入备注" />,
          )}
        </FormItem>
      </Form>
    );
  }

};

export default Form.create()(LabelForm);
